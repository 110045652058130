import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useRedux";

export const BackButton: React.FunctionComponent<{
  backFunction?: () => void;
}> = (props) => {
  const { backFunction } = props;
  const navigate = useNavigate();
  const appSettings = useAppSelector((state) => state.appsettings);

  const backToHome = () => {
    navigate("/dashboard/home");
  };

  return (
    <div
      className={"btn-back" + (!appSettings.isDarkMode ? " light" : "")}
      onClick={() => {
        if (backFunction !== undefined) {
          backFunction();
        } else {
          backToHome();
        }
      }}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: 30 }} />
    </div>
  );
};
