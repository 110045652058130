import React, { useState } from "react";
import "../../assets/styles/Components/Form/PasswordInput.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  FieldValues,
  UseFormRegisterReturn,
  UseFormSetFocus,
} from "react-hook-form/dist/types";

interface PasswordInputProps {
  id: string;
  className: string;
  label?: string;
  register: UseFormRegisterReturn<string>;
  setFocus: UseFormSetFocus<FieldValues>;
}

export const PasswordInput: React.FunctionComponent<PasswordInputProps> = ({
  className,
  id,
  label,
  register,
  setFocus,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setFocus(id);
  };

  return (
    <div className="form-password">
      <label htmlFor={id} className="password-label">
        {label ?? "Password"}:
      </label>
      <div className={"input-wrapper " + className}>
        <input
          type={showPassword ? "text" : "password"}
          id={id}
          className="password-input"
          {...register}
        />
        <div className="show-password" onClick={togglePasswordVisibility}>
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      </div>
    </div>
  );
};
