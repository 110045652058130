import React from "react";
import "../../assets/styles/Components/Modal/SignupModal.scss";
import { SignupForm } from "../form/SignupForm";
import { GenericModal } from "./GenericModal";

export const SignupModal: React.FunctionComponent<{
  setIsSignupModalOpen: (isOpen: boolean) => void;
  setIsLoginModalOpen: (isOpen: boolean) => void;
}> = (props) => {
  const { setIsSignupModalOpen, setIsLoginModalOpen } = props;

  const openLoginModal = (): void => {
    setIsSignupModalOpen(false);
    setIsLoginModalOpen(true);
  };

  return (
    <div>
      <GenericModal setIsOpen={setIsSignupModalOpen} className={"signup-modal"}>
        <div className="container">
          <div className="content">
            <h2 className="signup-h2">Registrati</h2>
            <SignupForm />
            <p onClick={openLoginModal} className="link signup-p">
              Hai già un account? <span className="signup-span">Accedi</span>
            </p>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};
