import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { it } from "date-fns/locale";
import { Transaction } from "../redux/transactionsSlice";

export interface DatePaginationItem {
  transactions: Transaction[];
  label: string;
}

export const createMonthListForPagination = (transactions: Transaction[]) => {
  const months: DatePaginationItem[] = [];
  const today: Date = new Date();

  const oldestTransactionDate: Date = new Date(
    Math.min(
      ...transactions.map((transaction: Transaction) =>
        new Date(transaction.created_at!).getTime()
      )
    )
  );

  let currentMonth = startOfMonth(oldestTransactionDate);

  while (currentMonth <= today) {
    const targetMonth = format(currentMonth, "MMMM yyyy", { locale: it });
    const transactionsForTargetMonth = transactions.filter(
      (transaction: Transaction) => {
        const transactionDate = parseISO(
          transaction.created_at!.toLocaleString()
        );
        return isSameMonth(transactionDate, currentMonth);
      }
    );
    months.push({
      transactions: transactionsForTargetMonth,
      label: targetMonth,
    });
    currentMonth = addMonths(currentMonth, 1);
  }

  return months;
};

export const createWeekListForPagination = (transactions: Transaction[]) => {
  const weeks: DatePaginationItem[] = [];
  const today: Date = new Date();

  const oldestTransactionDate: Date = new Date(
    Math.min(
      ...transactions.map((transaction: Transaction) =>
        new Date(transaction.created_at!).getTime()
      )
    )
  );

  let currentWeek = startOfWeek(oldestTransactionDate, { weekStartsOn: 1 });

  while (currentWeek <= today) {
    const firstDayOfWeek = format(
      startOfWeek(currentWeek, { weekStartsOn: 1 }),
      "dd/MM",
      { locale: it }
    );
    const lastDayOfWeek = format(
      endOfWeek(currentWeek, { weekStartsOn: 1 }),
      "dd/MM",
      { locale: it }
    );
    const weekNumberOfYear = format(currentWeek, "wo");
    const weekLabel = `${firstDayOfWeek} - ${weekNumberOfYear} - ${lastDayOfWeek}`;
    const transactionsForTargetWeek = transactions.filter(
      (transaction: Transaction) => {
        const transactionDate = parseISO(
          transaction.created_at!.toLocaleString()
        );
        return isSameWeek(transactionDate, currentWeek);
      }
    );
    weeks.push({
      transactions: transactionsForTargetWeek,
      label: weekLabel,
    });
    currentWeek = addWeeks(currentWeek, 1);
  }

  return weeks;
};

export const createDayListForPagination = (transactions: Transaction[]) => {
  const days: DatePaginationItem[] = [];
  const today: Date = new Date();

  const oldestTransactionDate: Date = new Date(
    Math.min(
      ...transactions.map((transaction: Transaction) =>
        new Date(transaction.created_at!).getTime()
      )
    )
  );

  let currentDay = startOfDay(oldestTransactionDate);

  while (currentDay <= today) {
    const currentDayFormatted = format(currentDay, "EEE dd/MM/yy", {
      locale: it,
    });
    const dayLabel = `${currentDayFormatted}`;
    const transactionsForTargetDay = transactions.filter(
      (transaction: Transaction) => {
        const transactionDate = parseISO(
          transaction.created_at!.toLocaleString()
        );
        return isSameDay(transactionDate, currentDay);
      }
    );
    days.push({
      transactions: transactionsForTargetDay,
      label: dayLabel,
    });
    currentDay = addDays(currentDay, 1);
  }

  return days;
};

export const createYearListForPagination = (transactions: Transaction[]) => {
  const years: DatePaginationItem[] = [];
  let currentYear: Date = new Date();

  const oldestTransactionDate: Date = new Date(
    Math.min(
      ...transactions.map((transaction: Transaction) =>
        new Date(transaction.created_at!).getTime()
      )
    )
  );

  let oldestYear = startOfYear(oldestTransactionDate);

  while (oldestYear <= currentYear) {
    const year = format(oldestYear, "yyyy");
    const yearLabel = `${year}`;
    const transactionsForTargetYear = transactions.filter(
      (transaction: Transaction) => {
        const transactionDate = parseISO(
          transaction.created_at!.toLocaleString()
        );
        return isSameYear(transactionDate, oldestYear);
      }
    );
    years.push({
      transactions: transactionsForTargetYear,
      label: yearLabel,
    });
    oldestYear = addYears(oldestYear, 1);
  }

  return years;
};
