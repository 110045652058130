// actionsAndReducers.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

// Inizializza lo stato
const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: "info",
};

// Crea uno slice con Redux Toolkit
const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (
      state,
      action: PayloadAction<{
        message: string;
        severity: "success" | "error" | "warning" | "info";
      }>
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    closeSnackbar: () => initialState,
  },
});

// Esporta le action creators
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

// Esporta il reducer
export const SnackbarReducer = snackbarSlice.reducer;
