import React, { useState } from "react";
import "../assets/styles/Components/ChartCheckboxGroup.scss";
import { ChartTypeItem, selectChartType } from "../redux/appsettingsSlice";
import { useAppDispatch } from "../hooks/useRedux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { motion } from "framer-motion";

interface ChartCheckboxGroupProps {
  selectedChartType: ChartTypeItem[];
  setIsOpen: (isOpen: boolean) => void;
}

interface ChartCheckboxOptions {
  id: "line" | "donut" | "bar";
  label: string;
}

const options: ChartCheckboxOptions[] = [
  { id: "bar", label: "Bar" },
  { id: "donut", label: "Donut" },
  { id: "line", label: "Line" },
];

export const ChartCheckboxGroup: React.FC<ChartCheckboxGroupProps> = ({
  selectedChartType,
  setIsOpen,
}) => {
  const dispatch = useAppDispatch();
  const [selectedOptions, setSelectedOptions] =
    useState<ChartTypeItem[]>(selectedChartType);

  const handleCheckboxChange = (optionId: "line" | "donut" | "bar") => {
    const isSelected = selectedOptions.some(
      (option) => option.type === optionId
    );
    let updatedOptions: ChartTypeItem[];
    if (isSelected) {
      updatedOptions = selectedOptions.filter(
        (option) => option.type !== optionId
      );
    } else {
      const optionToAdd: ChartTypeItem = {
        type: optionId,
        viewMode: "balance",
        settings: { enabledAnimation: true, filterBy: "month" },
      };
      updatedOptions = [...selectedOptions, optionToAdd];
    }
    setSelectedOptions(updatedOptions);
    dispatch(selectChartType(updatedOptions));
  };

  const closeModal = (): void => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <ClickAwayListener onClickAway={closeModal}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transform: "translateY(10px)" }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.5 }}
        className="checkbox-group-chart"
      >
        {options.map((chartCheckboxOption) => (
          <div
            key={chartCheckboxOption.id}
            className="checkbox-group-chart-div"
          >
            <input
              type="checkbox"
              id={chartCheckboxOption.id}
              className="checkbox-group-chart-input"
              checked={selectedOptions.some(
                (chartTypeOption) =>
                  chartTypeOption.type === chartCheckboxOption.id
              )}
              onChange={() => handleCheckboxChange(chartCheckboxOption.id)}
            />
            <label
              htmlFor={chartCheckboxOption.id}
              className={`checkbox-group-chart-label ${
                selectedOptions.some(
                  (chartTypeOption) =>
                    chartTypeOption.type === chartCheckboxOption.id
                ) && "checkbox-group-chart-selected"
              }`}
            >
              {chartCheckboxOption.label}
            </label>
          </div>
        ))}
      </motion.div>
    </ClickAwayListener>
  );
};
