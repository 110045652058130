import React, { useState } from "react";
import _ from "lodash";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { capitalize } from "lodash";
import { DatePaginationItem } from "../utils/DatePagination";
import { useAppSelector } from "../hooks/useRedux";

export enum PaginationMode {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly",
}

export const DatePagination: React.FunctionComponent<{
  datePaginationItem: DatePaginationItem[];
  currentDatePagination: number;
  setCurrentDatePagination: React.Dispatch<React.SetStateAction<number>>;
  setIsDatePaginationModeModalOpen: (isOpen: boolean) => void;
}> = (props) => {
  const {
    datePaginationItem,
    currentDatePagination,
    setCurrentDatePagination,
    setIsDatePaginationModeModalOpen,
  } = props;
  const [isLastDatePagination, setIsLastDatePagination] =
    useState<boolean>(true);
  const [isFirstDatePagination, setIsFirstDatePagination] =
    useState<boolean>(false);
  const appSettings = useAppSelector((state) => state.appsettings);

  const previousDatePagination = () => {
    if (currentDatePagination > 0) {
      setCurrentDatePagination((prevItemIndex: number) => prevItemIndex - 1);
      setIsFirstDatePagination(currentDatePagination === 1);
      setIsLastDatePagination(false);
    }
  };

  const nextDatePagination = () => {
    if (currentDatePagination < datePaginationItem.length - 1) {
      setCurrentDatePagination((nextItemIndex: number) => nextItemIndex + 1);
      setIsFirstDatePagination(false);
      setIsLastDatePagination(
        currentDatePagination === datePaginationItem.length - 2
      );
    }
  };

  return (
    <div
      className={
        "date-pagination" +
        (!appSettings.isDarkMode ? " date-pagination-light" : "")
      }
    >
      <div className="date-container">
        <div className="arrow-action" onClick={previousDatePagination}>
          <ArrowBackIosIcon
            sx={
              isFirstDatePagination
                ? { color: "transparent", cursor: "default" }
                : {}
            }
          />
        </div>
        <div className="date-content">
          <div
            className="inner-date-content"
            style={{
              transform: `translateX(-${currentDatePagination * 100}%)`,
            }}
          >
            {_.map(datePaginationItem, (item, i) => (
              <div key={i} className="label">
                <p onClick={() => setIsDatePaginationModeModalOpen(true)}>
                  {capitalize(item.label)}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="arrow-action" onClick={nextDatePagination}>
          <ArrowForwardIosIcon
            sx={
              isLastDatePagination
                ? { color: "transparent", cursor: "default" }
                : {}
            }
          />
        </div>
      </div>
    </div>
  );
};
