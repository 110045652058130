import React, { useEffect } from "react";
import "./assets/styles/App.scss";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./containers/Home";
import { HomeDashboard } from "./containers/HomeDashboard";
import { DashboardSummary } from "./pages/DashboardSummary";
import { TransactionsHistory } from "./pages/TransactionsHistory";
import { NewTransaction } from "./pages/NewTransaction";
import { NewCategory } from "./pages/NewCategory";
import { CategoriesPage } from "./pages/CategoriesPage";
import { DetailsTransaction } from "./pages/DetailsTransaction";
import { DetailsCategory } from "./pages/DetailsCategory";
import { ProfilePage } from "./pages/ProfilePage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { useAppDispatch, useAppSelector } from "./hooks/useRedux";
import { closeSnackbar } from "./redux/snackbarSlice";
import { AppSettingsPage } from "./pages/AppSettingsPage";
import { ContactPage } from "./pages/ContactPage";
import { useTranslation } from "react-i18next";
import { ChartsPage } from "./pages/ChartsPage";
import { NotificationsPage } from "./pages/NotificationsPage";
import { HomeAdminPage } from "./pages/admin/HomeAdminPage";
import { AdminRoute } from "./containers/AdminRoute";
import { AdminContainer } from "./containers/AdminContainer";
import { NotificationsAdminPage } from "./pages/admin/NotificationsAdminPage";
import { TransactionsAdminPage } from "./pages/admin/TransactionsAdminPage";
import { CategoriesAdminPage } from "./pages/admin/CategoriesAdminPage";
import { UsersAdminPage } from "./pages/admin/UsersAdminPage";
import { CreatePage } from "./pages/CreatePage";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const App = () => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector((state) => state.snackbar);
  const settings = useAppSelector((state) => state.appsettings);
  const { i18n } = useTranslation();

  const handleSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  useEffect(() => {
    i18n.changeLanguage(settings.language);
  }, [i18n, settings.language]);

  return (
    <div>
      <div className={"App" + (!settings.isDarkMode ? " light" : "")}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ zIndex: 1000 }}
        >
          <Alert
            onClose={handleSnackClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/dashboard/*" element={<HomeDashboard />}>
              <Route index element={<DashboardSummary />} />
              <Route path="home" element={<DashboardSummary />} />
              <Route path="transactions" element={<TransactionsHistory />} />
              <Route path="categories" element={<CategoriesPage />} />
              <Route path="charts" element={<ChartsPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="settings" element={<AppSettingsPage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="createNew" element={<CreatePage />} />
              <Route path="newTransaction" element={<NewTransaction />} />
              <Route path="newCategory" element={<NewCategory />} />
              <Route
                path="detailsTransaction/:id"
                element={<DetailsTransaction />}
              />
              <Route path="detailsCategory/:id" element={<DetailsCategory />} />
            </Route>
            <Route element={<AdminRoute />}>
              <Route path="/admin/*" element={<AdminContainer />}>
                <Route index element={<HomeAdminPage />} />
                <Route path="home" element={<HomeAdminPage />} />
                <Route
                  path="notifications"
                  element={<NotificationsAdminPage />}
                />
                <Route
                  path="transactions"
                  element={<TransactionsAdminPage />}
                />
                <Route path="categories" element={<CategoriesAdminPage />} />
                <Route path="users" element={<UsersAdminPage />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
};
