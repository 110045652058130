import React, { useEffect, useState } from "react";
import "../assets/styles/Components/CategoriesTable.scss";
import _, { capitalize } from "lodash";
import { CategoriesTableActionsButton } from "./CategoriesTableActionsButton";
import { Category } from "../redux/categoriesSlice";
import { Transaction_type } from "../hooks/useTransactionForm";
import { Search } from "./SearchInput";
import { MuIcon } from "./MuIcon";
import { RadioButtonTransactionType } from "./RadioButtonTransactionType";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const CategoriesTable: React.FunctionComponent<{
  categories: Category[];
}> = (props) => {
  const { categories } = props;
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [filteredResults, setFilteredResults] =
    useState<Category[]>(categories);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [typeFilterSelected, setTypeFilterSelected] = useState<string>("all");
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  const handleSearch = (query: string) => {
    if (query.trim() === "" || query.length <= 1) {
      setFilteredResults(categories);
    } else {
      const filteredData = categories.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(filteredData);
    }
    setSearchQuery(query);

    handleSearchAndFilter(query, typeFilterSelected);
  };

  const handleSearchAndFilter = (query: string, typeFilter: string) => {
    const filteredData = categories.filter((item) => {
      const nameMatch =
        query.trim() === "" ||
        query.length <= 1 ||
        item.name.toLowerCase().includes(query.toLowerCase());
      const typeMatch = typeFilter === "all" || item.c_type === typeFilter;
      return nameMatch && typeMatch;
    });

    setFilteredResults(filteredData);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 500); // Puoi regolare questo valore in base alle tue esigenze
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const selectedType = event.target.value;
    setTypeFilterSelected(selectedType);
    handleSearchAndFilter(searchQuery, selectedType);
  };

  return (
    <>
      <h2 className="table-h2">{t("categories")}</h2>
      <div
        className={
          "table-container" +
          (!appSettings.isDarkMode ? " table-container-light" : "")
        }
      >
        <div className="search-and-filter">
          <Search searchQuery={searchQuery} onSearch={handleSearch} />
          <div className="table-filter">
            <RadioButtonTransactionType
              t_type={typeFilterSelected}
              handleInputChange={handleInputChange}
              showAll={true}
            />
          </div>
        </div>
        <div className="table-content">
          <table>
            <thead>
              <tr>
                {isDesktop && <th className="th">{t("type")}</th>}
                <th className="th">{t("name")}</th>
                {isDesktop && <th className="th">{t("color")}</th>}
                <th className="th" style={{ minWidth: 60 }}>
                  {t("icon")}
                </th>
                <th className="th" style={{ width: 120 }}>
                  {t("actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {isDesktop && <td></td>}
                <td></td>
                {isDesktop && <td></td>}
                <td></td>
                <td></td>
              </tr>
              {_.map(filteredResults, (category, i) => {
                return (
                  <tr key={i}>
                    {isDesktop && <td>{t(category.c_type)}</td>}
                    <td>{capitalize(category.name)}</td>
                    {isDesktop && <td>{category.color}</td>}
                    <td>
                      {
                        <MuIcon
                          name={category.icon}
                          sx={{ color: category.color, fontSize: 36 }}
                        />
                      }
                    </td>
                    <td className="table-actions">
                      <CategoriesTableActionsButton category={category} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
