import { IconTypeMap } from "@mui/material";
import * as MUIcon from "@mui/icons-material";

type IconProps = IconTypeMap["props"];

interface MIconProps extends IconProps {
  name: string | undefined;
}

export const iconNames = [
  "Home",
  "DirectionsCarFilled",
  "RequestQuote",
  "AttachMoney",
  "Fastfood",
  "Paid",
  "ShoppingCart",
  "ShoppingBasket",
  "Flight",
  "Train",
  "School",
  "SportsEsports",
  "Hiking",
  "TwoWheeler",
  "Cake",
  "ChildFriendly",
  "Sailing",
  "Movie",
  "Stadium",
  "TheaterComedy",
  "AccountBalanceWallet",
  "CreditCard",
  "Medication",
  "DownhillSkiing",
  "Computer",
  "PhoneIphone",
  "Atm",
  "BeachAccess",
  "CurrencyBitcoin",
  "Celebration",
  "Restaurant",
  "Coffee",
  "QuestionMark",
];

export const MuIcon = (props: MIconProps) => {
  const { name } = props;
  const Icon = MUIcon[name as keyof typeof MUIcon];
  if (Icon == null) {
    throw `There is no "${name}" Icon`;
  }
  return <Icon {...props} />;
};
