import React, { createContext, useContext, useState, ReactNode } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import _ from "lodash";

// Interfaccia per definire il contesto
interface AuthContextProps {
  isLoggedIn: boolean;
  login: (
    token: string,
    userId: number,
    role: string,
    firstName: string
  ) => void;
  logout: () => void;
}

// Crea un contesto per gestire lo stato di autenticazione
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Componente Provider che gestisce lo stato di autenticazione
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    !!getItem("token") ?? false
  );

  const login = (
    token: string,
    userId: number,
    role: string,
    firstName: string
  ) => {
    setItem("token", token);
    setItem("userId", userId.toString());
    setItem("role", role);
    setItem("firstCharName", _.head(firstName)!);
    setIsLoggedIn(true);
  };

  const logout = () => {
    removeItem("token");
    removeItem("userId");
    removeItem("firstCharName");
    removeItem("persist:root");
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizzato per accedere allo stato di autenticazione
export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuth deve essere utilizzato all'interno di un AuthProvider"
    );
  }
  return context;
};
