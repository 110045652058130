import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { pink } from "@mui/material/colors";

export const CloseButton: React.FunctionComponent<{
  className: string;
  onClick: () => void;
  fontSize: number | string;
}> = (props) => {
  const { className, onClick, fontSize } = props;
  return (
    <div className={className} onClick={onClick}>
      <CloseIcon sx={{ color: pink[500], fontSize: fontSize }} />
    </div>
  );
};
