import React from "react";
import "../assets/styles/Components/RadioButtonCreateNew.scss";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

interface RadioButtonCreateNewProps {
  isTransactionMode: boolean;
  handleInputChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
}

export const RadioButtonCreateNew: React.FunctionComponent<
  RadioButtonCreateNewProps
> = ({ isTransactionMode, handleInputChange }) => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  return (
    <div
      className={
        "new-transaction-radio-expense-type" +
        (!appSettings.isDarkMode
          ? " new-transaction-radio-expense-type-light"
          : "")
      }
    >
      <div className="radio-create-new-inner">
        <label
          htmlFor="transaction"
          className={`radio-create-new-label ${
            isTransactionMode && "radio-create-new-selected"
          }`}
        >
          <input
            type="radio"
            name="creationType"
            id="transaction"
            value="transaction"
            checked={isTransactionMode}
            onChange={handleInputChange}
            className="radio-create-new-input"
          />
          {t("transaction")}
        </label>
        <label
          htmlFor="category"
          className={`radio-create-new-label ${
            !isTransactionMode && "radio-create-new-selected"
          }`}
        >
          <input
            type="radio"
            name="creationType"
            id="category"
            value="category"
            checked={!isTransactionMode}
            onChange={handleInputChange}
            className="radio-create-new-input"
          />
          {t("category")}
        </label>
      </div>
    </div>
  );
};
