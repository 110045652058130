import React, { useState } from "react";
import "../assets/styles/Components/CategorySelect.scss";
import _, { capitalize } from "lodash";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MuIcon } from "./MuIcon";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Category } from "../redux/categoriesSlice";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const CategorySelect: React.FunctionComponent<{
  categories: Category[];
  categoryName: string;
  selectCategory: (categoryName: string) => void;
  showAll?: boolean;
}> = (props) => {
  const { categoryName, selectCategory, categories, showAll } = props;
  const [isCategoryOptionsVisible, setIsCategoryOptionsVisible] =
    useState<boolean>(false);
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  const handleSelectCategory = (categoryName: string) => {
    selectCategory(categoryName);
    setIsCategoryOptionsVisible(false);
  };

  const handleClickAway = () => {
    setIsCategoryOptionsVisible(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="category-select">
        <div
          className={
            showAll ? "category-select-input br5" : "category-select-input"
          }
          onClick={() => setIsCategoryOptionsVisible(!isCategoryOptionsVisible)}
        >
          {categoryName === ""
            ? t("select-category")
            : categoryName === "all"
            ? t("all")
            : capitalize(categoryName)}
          <ArrowDropDownIcon sx={{ fontSize: 30 }} />
        </div>
        <div
          className={
            "category-select-list" +
            (!appSettings.isDarkMode ? " light" : "") +
            (!isCategoryOptionsVisible ? " hidden" : "")
          }
        >
          {showAll && (
            <li
              key={"all"}
              className="category-select-li zebra"
              onClick={() => {
                handleSelectCategory("all");
              }}
            >
              {t("all")}
              <div>
                <MuIcon
                  name="AllInclusive"
                  sx={{ color: "#fff", fontSize: 36 }}
                />
              </div>
            </li>
          )}
          {_.map(categories, (category, i) => (
            <li
              key={i}
              className={`category-select-li ${i % 2 === 1 && "zebra"}`}
              onClick={() => {
                handleSelectCategory(category.name);
              }}
            >
              {capitalize(category.name)}
              <div>
                <MuIcon
                  name={category.icon}
                  sx={{ color: category.color, fontSize: 36 }}
                />
              </div>
            </li>
          ))}
          <li
            key={"newCategory"}
            className="category-select-li li-new-category"
            onClick={() => {
              handleSelectCategory("newCategory");
            }}
          >
            {t("create-category")}
            <div>
              <MuIcon name="AddCircle" sx={{ color: "#fff", fontSize: 36 }} />
            </div>
          </li>
        </div>
      </div>
    </ClickAwayListener>
  );
};
