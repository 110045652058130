import React from "react";
import "../../assets/styles/Components/Modal/DatePaginationModeModal.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CloseButton } from "../CloseButton";
import { motion } from "framer-motion";
import { useAppSelector } from "../../hooks/useRedux";
import { PaginationMode } from "../DatePagination";

export const DatePaginationModeModal: React.FunctionComponent<{
  setIsDatePaginationModeModalOpen: (isOpen: boolean) => void;
  changePaginationMode: (paginationMode: PaginationMode) => void;
  paginationMode: PaginationMode;
  setPaginationMode: (paginationMode: PaginationMode) => void;
}> = (props) => {
  const {
    setIsDatePaginationModeModalOpen,
    changePaginationMode,
    paginationMode,
    setPaginationMode,
  } = props;
  const appSettings = useAppSelector((state) => state.appsettings);

  const closeModal = (): void => {
    setTimeout(() => {
      setIsDatePaginationModeModalOpen(false);
    }, 100);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePaginationMode(event.target.value as PaginationMode);
    setPaginationMode(event.target.value as PaginationMode);
    setIsDatePaginationModeModalOpen(false);
  };

  return (
    <div className="backdrop-panel-blur">
      <ClickAwayListener onClickAway={closeModal}>
        <motion.div
          className={
            "date-pagination-mode-modal" +
            (!appSettings.isDarkMode ? " date-pagination-mode-modal-light" : "")
          }
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, scale: 0.5 }}
        >
          <CloseButton
            className="btn-close"
            onClick={closeModal}
            fontSize={40}
          />
          <div className="container">
            <div className="content">
              <form>
                <input
                  type="radio"
                  id="giornaliero"
                  name="frequenza"
                  value={PaginationMode.Daily}
                  checked={paginationMode === PaginationMode.Daily}
                  onChange={handleRadioChange}
                />
                <label
                  htmlFor="giornaliero"
                  className={
                    paginationMode === PaginationMode.Daily ? "selected" : ""
                  }
                >
                  Giornaliero
                </label>

                <input
                  type="radio"
                  id="settimanale"
                  name="frequenza"
                  value={PaginationMode.Weekly}
                  checked={paginationMode === PaginationMode.Weekly}
                  onChange={handleRadioChange}
                />
                <label
                  htmlFor="settimanale"
                  className={
                    paginationMode === PaginationMode.Weekly ? "selected" : ""
                  }
                >
                  Settimanale
                </label>

                <input
                  type="radio"
                  id="mensile"
                  name="frequenza"
                  value={PaginationMode.Monthly}
                  checked={paginationMode === PaginationMode.Monthly}
                  onChange={handleRadioChange}
                />
                <label
                  htmlFor="mensile"
                  className={
                    paginationMode === PaginationMode.Monthly ? "selected" : ""
                  }
                >
                  Mensile
                </label>

                <input
                  type="radio"
                  id="annuale"
                  name="frequenza"
                  value={PaginationMode.Yearly}
                  checked={paginationMode === PaginationMode.Yearly}
                  onChange={handleRadioChange}
                />
                <label
                  htmlFor="annuale"
                  className={
                    paginationMode === PaginationMode.Yearly ? "selected" : ""
                  }
                >
                  Annuale
                </label>
              </form>
            </div>
          </div>
        </motion.div>
      </ClickAwayListener>
    </div>
  );
};
