import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { createTheme, styled, useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  ChartTypeItem,
  updateChartTypeItemSettings,
} from "../../redux/appsettingsSlice";
import { motion } from "framer-motion";
import ClickAwayListener from "@mui/material/ClickAwayListener";

interface ChartMenuSettingsProps {
  selectedChartType: ChartTypeItem;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const MaterialUISwitchGeneric = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#005fdf",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 30 30"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
    borderRadius: 20 / 2,
  },
}));

export const ChartMenuSettings: React.FC<ChartMenuSettingsProps> = ({
  selectedChartType,
  isOpen,
  setIsOpen,
}) => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const customTheme = createTheme({
    ...theme,
    palette: {
      mode: appSettings.isDarkMode ? "dark" : "light",
    },
  });

  const handleSwitchButton = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      updateChartTypeItemSettings({
        type: selectedChartType.type,
        settings: {
          enabledAnimation: checked,
          filterBy: selectedChartType.settings.filterBy,
        },
      })
    );
  };

  const selectFilterByMode = (selectedMode: "day" | "month" | "year") => {
    dispatch(
      updateChartTypeItemSettings({
        type: selectedChartType.type,
        settings: {
          enabledAnimation: selectedChartType.settings.enabledAnimation,
          filterBy: selectedMode,
        },
      })
    );
  };

  const closeModal = (): void => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <ClickAwayListener onClickAway={closeModal}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transform: "translateY(10px)" }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.5 }}
        className={
          "chart-type-content-settings-menu " +
          (!appSettings.isDarkMode
            ? " chart-type-content-settings-menu-light"
            : "")
        }
      >
        <div className="chart-type-content-settings-menu-div">
          <label className="chart-type-content-settings-menu-label">
            {t("animation")}
          </label>
          <FormControlLabel
            control={
              <MaterialUISwitchGeneric
                sx={{ m: 0 }}
                name="enabledAnimation"
                checked={selectedChartType.settings.enabledAnimation}
                onChange={handleSwitchButton}
                theme={customTheme}
              />
            }
            sx={{ m: 0 }}
            label=""
          />
        </div>
        <div className="chart-type-content-settings-menu-div">
          <label className="chart-type-content-settings-menu-label">
            {t("filter-by")}
          </label>
          <select
            name="filterBy"
            id="filterBy"
            className="chart-type-content-settings-menu-select"
            value={selectedChartType.settings.filterBy}
            onChange={(e) =>
              selectFilterByMode(e.target.value as "day" | "month" | "year")
            }
          >
            <option value="day">{t("day")}</option>
            <option value="month">{t("month")}</option>
            <option value="year">{t("year")}</option>
          </select>
        </div>
      </motion.div>
    </ClickAwayListener>
  );
};
