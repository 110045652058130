import React, { useState } from "react";
import "../../assets/styles/Components/Form/CategoryForm.scss";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { HexColorPicker } from "react-colorful";
import { nameValidator } from "../../utils/FormValidators";
import { useCategoryForm } from "../../hooks/useCategoryForm";
import { GenericLoader } from "../loader/GenericLoader";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { addCategories } from "../../redux/categoriesSlice";
import { ShowIconModal } from "../modal/ShowIconModal";
import { MuIcon } from "../MuIcon";
import { RadioButtonTransactionType } from "../RadioButtonTransactionType";
import { openSnackbar } from "../../redux/snackbarSlice";
import { useTranslation } from "react-i18next";

export const NewCategoryForm: React.FunctionComponent<{
  setIsModalOpen?: (isOpen: boolean) => void;
  isTitleVisible?: boolean;
}> = (props) => {
  const { setIsModalOpen, isTitleVisible = true } = props;
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.categories);
  const appSettings = useAppSelector((state) => state.appsettings);
  const [isSubmittingFormData, setIsSubmittingFormData] =
    useState<boolean>(false);
  const [isIconModalOpen, setIsIconModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    formData,
    setFormData,
    isColorPickerVisible,
    handleColorPicker,
    handleSelectColor,
    handleSelectIcon,
    handleInputChange,
  } = useCategoryForm();
  const { t } = useTranslation();

  const handleIconClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsIconModalOpen(true);
  };

  const handleInputNameBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const categoryName = event.target.value;
    if (categoryName !== "") {
      const categoryNameExist = !!_.find(
        categories.data,
        (category) => category.name === categoryName.toLowerCase()
      );
      if (categoryNameExist) {
        setFormData({ ...formData, name: "" });
        dispatch(
          openSnackbar({
            message: t("name-used"),
            severity: "error",
          })
        );
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isNameValid = nameValidator(formData.name);
    if (isNameValid) {
      setIsSubmittingFormData(true);
      try {
        const response = await dispatch(addCategories(formData));

        if (response.meta.requestStatus === "fulfilled") {
          if (!setIsModalOpen) {
            navigate("/dashboard/home");
          } else {
            setIsModalOpen(false);
            window.location.reload();
          }
        } else {
          alert("Accesso non autorizzato");
        }
      } catch (error) {
        console.error("Errore durante l'accesso:", error);
        alert("Errore durante l'accesso:");
      } finally {
        setIsSubmittingFormData(false);
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("name-invalid"),
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      {isTitleVisible && <h2 className="category-h2">{t("new-category")}</h2>}
      <form
        className={
          "category-form" +
          (!appSettings.isDarkMode ? " category-form-light" : "")
        }
        onSubmit={handleSubmit}
      >
        <div className="category-form-div">
          <label htmlFor="c_type" className="category-form-label">
            {t("type")}
          </label>
          <RadioButtonTransactionType
            t_type={formData.c_type}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="category-form-div">
          <label htmlFor="name" className="category-form-label">
            {t("name")}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            className="category-form-input"
            onChange={handleInputChange}
            onBlur={handleInputNameBlur}
            required
          />
        </div>
        <div className="category-form-div div-group-color-icon">
          <div className="category-form-div div-group-color-cat">
            <div className="category-form-div btn-group-color-cat">
              <label
                htmlFor="color"
                className="category-form-label btn-group-color-cat"
              >
                {t("color")}
              </label>
              <div
                id="color"
                className="category-form-div display-color-selected"
                style={{ backgroundColor: formData.color }}
                onClick={() => handleColorPicker(true)}
              ></div>
            </div>
          </div>
          <div className="category-form-div div-group-icon-cat">
            <div className="category-form-div content">
              <label
                htmlFor="icon-selected"
                className="category-form-label div-group-icon-cat-label"
              >
                {t("icon")}
              </label>
              <div
                id="icon"
                className="category-form-div display-icon-selected"
                onClick={handleIconClick}
              >
                {
                  <MuIcon
                    name={formData.icon}
                    sx={{ color: formData.color, fontSize: 40 }}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {isColorPickerVisible && (
          <div
            className="backdrop-panel-blur"
            onClick={() => handleColorPicker(false)}
          >
            <HexColorPicker
              className="color-picker"
              color={formData.color}
              onChange={(color) => handleSelectColor(color)}
            />
          </div>
        )}
        {isIconModalOpen && (
          <ShowIconModal
            setIsOpen={setIsIconModalOpen}
            color={formData.color}
            setSelectedIcon={handleSelectIcon}
          />
        )}
        <div className="btn-submit">
          <button type="submit" className="category-form-submit-button">
            {t("create")}
          </button>
        </div>
      </form>
      {isSubmittingFormData && <GenericLoader />}
    </>
  );
};
