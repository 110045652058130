import React from "react";
import "../../assets/styles/Components/Modal/LoginModal.scss";
import { LoginForm } from "../form/LoginForm";
import { GenericModal } from "./GenericModal";

export const LoginModal: React.FunctionComponent<{
  setIsLoginModalOpen: (isOpen: boolean) => void;
  setIsSignupModalOpen: (isOpen: boolean) => void;
}> = (props) => {
  const { setIsLoginModalOpen, setIsSignupModalOpen } = props;

  const openSignupModal = () => {
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(true);
  };

  return (
    <div>
      <GenericModal setIsOpen={setIsLoginModalOpen} className={"login-modal"}>
        <div className="container">
          <div className="content">
            <h2 className="login-h2">Accedi</h2>
            <LoginForm />
            <p className="link login-p">
              Non hai un account?{" "}
              <span onClick={openSignupModal} className="login-span">
                Registrati
              </span>
            </p>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};
