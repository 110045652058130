import React, { useEffect } from "react";
import "../assets/styles/Pages/AppSettingsPage.scss";
import { useNavigate } from "react-router-dom";
import { GenericLoader } from "../components/loader/GenericLoader";
import { BackButton } from "../components/BackButton";
import { AppSettingsForm } from "../components/form/AppSettingsForm";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { fetchSettings } from "../redux/appsettingsSlice";
import CookieIcon from "@mui/icons-material/Cookie";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";

export const AppSettingsPage: React.FunctionComponent = () => {
  const settings = useAppSelector((state) => state.appsettings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const iconsPolicyColor = "#14A478";

  const backFunction = () => {
    navigate("/dashboard/home");
  };

  useEffect(() => {
    dispatch(fetchSettings());

    // const script = document.createElement("script");
    // script.src = "https://cdn.iubenda.com/iubenda.js";
    // script.async = true;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return (
    <div className="partial-appsettings-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="app-settings-container">
            {settings.loading ? (
              <GenericLoader />
            ) : (
              <>
                <BackButton backFunction={backFunction} />
                <AppSettingsForm />
                <h3 className="app-settings-h3-policy">Policy</h3>
                <div className="app-settings-div-policy">
                  <div
                    className={
                      "app-settings-a-policy " +
                      (!settings.isDarkMode
                        ? " app-settings-a-policy-light"
                        : "")
                    }
                  >
                    <a
                      href="https://www.iubenda.com/privacy-policy/18045855/cookie-policy"
                      target="_blank"
                      className="iubenda iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
                      title="Cookie Policy "
                    >
                      <CookieIcon
                        sx={{ color: iconsPolicyColor, marginRight: 0.8 }}
                      />
                      Cookie Policy
                    </a>
                  </div>
                  <div
                    className={
                      "app-settings-a-policy " +
                      (!settings.isDarkMode
                        ? " app-settings-a-policy-light"
                        : "")
                    }
                  >
                    <a
                      href="https://www.iubenda.com/privacy-policy/18045855"
                      target="_blank"
                      className="iubenda iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
                      title="Privacy Policy "
                    >
                      <PrivacyTipIcon
                        sx={{ color: iconsPolicyColor, marginRight: 0.8 }}
                      />
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
