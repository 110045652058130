import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Transaction } from "../../redux/transactionsSlice";
import { ChartTypeItem } from "../../redux/appsettingsSlice";
import { ChartRadioButtonGroup } from "../ChartRadioButtonGroup";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  ChartData,
  initChartData,
  setBalanceChartData,
  setCountChartData,
} from "../../utils/Charts";
import SettingsIcon from "@mui/icons-material/Settings";
import { ChartMenuSettings } from "./ChartMenuSettings";
import { AnimatePresence } from "framer-motion";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart: React.FC<{
  transactions: Transaction[];
  chartTypeItem: ChartTypeItem;
}> = ({ transactions, chartTypeItem }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<ChartData>(
    initChartData([t("income"), t("expense")])
  );

  const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState<boolean>(false);

  let delayed = chartTypeItem.settings.enabledAnimation;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    animation: {
      onComplete: () => {
        delayed = false;
      },
      delay: (context: {
        type: string;
        mode: string;
        dataIndex: number;
        datasetIndex: number;
      }) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  };

  const toggleMenuSettingsVisibility = () => {
    setIsMenuSettingsOpen(!isMenuSettingsOpen);
  };

  useEffect(() => {
    const sortedTransactionsAsc = _.sortBy(transactions, "created_at");
    if (chartTypeItem.viewMode === "balance") {
      setChartData(
        setBalanceChartData(
          sortedTransactionsAsc,
          [t("income"), t("expense")],
          chartTypeItem.settings.filterBy
        )
      );
    } else {
      setChartData(
        setCountChartData(
          sortedTransactionsAsc,
          [t("income"), t("expense")],
          chartTypeItem.settings.filterBy
        )
      );
    }
  }, [chartTypeItem.viewMode, chartTypeItem.settings.filterBy]);

  return (
    <div className="chart-type-content">
      <div className="chart-type-content-div-settings">
        <ChartRadioButtonGroup selectedChartType={chartTypeItem} />
        <div
          className="chart-type-content-settings"
          onClick={() => toggleMenuSettingsVisibility()}
        >
          <SettingsIcon sx={{}} />
        </div>
      </div>
      <Bar data={chartData} options={options} />
      <AnimatePresence>
        {isMenuSettingsOpen && (
          <ChartMenuSettings
            selectedChartType={chartTypeItem}
            isOpen={isMenuSettingsOpen}
            setIsOpen={setIsMenuSettingsOpen}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
