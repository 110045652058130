import React, { useEffect, useState } from "react";
import { HeaderDashboardPage } from "../components/HeaderDashboardPage";
import { BottomBarMenu } from "../components/BottomBarMenu";
import { Outlet, useNavigate } from "react-router-dom";
import { checkAuthenticationAsync, logoutUserAsync } from "../services/Api";
import { SideMenu } from "../components/SideMenu";
import { useAppDispatch } from "../hooks/useRedux";
import { fetchNotifications } from "../redux/appsettingsSlice";
import { openSnackbar } from "../redux/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useAuth } from "../stores/AuthContext";
import { GenericLoader } from "../components/loader/GenericLoader";

export const HomeDashboard: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { logout } = useAuth();

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await logoutUserAsync();

      if (response.status === 200) {
        logout();
        navigate("/");
      }
    } catch (error) {
      console.error("Errore durante il logout:", error);
      alert("Errore durante il logout");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await checkAuthenticationAsync();
        if (response.status !== 200) {
          navigate("/");
        }
      } catch (error) {
        if (typeof error === "object" && "statusCode" in error!) {
          // const customError = error as { statusCode: number; message: string };
          logout();
          dispatch(
            openSnackbar({
              message: t("retry-login"),
              severity: "error",
            })
          );
          navigate("/");
        } else {
          console.error("Errore nel authenticazione dashboard", error);
        }
      }
    };

    checkAuthentication();
    dispatch(fetchNotifications());
  }, []);

  return (
    <div>
      <HeaderDashboardPage handleLogout={handleLogout} />
      {window.innerWidth < 500 ? <BottomBarMenu /> : <SideMenu />}
      {isLoading ? <GenericLoader /> : <Outlet />}
    </div>
  );
};
