import React, { useEffect } from "react";
import "../../assets/styles/Pages/admin/HomeAdminPage.scss";
import { AdminKpi } from "../../components/admin/kpi/AdminKpi";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  fetchCategories,
  fetchNotifications,
  fetchTransactions,
  fetchUsers,
} from "../../redux/adminSlice";
import { GenericLoader } from "../../components/loader/GenericLoader";

export const HomeAdminPage: React.FC = () => {
  const admin = useAppSelector((state) => state.admin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchNotifications());
    dispatch(fetchTransactions());
    dispatch(fetchCategories());
  }, []);

  return (
    <div className="partial-admin-page">
      <div className="dash-container">
        {admin.loading === true ? (
          <GenericLoader />
        ) : (
          <div className="dash-content">
            <div className="kpi-content">
              <AdminKpi
                counter={admin.data.users.length}
                type="users"
                subtitle="Sottotilo figo users"
              />
              <AdminKpi
                counter={admin.data.transactionsCounter}
                type="transactions"
                subtitle="Sottotilo figo transactions"
              />
              <AdminKpi
                counter={admin.data.categoriesCounter}
                type="categories"
                subtitle="Sottotilo figo categories"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
