import React, { useEffect, useState } from "react";
import { isNaN } from "lodash";
import { useAppDispatch } from "../hooks/useRedux";
import { openSnackbar } from "../redux/snackbarSlice";
import { useTranslation } from "react-i18next";

interface NumberInputProps {
  setAmount: (amount: number) => void;
  amount: number;
}
export const NumberInput: React.FunctionComponent<NumberInputProps> = ({
  setAmount,
  amount,
}) => {
  const [number, setNumber] = useState<number | string>("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      if (value >= 1) {
        setAmount(value);
        setNumber(value.toFixed(2));
      } else {
        setAmount(0);
        setNumber("");
        dispatch(
          openSnackbar({
            message: t("amount-greater-zero"),
            severity: "error",
          })
        );
      }
    } else {
      setAmount(0);
      setNumber("");
    }
  };

  useEffect(() => {
    setNumber(amount.toFixed(2));
  }, []);
  
  return (
    <div className="input-number-div-inner">
      <input
        type="number"
        id="amount"
        name="amount"
        className="input-number"
        value={number}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      />
      <span className="input-number-span">€</span>
    </div>
  );
};
