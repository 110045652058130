import React, { useEffect, useMemo, useState } from "react";
import "../assets/styles/Pages/TransactionsHistory.scss";
import "../assets/styles/Components/DatePagination.scss";
import _, { capitalize } from "lodash";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { pink } from "@mui/material/colors";
import Lottie from "lottie-react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import animationData from "../assets/NoDataFound.json";
import { NoResultsFoundAnimation } from "../components/NoResultsFoundAnimation";
import { TransactionCard } from "../components/TransactionCard";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { Search } from "../components/SearchInput";
import { useFilterTransactions } from "../hooks/useFilterTransactions";
import { TransactionsFilterSection } from "../components/TransactionsFilterSection";
import { DatePagination, PaginationMode } from "../components/DatePagination";
import {
  DatePaginationItem,
  createDayListForPagination,
  createMonthListForPagination,
  createWeekListForPagination,
  createYearListForPagination,
} from "../utils/DatePagination";
import { DatePaginationModeModal } from "../components/modal/DatePaginationModeModal";
import { RadioButtonTransactionType } from "../components/RadioButtonTransactionType";
import { AnimatePresence, motion } from "framer-motion";
import { format } from "date-fns";
import { Transaction } from "../redux/transactionsSlice";
import { defaultCategory } from "../redux/categoriesSlice";
import { downloadTransactionsAsync } from "../services/Api";
import { openSnackbar } from "../redux/snackbarSlice";
import { DownloadTransactionsLoader } from "../components/loader/DownloadTransactionsLoader";
import { useTranslation } from "react-i18next";
import { UploadFileModal } from "../components/modal/UploadFileModal";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const TransactionsHistory: React.FunctionComponent = () => {
  const transactions = useAppSelector((state) => state.transactions);
  const categories = useAppSelector((state) => state.categories);
  const appSettings = useAppSelector((state) => state.appsettings);
  const dispatch = useAppDispatch();
  const isLoading = transactions.loading || categories.loading;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const {
    searchQuery,
    sortFilterData,
    isSortFilterSectionOpen,
    handleSearch,
    handleSortFilterClick,
    isSortedOrderDesc,
    handleSort,
    handleFilterType,
    handleFilterCategory,
    handleSortClick,
  } = useFilterTransactions(transactions.data);
  const [isDatePaginationModeModalOpen, setIsDatePaginationModeModalOpen] =
    useState<boolean>(false);
  const [paginationMode, setPaginationMode] = useState<PaginationMode>(
    appSettings.datePaginationMode
  );
  const [isImportExportMenuOpen, setIsImportExportMenuOpen] =
    useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [checkedVisibleFilter, setCheckedVisibleFilter] = useState<
    Record<string, boolean>
  >({});
  const { t } = useTranslation();

  const days = createDayListForPagination(transactions.data);
  const weeks = createWeekListForPagination(transactions.data);
  const months = createMonthListForPagination(transactions.data);
  const years = createYearListForPagination(transactions.data);

  const getPaginationItem = (paginationMode: PaginationMode) => {
    switch (paginationMode) {
      case PaginationMode.Daily:
        return days;
      case PaginationMode.Weekly:
        return weeks;
      case PaginationMode.Monthly:
        return months;
      case PaginationMode.Yearly:
        return years;
      default:
        return months;
    }
  };

  const [datePaginationItem, setDatePaginationItem] = useState<
    DatePaginationItem[]
  >(getPaginationItem(appSettings.datePaginationMode));
  const [currentDatePagination, setCurrentDatePagination] = useState<number>(
    months.length - 1
  );

  const changePaginationMode = (paginationMode: PaginationMode) => {
    switch (paginationMode) {
      case PaginationMode.Daily:
        setDatePaginationItem(days);
        setCurrentDatePagination(days.length - 1);
        return true;
      case PaginationMode.Weekly:
        setDatePaginationItem(weeks);
        setCurrentDatePagination(weeks.length - 1);
        return true;
      case PaginationMode.Monthly:
        setDatePaginationItem(months);
        setCurrentDatePagination(months.length - 1);
        return true;
      case PaginationMode.Yearly:
        setDatePaginationItem(years);
        setCurrentDatePagination(years.length - 1);
        return true;
      default:
        return false;
    }
  };

  const filteredAndSortedTransactions = useMemo(
    () =>
      _.orderBy(
        _.filter(sortFilterData.results, (transactionResult: Transaction) => {
          return datePaginationItem[currentDatePagination].transactions.some(
            (transaction) =>
              transactionResult.description === transaction.description &&
              transactionResult.created_at === transaction.created_at
          );
        }),
        sortFilterData.sortProperty,
        sortFilterData.sortOrder
      ),
    [
      sortFilterData.results,
      datePaginationItem,
      currentDatePagination,
      sortFilterData.sortProperty,
      sortFilterData.sortOrder,
    ]
  );

  const transactionsByFilter: Record<string, Transaction[]> = useMemo(() => {
    const groupedTransactions: Record<string, Transaction[]> = {};
    filteredAndSortedTransactions.forEach((transaction) => {
      let transactionFilter: string;
      if (sortFilterData.sortProperty === "created_at") {
        transactionFilter = format(new Date(transaction.created_at!), "dd/MM");
      } else if (sortFilterData.sortProperty === "category") {
        transactionFilter = transaction.category;
      } else if (sortFilterData.sortProperty === "amount") {
        transactionFilter = transaction.amount.toFixed(2) + " €";
      } else if (sortFilterData.sortProperty === "t_type") {
        transactionFilter = t(transaction.t_type);
      } else if (sortFilterData.sortProperty === "is_recurring") {
        transactionFilter = transaction.is_recurring ? t("yes") : t("no");
      } else if (sortFilterData.sortProperty === "recurring_type") {
        transactionFilter = transaction.recurring_type ?? t("nobody");
      } else if (sortFilterData.sortProperty === "recurring_date") {
        transactionFilter = transaction.recurring_date
          ? "" + transaction.recurring_date
          : t("nobody");
      } else {
        transactionFilter = "";
      }
      if (!groupedTransactions[transactionFilter]) {
        groupedTransactions[transactionFilter] = [];
      }
      groupedTransactions[transactionFilter].push(transaction);
    });
    return groupedTransactions;
  }, [filteredAndSortedTransactions, sortFilterData.sortProperty]);

  let totalAmountPerFilter = 0;

  const transactionsToRender = Object.entries(transactionsByFilter).map(
    ([filter, transactions], i) => {
      return (
        <div key={i} className="grouped-transactions">
          <div className="label-filter">
            <div className="history-div"></div>
            <p
              className={
                "history-p" + (!appSettings.isDarkMode ? " light" : "")
              }
            >
              {capitalize(filter)}
              <label htmlFor={"check" + filter} className="history-p-checkbox">
                <input
                  id={"check" + filter}
                  type="checkbox"
                  name={"check" + filter}
                  className="history-p-input"
                  checked={!!checkedVisibleFilter[filter]}
                  onChange={() => handleCheckboxChange(filter)}
                />
                {!!!checkedVisibleFilter[filter] ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </label>
            </p>
          </div>
          {checkedVisibleFilter[filter] &&
            transactions.map((transaction, j) => {
              const category = _.find(
                categories.data,
                (category) => category.name === transaction.category
              );

              if (transaction.t_type === "expense") {
                totalAmountPerFilter -= transaction.amount;
              } else {
                totalAmountPerFilter += transaction.amount;
              }

              return (
                <TransactionCard
                  key={j}
                  transaction={transaction}
                  category={category ?? defaultCategory}
                />
              );
            })}
        </div>
      );
    }
  );

  const handleResetFilterClick = () => {
    // HACK per resettare i filtri di ricerca DA SISTEMARE
    window.location.reload();
  };

  const handleCheckboxChange = (id: string) => {
    setCheckedVisibleFilter((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const downloadTransactions = async (transactions: Transaction[]) => {
    if (transactions.length >= 1) {
      setIsDownloading(true);
      try {
        const response = await downloadTransactionsAsync(
          transactions,
          datePaginationItem[currentDatePagination].label
        );

        if (response.status === 500) {
          dispatch(
            openSnackbar({
              message: t("download-stopped"),
              severity: "error",
            })
          );
        }
      } catch (error) {
        console.error("Errore durante il download:", error);
        // alert("Errore durante il download");
      } finally {
        setIsDownloading(false);
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("no-transactions-for-download"),
          severity: "error",
        })
      );
    }
  };

  const uploadTransactions = async () => {
    setIsUploadModalOpen(true);
  };

  const toggleImportExportMenuVisibility = () => {
    setIsImportExportMenuOpen(!isImportExportMenuOpen);
  };

  useEffect(() => {
    if (Object.keys(transactionsByFilter).length > 0) {
      const initCheckedVisibleFilter: Record<string, boolean> = {};
      for (const filter in transactionsByFilter) {
        initCheckedVisibleFilter[filter] = true;
      }
      setCheckedVisibleFilter(initCheckedVisibleFilter);
    }
  }, [datePaginationItem, transactionsByFilter]);

  return (
    <div className="partial-dashboard-history-page">
      <div className="dash-container">
        <div className="dash-content">
          {!isLoading && transactions.data.length < 1 && (
            <div key="no-results-found" className="no-results-found">
              <h3>{t("no-transactions-found")}</h3>
              <NoResultsFoundAnimation />
              <p>
                Premi il pulsante in basso per inserire il tuo primo movimento
              </p>
              <div key="arrow-demo" className="arrow-demo">
                <KeyboardDoubleArrowDownIcon sx={{ fontSize: 70 }} />
              </div>
            </div>
          )}
          {isDownloading && <DownloadTransactionsLoader />}
          {!isLoading &&
            transactions.data.length >= 1 && [
              <div key={"history-content"} className="history-content">
                <DatePagination
                  datePaginationItem={datePaginationItem}
                  currentDatePagination={currentDatePagination}
                  setCurrentDatePagination={setCurrentDatePagination}
                  setIsDatePaginationModeModalOpen={
                    setIsDatePaginationModeModalOpen
                  }
                />
                <h3 className="history-h3">{t("total")}</h3>
                <div className="history-h2-div">
                  <h2
                    className={
                      "history-h2 " +
                      (totalAmountPerFilter >= 0 ? " positive" : " negative")
                    }
                  >
                    {totalAmountPerFilter.toFixed(2)}
                  </h2>
                  <div
                    className="import-export"
                    onClick={() => toggleImportExportMenuVisibility()}
                  >
                    <ImportExportIcon sx={{ fontSize: 30 }} />
                    <AnimatePresence>
                      {isImportExportMenuOpen && (
                        <ClickAwayListener
                          onClickAway={toggleImportExportMenuVisibility}
                        >
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                              opacity: 1,
                              transform: "translateY(10px)",
                            }}
                            transition={{ duration: 0.2 }}
                            exit={{ opacity: 0, scale: 0.5 }}
                            className="import-export-content"
                          >
                            <div
                              className="download"
                              onClick={() =>
                                downloadTransactions(
                                  filteredAndSortedTransactions
                                )
                              }
                            >
                              {t("download")}
                              <DownloadIcon
                                sx={{ fontSize: 30, marginLeft: 0.8 }}
                              />
                            </div>
                            <div
                              className="upload"
                              onClick={() => uploadTransactions()}
                            >
                              {t("upload")}
                              <UploadIcon
                                sx={{ fontSize: 30, marginLeft: 0.8 }}
                              />
                            </div>
                          </motion.div>
                        </ClickAwayListener>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <div key={"search-sort-filter"} className="search-sort-filter">
                  <div
                    className={
                      "action" + (isSortFilterSectionOpen ? " selected" : "")
                    }
                    onClick={handleSortFilterClick}
                  >
                    <FilterAltIcon />
                  </div>
                  <Search searchQuery={searchQuery} onSearch={handleSearch} />
                  <div
                    className="action reset"
                    onClick={handleResetFilterClick}
                  >
                    <RestartAltIcon
                      sx={{ color: pink[500], fontSize: "inherit" }}
                    />
                  </div>
                </div>
                {isSortFilterSectionOpen && (
                  <TransactionsFilterSection
                    key={"transactions-filter-section"}
                    categories={categories.data}
                    sortFilterData={sortFilterData}
                    isSortedOrderDesc={isSortedOrderDesc}
                    handleSort={handleSort}
                    handleFilterType={handleFilterType}
                    handleFilterCategory={handleFilterCategory}
                    handleSortClick={handleSortClick}
                  />
                )}
                <div className="history-filter-type">
                  <RadioButtonTransactionType
                    t_type={sortFilterData.filterType}
                    handleInputChange={(event) =>
                      handleFilterType(event.target.value)
                    }
                    showAll={true}
                  />
                </div>
              </div>,
              transactionsToRender.length > 0 ? (
                <div key={"transactions-list"} className="transactions-list">
                  {transactionsToRender}
                </div>
              ) : (
                <span key={"lottie"}>
                  <Lottie
                    animationData={animationData}
                    style={{ height: 400 }}
                  />
                </span>
              ),
            ]}
        </div>
      </div>
      <AnimatePresence>
        {isDatePaginationModeModalOpen && (
          <DatePaginationModeModal
            setIsDatePaginationModeModalOpen={setIsDatePaginationModeModalOpen}
            changePaginationMode={changePaginationMode}
            paginationMode={paginationMode}
            setPaginationMode={setPaginationMode}
          />
        )}
        {isUploadModalOpen && (
          <UploadFileModal setIsOpen={setIsUploadModalOpen} />
        )}
      </AnimatePresence>
    </div>
  );
};
