import { useEffect, useState } from "react";
import { Transaction } from "../redux/transactionsSlice";
import _ from "lodash";

export type SortFilterType = {
  filterType: string;
  filterCategory: string;
  sortProperty: string;
  sortOrder: "asc" | "desc";
  searchText: string;
  results: Transaction[];
};

export const useFilterTransactions = (transactions: Transaction[]) => {
  const initSortFilterType: SortFilterType = {
    filterType: "all",
    filterCategory: "all",
    sortProperty: "created_at",
    sortOrder: "desc",
    searchText: "",
    results: [],
  };

  const [sortFilterData, setSortFilterData] =
    useState<SortFilterType>(initSortFilterType);
  const [isSortedOrderDesc, setIsSortedOrderDesc] = useState<boolean>(true);
  const [isSortFilterSectionOpen, setIsSortFilterSectionOpen] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    let results: Transaction[] = [];
    if (query.trim() === "" || query.length <= 1) {
      results = transactions;
    } else {
      const filteredData = transactions.filter((item) =>
        item.description.toLowerCase().includes(query.toLowerCase())
      );
      results = filteredData;
    }

    let filterByTypeResults = results;

    if (sortFilterData.filterType !== "all") {
      filterByTypeResults = _.filter(results, {
        t_type: sortFilterData.filterType,
      });
    }

    let filterByCategoryResults = filterByTypeResults;

    if (sortFilterData.filterCategory !== "all") {
      filterByCategoryResults = _.filter(filterByTypeResults, {
        category: sortFilterData.filterCategory,
      });
    }

    setSortFilterData({
      ...sortFilterData,
      results: filterByCategoryResults,
      searchText: query,
    });
  };

  const handleSort = (property: string, order: "asc" | "desc") => {
    const sortedData = _.orderBy(sortFilterData.results, [property], [order]);
    setSortFilterData({
      ...sortFilterData,
      results: sortedData,
      sortProperty: property,
      sortOrder: order,
    });
    setIsSortedOrderDesc(order === "desc" ? true : false);
  };

  const handleFilterType = (typeFilter: string) => {
    const filteredData = transactions.filter((item) => {
      const nameMatch =
        sortFilterData.searchText.trim() === "" ||
        sortFilterData.searchText.length <= 1 ||
        item.description
          .toLowerCase()
          .includes(sortFilterData.searchText.toLowerCase());
      const typeMatch = typeFilter === "all" || item.t_type === typeFilter;
      return nameMatch && typeMatch;
    });

    setSortFilterData({
      ...sortFilterData,
      results: filteredData,
      filterType: typeFilter,
      filterCategory:
        typeFilter === "all" ? typeFilter : sortFilterData.filterCategory,
    });
  };

  const handleFilterCategory = (categoryFilter: string) => {
    const filteredData = transactions.filter((item) => {
      const nameMatch =
        sortFilterData.searchText === "" ||
        sortFilterData.searchText.length <= 1 ||
        item.description
          .toLowerCase()
          .includes(sortFilterData.searchText.toLowerCase());
      const categoryMatch =
        categoryFilter === "all" || item.category === categoryFilter;
      return nameMatch && categoryMatch;
    });

    setSortFilterData({
      ...sortFilterData,
      results: filteredData,
      filterCategory: categoryFilter,
    });
  };

  const handleSortFilterClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsSortFilterSectionOpen(!isSortFilterSectionOpen);
  };

  const handleSortClick = (order: "asc" | "desc") => {
    setIsSortedOrderDesc(order === "desc" ? true : false);
    setSortFilterData({ ...sortFilterData, sortOrder: order });
  };

  useEffect(() => {
    setSortFilterData({ ...sortFilterData, results: transactions });
  }, []);

  return {
    searchQuery,
    setSortFilterData,
    sortFilterData,
    isSortedOrderDesc,
    isSortFilterSectionOpen,
    handleSearch,
    handleSort,
    handleFilterType,
    handleFilterCategory,
    handleSortFilterClick,
    handleSortClick,
  };
};
