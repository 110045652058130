import React, { useState } from "react";
import "../assets/styles/Components/SideMenu.scss";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export const SideMenu: React.FunctionComponent = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleMenuClick = (menu: string) => {
    navigate(`/dashboard/${menu}`);
  };

  const setPageSelected = (uri: string) => {
    return location.pathname === "/dashboard/" + uri ? "selected-page" : "";
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <>
      <div
        className={"side-menu " + (!isSideMenuOpen ? " side-menu-close" : " ")}
      >
        <div className="side-menu-inner">
          <div className="side-menu-btn-action" onClick={handleMenuOpen}>
            {isSideMenuOpen ? (
              <MenuOpenIcon sx={{ fontSize: 32 }} />
            ) : (
              <MenuIcon sx={{ fontSize: 32 }} />
            )}
          </div>
          <div className="side-menu-content">
            <nav className="side-menu-nav">
              <ul className="side-menu-ul">
                <div
                  className="side-menu-li btn-add link"
                  onClick={() => handleMenuClick("createNew")}
                >
                  <AddIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                  <p className="side-menu-li-p">{t("new")}</p>
                </div>
                <li
                  onClick={() => handleMenuClick("home")}
                  className={"side-menu-li link " + setPageSelected("home")}
                >
                  <DataUsageIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                  <p className="side-menu-li-p">{t("dashboard")}</p>
                </li>
                <li
                  onClick={() => handleMenuClick("transactions")}
                  className={
                    "side-menu-li link " + setPageSelected("transactions")
                  }
                >
                  <CurrencyExchangeIcon
                    sx={{ fontSize: 32, marginRight: 0.8 }}
                  />
                  <p className="side-menu-li-p">{t("transactions")}</p>
                </li>
                <li
                  onClick={() => handleMenuClick("charts")}
                  className={"side-menu-li link " + setPageSelected("charts")}
                >
                  <DataUsageIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                  <p className="side-menu-li-p">{t("charts")}</p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
