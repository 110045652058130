import React, { useState } from "react";
import "../assets/styles/Pages/ContactPage.scss";
import { GenericLoader } from "../components/loader/GenericLoader";
import { BackButton } from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import { ContactForm } from "../components/form/ContactForm";

export const ContactPage: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const backFunction = () => {
    navigate("/dashboard/home");
  };

  return (
    <div className="partial-contact-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="contact-container">
            {isLoading ? (
              <GenericLoader />
            ) : (
              <>
                <BackButton backFunction={backFunction} />
                <ContactForm setIsLoading={setIsLoading} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
