import React from "react";
import "../assets/styles/Pages/NewTransaction.scss";
import { TransactionForm } from "../components/form/TransactionForm";
import { BackButton } from "../components/BackButton";
import { useTranslation } from "react-i18next";

export const NewTransaction: React.FunctionComponent = () => {
  const { t } = useTranslation();
  
  return (
    <div className="partial-dashboard-new-transaction-page">
      <div className="dash-container">
        <div className="dash-content">
          <BackButton />
          <h2 className="new-transaction-h2">{t('new-transaction')}</h2>
          <TransactionForm />
        </div>
      </div>
    </div>
  );
};
