import React from "react";
import "../../../assets/styles/Components/admin/kpi/AdminKpi.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useRedux";

interface AdminKpiProps {
  counter: number;
  type: "users" | "transactions" | "categories";
  subtitle: string;
}

export const AdminKpi: React.FC<AdminKpiProps> = ({
  counter,
  type,
  subtitle,
}) => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  return (
    <div
      className={
        "kpi-container" +
        (!appSettings.isDarkMode ? " kpi-container-light" : "")
      }
      data-bg-color={type}
    >
      <div className="kpi-content">
        <div className="kpi-title">
          <h3 className="kpi-title-h3">
            <span className="kpi-title-h3-span">{counter}</span> {t(type)}
          </h3>
        </div>
        <div className="kpi-subtitle">
          <p className="kpi-subtitle-p">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};
