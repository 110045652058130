import React, { useState } from "react";
import { BackButton } from "../components/BackButton";
import { TransactionForm } from "../components/form/TransactionForm";
import { NewCategoryForm } from "../components/form/NewCategoryForm";
import { RadioButtonCreateNew } from "../components/RadioButtonCreateNew";

export const CreatePage: React.FC = () => {
  const [isTransactionMode, setIsTransactionMode] = useState<boolean>(true);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (event.target.value === "transaction") {
      setIsTransactionMode(true);
    } else {
      setIsTransactionMode(false);
    }
  };

  return (
    <div className="partial-dashboard-new-transaction-page">
      <div className="dash-container">
        <div className="dash-content">
          <BackButton />
          <RadioButtonCreateNew
            isTransactionMode={isTransactionMode}
            handleInputChange={handleInputChange}
          />
          {isTransactionMode ? (
            <TransactionForm />
          ) : (
            <NewCategoryForm isTitleVisible={false} />
          )}
        </div>
      </div>
    </div>
  );
};
