import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/NoResultsFound.json";

export const NoResultsFoundAnimation: React.FunctionComponent = () => {
  return (
    <div className="loader-img">
      <Lottie animationData={animationData} />
    </div>
  );
};
