import React, { ChangeEvent, FormEvent, useState } from "react";
import { useAppSelector } from "../../hooks/useRedux";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import EmailIcon from "@mui/icons-material/Email";
import { sendMailToSupportAsync } from "../../services/Api";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";

export const ContactForm: React.FunctionComponent<{
  setIsLoading: (isLoading: boolean) => void;
}> = (props) => {
  const { setIsLoading } = props;
  const settings = useAppSelector((state) => state.appsettings);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [rating, setRating] = useState<number | null>(null);

  const handleTextAreaOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (message !== null && message !== "") {
      setIsLoading(true);
      try {
        const response = await sendMailToSupportAsync(message, rating);

        if (response.status === 200) {
          dispatch(
            openSnackbar({
              message: t("thanks-for-help"),
              severity: "success",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: t("error-try-again"),
              severity: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          openSnackbar({
            message: t("error-try-again"),
            severity: "error",
          })
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className={
        "contact-content" +
        (!settings.isDarkMode ? " contact-content-light" : "")
      }
    >
      <h2 className="contact-h2">{t("contacts")}</h2>
      <div className="contact-div contact-div-inline">
        <EmailIcon sx={{ fontSize: 32, marginRight: 2 }} />
        <a
          href="mailto:info@mymoneytracker.it"
          className={
            "contact-a" + (!settings.isDarkMode ? " contact-a-light" : "")
          }
        >
          info@mymoneytracker.it
        </a>
      </div>
      <h3 className="contact-h3">{t("help-us")}</h3>
      <div className="contact-div contact-div-rating">
        <Rating
          name="rating-app"
          value={rating}
          size="large"
          icon={
            <StarIcon
              fontSize="inherit"
              sx={{ color: settings.isDarkMode ? "#eaee05" : "#110382" }}
            />
          }
          emptyIcon={
            <StarBorderIcon
              fontSize="inherit"
              sx={{ color: settings.isDarkMode ? "#c4c4c4" : "#aaa" }}
            />
          }
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </div>
      <p className="contact-p">{t("help-us-subtitle")}</p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-div">
          <textarea
            id="helpUs"
            name="helpUs"
            className="contact-textarea"
            value={message}
            onChange={handleTextAreaOnChange}
            required
          />
        </div>
        <div className="contact-div contact-div-submit">
          <button type="submit" className="contact-btn-submit">
            {t("send")}
          </button>
        </div>
      </form>
    </div>
  );
};
