import React from "react";
import { User } from "../../pages/ProfilePage";
import { capitalize } from "lodash";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { requestResetPasswordAsync } from "../../services/Api";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { useTranslation } from "react-i18next";

export const ProfileData: React.FunctionComponent<{
  user: User;
  setIsChangePasswordForm: (isChangePasswordForm: boolean) => void;
}> = (props) => {
  const { user, setIsChangePasswordForm } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleResetPassword = async () => {
    try {
      const response = await requestResetPasswordAsync(user.email);

      if (response.status === 200) {
        dispatch(
          openSnackbar({
            message: t("mail-sended"),
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: t("mail-not-sended"),
          severity: "error",
        })
      );
    }
  };

  return (
    <div className="profile-data">
      <h2 className="profile-data-h2">{t("profile")}</h2>
      <div>
        <p>{t("fullname")}:</p>
        <p className="data">
          {capitalize(user.first_name)} {capitalize(user.last_name)}
        </p>
      </div>
      <div>
        <p>{t("registeredon")}:</p>
        <p className="data">
          {format(user.created_at, "dd MMMM yyyy - HH:mm:ss", { locale: it })}
        </p>
      </div>
      <div>
        <p>{t("email")}:</p>
        <p className="data">{user.email}</p>
      </div>
      <div>
        <p>{t("password")}:</p>
        <div className="password-section">
          <button className="link reset" onClick={handleResetPassword}>
            {t("reset")}
          </button>
          <button
            className="link change"
            onClick={() => setIsChangePasswordForm(true)}
          >
            {t("change")}
          </button>
        </div>
      </div>
      {/* <button className="link edit" onClick={() => {}}>
        Modifica
      </button> */}
    </div>
  );
};
