import React from "react";
import { PasswordInput } from "./PasswordInput";
import { comparePasswords } from "../../utils/FormValidators";
import { resetPasswordAsync } from "../../services/Api";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface ResetPasswordFormFields {
  readonly token: string;
  readonly newPassword: string;
  readonly confirmPassword: string;
}

export const ResetPasswordForm: React.FunctionComponent<{
  setIsLoading: (isLoading: boolean) => void;
  token: string;
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
  } = useForm<ResetPasswordFormFields>();
  const { setIsLoading, token } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const onSubmit: SubmitHandler<ResetPasswordFormFields> = async (data) => {
    const isPasswordsEqual = comparePasswords(
      data.newPassword,
      data.confirmPassword
    );
    if (!isPasswordsEqual) {
      dispatch(
        openSnackbar({
          message: t("passwords-not-match"),
          severity: "error",
        })
      );
      setValue("confirmPassword", "");
      return;
    }
    try {
      setIsLoading(true);
      const response = await resetPasswordAsync({ ...data, token: token });

      if (response.status === 200) {
        dispatch(
          openSnackbar({
            message: t("password-updated"),
            severity: "success",
          })
        );
        navigate("/");
      } else {
        dispatch(
          openSnackbar({
            message: t("error-reset-password"),
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: t("error-reset-password"),
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="reset-password-form">
      <div className="password">
        <PasswordInput
          id={"newPassword"}
          className={errors.newPassword ? " form-input-error" : ""}
          label={"Nuova password"}
          register={register("newPassword", {
            required: true,
            min: 8,
            pattern: passwordPattern,
          })}
          setFocus={setFocus}
        />
      </div>
      <div className="password">
        <label className="reset-password-label" htmlFor="confirmpassword">
          {t("confirm-password")}:
        </label>
        <input
          type="password"
          id="confirmpassword"
          className={errors.confirmPassword ? "form-input-error" : ""}
          {...register("confirmPassword", {
            required: true,
            min: 8,
            pattern: passwordPattern,
          })}
        />
      </div>
      <button type="submit">{t("save")}</button>
    </form>
  );
};
