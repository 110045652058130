// AdminRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const AdminRoute: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const { getItem } = useLocalStorage();
  const role = getItem("role");

  if (isLoggedIn) {
    return role === "admin" ? (
      <Outlet />
    ) : (
      <Navigate to={"/dashboard"} replace />
    );
  } else {
    return <Navigate to={"/"} replace />;
  }
};
