import { Transaction } from "../redux/transactionsSlice";
import { format } from "date-fns/format";
import { Transaction_type } from "../hooks/useTransactionForm";
import { ChartSettings } from "../redux/appsettingsSlice";
import { Category } from "../redux/categoriesSlice";

export interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
    data: number[];
  }[];
}

export interface ChartSingleData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface ProcessData {
  labels: string[];
  incomes: number[];
  expenses: number[];
}

interface ProcessSingleData {
  labels: string[];
  totalAmount: number[];
}

const createChartSingleData = (
  labels: string[],
  datasetLabel: string,
  data: number[],
  backgroundColors: string[]
) => {
  return {
    labels: labels,
    datasets: [
      {
        label: datasetLabel,
        data: data,
        backgroundColor: backgroundColors,
        borderColor: ["rgb(0, 0, 0)"],
        borderWidth: 1,
        borderRadius: 30,
        spacing: 10,
      },
    ],
  };
};

export const createChartData = (
  labels: string[],
  datasetLabels: string[],
  incomesData: number[],
  expensesData: number[]
) => {
  return {
    labels: labels,
    datasets: [
      {
        label: datasetLabels[0],
        backgroundColor: "rgba(12, 199, 99,0.2)",
        borderColor: "rgba(12, 199, 99,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(12, 199, 99,0.4)",
        hoverBorderColor: "rgba(12, 199, 99,1)",
        data: incomesData,
      },
      {
        label: datasetLabels[1],
        backgroundColor: "rgba(217, 39, 15,0.2)",
        borderColor: "rgba(217, 39, 15,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(217, 39, 15,0.4)",
        hoverBorderColor: "rgba(217, 39, 15,1)",
        data: expensesData,
      },
    ],
  };
};

export const createCustomChartData = (
  labels: string[],
  datasetsLabel: string[],
  firstDatasetColor: string,
  firstDatasetBorderW: number,
  firstData: number[],
  secondDatasetColor: string,
  secondDatasetBorderW: number,
  secondData: number[]
) => {
  return {
    labels: labels,
    datasets: [
      {
        label: datasetsLabel[0],
        backgroundColor: `rgba(${firstDatasetColor},0.2)`,
        borderColor: `rgba(${firstDatasetColor},1)`,
        borderWidth: firstDatasetBorderW,
        hoverBackgroundColor: `rgba(${firstDatasetColor},0.4)`,
        hoverBorderColor: `rgba(${firstDatasetColor},1)`,
        data: firstData,
      },
      {
        label: datasetsLabel[1],
        backgroundColor: `rgba(${secondDatasetColor},0.2)`,
        borderColor: `rgba(${secondDatasetColor},1)`,
        borderWidth: secondDatasetBorderW,
        hoverBackgroundColor: `rgba(${secondDatasetColor},0.4)`,
        hoverBorderColor: `rgba(${secondDatasetColor},1)`,
        data: secondData,
      },
    ],
  };
};

const backgroundColorsChartSingleData = [
  "rgb(255, 0, 0)", // Rosso
  "rgb(0, 255, 0)", // Verde
  "rgb(0, 0, 255)", // Blu
  "rgb(255, 255, 0)", // Giallo
  "rgb(255, 0, 255)", // Magenta
  "rgb(0, 255, 255)", // Ciano
  "rgb(128, 0, 0)", // Rosso scuro
  "rgb(0, 128, 0)", // Verde scuro
  "rgb(0, 0, 128)", // Blu scuro
  "rgb(128, 128, 0)", // Giallo scuro
  "rgb(128, 0, 128)", // Magenta scuro
  "rgb(0, 128, 128)", // Ciano scuro
];

export const initChartData = (datasetLabels: string[]) => {
  return createChartData([], datasetLabels, [], []);
};

export const initChartSingleData = (datasetLabel: string) => {
  return createChartSingleData(
    [],
    datasetLabel,
    [],
    backgroundColorsChartSingleData
  );
};

export const initChartSettings: ChartSettings = {
  enabledAnimation: true,
  filterBy: "month",
};

const processDailyData = (
  viewMode: string,
  transactions: Transaction[]
): ProcessData => {
  const data: { [key: string]: { incomes: number; expenses: number } } = {};
  transactions.forEach((transaction) => {
    const monthYear = format(transaction.created_at!, "dd/MM/yyyy");
    if (!data[monthYear]) {
      data[monthYear] = { incomes: 0, expenses: 0 };
    }
    if (viewMode === "balance") {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += transaction.amount;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += transaction.amount;
      }
    } else {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += 1;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += 1;
      }
    }
  });

  // Estrai i mesi e i dati delle entrate e delle uscite
  const labels = Object.keys(data);
  const incomes = labels.map((month) => data[month].incomes);
  const expenses = labels.map((month) => data[month].expenses);

  return { labels, incomes, expenses };
};

const processMonthlyData = (
  viewMode: string,
  transactions: Transaction[]
): ProcessData => {
  const data: { [key: string]: { incomes: number; expenses: number } } = {};
  transactions.forEach((transaction) => {
    const monthYear = format(transaction.created_at!, "MM/yyyy");
    if (!data[monthYear]) {
      data[monthYear] = { incomes: 0, expenses: 0 };
    }
    if (viewMode === "balance") {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += transaction.amount;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += transaction.amount;
      }
    } else {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += 1;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += 1;
      }
    }
  });

  // Estrai i mesi e i dati delle entrate e delle uscite
  const labels = Object.keys(data);
  const incomes = labels.map((month) => data[month].incomes);
  const expenses = labels.map((month) => data[month].expenses);

  return { labels, incomes, expenses };
};

const processYearlyData = (
  viewMode: string,
  transactions: Transaction[]
): ProcessData => {
  const data: { [key: string]: { incomes: number; expenses: number } } = {};
  transactions.forEach((transaction) => {
    const monthYear = format(transaction.created_at!, "yyyy");
    if (!data[monthYear]) {
      data[monthYear] = { incomes: 0, expenses: 0 };
    }
    if (viewMode === "balance") {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += transaction.amount;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += transaction.amount;
      }
    } else {
      if (transaction.t_type === Transaction_type.Income) {
        data[monthYear].incomes += 1;
      } else if (transaction.t_type === Transaction_type.Expense) {
        data[monthYear].expenses += 1;
      }
    }
  });

  // Estrai i mesi e i dati delle entrate e delle uscite
  const labels = Object.keys(data);
  const incomes = labels.map((month) => data[month].incomes);
  const expenses = labels.map((month) => data[month].expenses);

  return { labels, incomes, expenses };
};

const processDailyCategoriesData = (
  viewMode: string,
  transactions: Transaction[]
): ProcessSingleData => {
  const data: {
    [category: string]: { totalAmount: number };
  } = {};

  transactions.forEach((transaction) => {
    const category = transaction.category;

    if (!data[category]) {
      data[category] = { totalAmount: 0 };
    }

    if (viewMode === "balance") {
      data[category].totalAmount += transaction.amount;
    } else {
      data[category].totalAmount += 1;
    }
  });

  const labels = Object.keys(data);
  const totalAmountData: number[] = [];

  labels.forEach((key) => {
    const dayData = data[key];
    const dayCategories = Object.keys(dayData);

    dayCategories.forEach((category) => {
      const totalAmount = dayData.totalAmount;
      totalAmountData.push(totalAmount);
    });
  });

  return {
    labels: labels,
    totalAmount: totalAmountData,
  };
};

export const setBalanceChartData = (
  transactions: Transaction[],
  labels: string[],
  filterBy: "day" | "month" | "year"
) => {
  let data: ProcessData = { labels: [], incomes: [], expenses: [] };
  if (filterBy === "day") {
    data = processDailyData("balance", transactions);
  } else if (filterBy === "year") {
    data = processYearlyData("balance", transactions);
  } else {
    data = processMonthlyData("balance", transactions);
  }

  return createChartData(data.labels, labels, data.incomes, data.expenses);
};

export const setCountChartData = (
  transactions: Transaction[],
  labels: string[],
  filterBy: "day" | "month" | "year"
) => {
  let data: ProcessData = { labels: [], incomes: [], expenses: [] };
  if (filterBy === "day") {
    data = processDailyData("count", transactions);
  } else if (filterBy === "year") {
    data = processYearlyData("count", transactions);
  } else {
    data = processMonthlyData("count", transactions);
  }

  return createChartData(data.labels, labels, data.incomes, data.expenses);
};

export const setCategoriesChartData = (
  transactions: Transaction[],
  categories: Category[],
  label: string,
  filterBy: "day" | "month" | "year",
  viewMode: string
) => {
  let data: ProcessSingleData = {
    labels: [],
    totalAmount: [],
  };
  if (filterBy === "day") {
    data = processDailyCategoriesData(viewMode, transactions);
  } else if (filterBy === "year") {
    data = processDailyCategoriesData(viewMode, transactions);
  } else {
    data = processDailyCategoriesData(viewMode, transactions);
  }

  let backgroundColors: string[] = [];
  data.labels.forEach((categoryName) => {
    const foundCategory = categories.find(
      (category) => category.name === categoryName
    );
    if (foundCategory) {
      backgroundColors.push(foundCategory.color);
    }
  });

  return createChartSingleData(
    data.labels,
    label,
    data.totalAmount,
    backgroundColors
  );
};
