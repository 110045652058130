import React from "react";
import "../assets/styles/Components/SearchInput.scss";
import { useTranslation } from "react-i18next";
interface SearchProps {
  searchQuery: string;
  onSearch: (query: string) => void;
  autofocus?: boolean;
  setIsSearchBarOpen?: (isOpen: boolean) => void;
}

export const Search: React.FC<SearchProps> = ({
  searchQuery,
  onSearch,
  autofocus,
  setIsSearchBarOpen,
}) => {
  const { t } = useTranslation();

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = event.target.value;
    onSearch(query);
  };

  const handleBlurChange = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    // onSearch("");
    setTimeout(() => {
      setIsSearchBarOpen?.(false);
    }, 500);
  };

  return (
    <>
      <input
        type="seacrh"
        placeholder={t("search")}
        value={searchQuery}
        onChange={handleSearchInputChange}
        autoFocus={autofocus}
        onBlur={handleBlurChange}
      />
    </>
  );
};
