import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Transaction } from "../../redux/transactionsSlice";
import { ChartTypeItem } from "../../redux/appsettingsSlice";
import { useTranslation } from "react-i18next";
import {
  ChartSingleData,
  initChartSingleData,
  setCategoriesChartData,
} from "../../utils/Charts";
import { ChartRadioButtonGroup } from "../ChartRadioButtonGroup";
import { ChartMenuSettings } from "./ChartMenuSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAppSelector } from "../../hooks/useRedux";
import { ChartLegend } from "./ChartLegend";
import { AnimatePresence } from "framer-motion";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DonutChartProps {
  transactions: Transaction[];
  chartTypeItem: ChartTypeItem;
}

export const DonutChart: React.FC<DonutChartProps> = ({
  transactions,
  chartTypeItem,
}) => {
  const categories = useAppSelector((state) => state.categories);
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<ChartSingleData>(
    initChartSingleData(t("income"))
  );

  const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState<boolean>(false);

  let delayed = chartTypeItem.settings.enabledAnimation;
  const options = {
    cutout: 90,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    animation: {
      onComplete: () => {
        delayed = false;
      },
      delay: (context: {
        type: string;
        mode: string;
        dataIndex: number;
        datasetIndex: number;
      }) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
  };

  const toggleMenuSettingsVisibility = () => {
    setIsMenuSettingsOpen(!isMenuSettingsOpen);
  };

  useEffect(() => {
    if (chartTypeItem.viewMode === "balance") {
      setChartData(
        setCategoriesChartData(
          transactions,
          categories.data,
          t("total"),
          chartTypeItem.settings.filterBy,
          "balance"
        )
      );
    } else {
      setChartData(
        setCategoriesChartData(
          transactions,
          categories.data,
          t("total"),
          chartTypeItem.settings.filterBy,
          "count"
        )
      );
    }
  }, [chartTypeItem.viewMode, chartTypeItem.settings.filterBy]);

  return (
    <div className="chart-type-content">
      <div className="chart-type-content-div-settings">
        <ChartRadioButtonGroup selectedChartType={chartTypeItem} />
        <div
          className="chart-type-content-settings"
          onClick={() => toggleMenuSettingsVisibility()}
        >
          <SettingsIcon sx={{}} />
        </div>
        {/* <ChartMenuSettings
          selectedChartType={chartTypeItem}
          isOpen={isMenuSettingsOpen}
        /> */}
      </div>
      <div className="chart-type-content-donut-container">
        <h3 className="chart-type-content-donut-container-h3">
          {t("annual-total-per-category")}
        </h3>
        <ChartLegend data={chartData} />
        <div className="chart-type-content-donut">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
      <AnimatePresence>
        {isMenuSettingsOpen && (
          <ChartMenuSettings
            selectedChartType={chartTypeItem}
            isOpen={isMenuSettingsOpen}
            setIsOpen={setIsMenuSettingsOpen}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
