import React, { useEffect, useState } from "react";
import "../../assets/styles/Components/Form/TransactionForm.scss";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { DateView } from "@mui/x-date-pickers";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import { fetchCategories } from "../../redux/categoriesSlice";
import { openSnackbar } from "../../redux/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { nameValidator } from "../../utils/FormValidators";
import { AddCategoryModal } from "../modal/AddCategoryModal";
import { TransferLoader } from "../loader/TransferLoader";
import { GenericLoader } from "../loader/GenericLoader";
import { useTransactionForm } from "../../hooks/useTransactionForm";
import {
  Transaction,
  addTransactions,
  editTransactions,
  isTransactionsEqual,
} from "../../redux/transactionsSlice";
import { DatePickerModal } from "../modal/DatePickerModal";
import { RadioButtonTransactionType } from "../RadioButtonTransactionType";
import { CategorySelect } from "../CategorySelect";
import { AnimatePresence } from "framer-motion";
import { NumberInput } from "../NumberInput";
import { RadioButtonTransactionRecurringType } from "../RadioButtonTransactionRecurringType";
import { RadioButtonTransactionDate } from "../RadioButtonTransactionDate";
import { useTranslation } from "react-i18next";

export const TransactionForm: React.FunctionComponent<{
  transaction?: Transaction;
  setIsTransactionsModalOpen?: (isOpen: boolean) => void;
}> = (props) => {
  const { transaction, setIsTransactionsModalOpen } = props;
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.categories);
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] =
    useState<boolean>(false);
  const [isSubmittingFormData, setIsSubmittingFormData] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const {
    formData,
    setFormData,
    isModalOpen,
    setIsModalOpen,
    handleInputChange,
    handleCheckboxChange,
    handleRadioChange,
    selectCategory,
    setAmount,
  } = useTransactionForm();
  const { t } = useTranslation();

  const today = new Date();
  const [userDatePickerDayjs, setUserDatePickerDayjs] = useState<Dayjs | null>(
    dayjs(today)
  );

  const handleDatePickerOnChange = (
    value: any,
    selectionState?: PickerSelectionState | undefined,
    selectedView?: DateView | undefined
  ) => {
    setUserDatePickerDayjs(value);
    setFormData({ ...formData, created_at: value?.toDate() });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.category !== "") {
      const isDescriptionValid = nameValidator(formData.description);
      if (isDescriptionValid) {
        setIsSubmittingFormData(true);
        try {
          if (transaction === undefined) {
            const response = await dispatch(addTransactions(formData));

            if (response.meta.requestStatus === "fulfilled") {
              navigate("/dashboard/home");
            } else {
              alert("Accesso non autorizzato");
            }
          } else {
            if (!isTransactionsEqual(transaction, formData)) {
              const response = await dispatch(editTransactions(formData));

              if (response.meta.requestStatus === "fulfilled") {
                if (!!setIsTransactionsModalOpen) {
                  setIsTransactionsModalOpen(false);
                }
                navigate("/dashboard/home");
              } else {
                alert("Accesso non autorizzato");
              }
            } else {
              if (!!setIsTransactionsModalOpen) {
                setIsTransactionsModalOpen(false);
              } else {
                navigate("/dashboard/home");
              }
            }
          }
        } catch (error) {
          console.error("Errore durante l'accesso:", error);
          alert("Errore durante l'accesso:");
        } finally {
          setIsSubmittingFormData(false);
        }
      } else {
        dispatch(
          openSnackbar({
            message: t("description-invalid"),
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("select-category"),
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (transaction) {
      setFormData(transaction);
      setUserDatePickerDayjs(dayjs(transaction.created_at));
    }
    if (categories.data.length < 1) {
      dispatch(fetchCategories());
    }
  }, [transaction, dispatch, categories.data.length]);

  return (
    <>
      {categories.loading ? (
        <GenericLoader />
      ) : (
        <>
          <form
            id="transaction-form"
            onSubmit={handleSubmit}
            className={"new-transaction-form" + (transaction ? " edit" : "")}
          >
            <div className="new-transaction-div">
              <label htmlFor="t_type" className="new-transaction-label">
                {t("type")}
              </label>
              <RadioButtonTransactionType
                t_type={formData.t_type}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="new-transaction-div">
              <label htmlFor="category" className="new-transaction-label">
                {t("category")}
              </label>
              <CategorySelect
                categories={_.filter(
                  categories.data,
                  (category) => category.c_type === formData.t_type
                )}
                categoryName={formData.category}
                selectCategory={selectCategory}
              />
            </div>
            <div className="new-transaction-div">
              <label htmlFor="description" className="new-transaction-label">
                {t("description")}
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                disabled={!!transaction}
                required
              />
            </div>
            <div className="new-transaction-div input-number-div">
              <label htmlFor="amount" className="new-transaction-label">
                {t("amount")}
              </label>
              <NumberInput setAmount={setAmount} amount={transaction === undefined ? formData.amount : transaction.amount} />
            </div>
            <div className="new-transaction-div">
              <RadioButtonTransactionDate
                formData={formData}
                setFormData={setFormData}
                today={today}
                userDatePickerDayjs={userDatePickerDayjs}
                setIsDatePickerModalOpen={setIsDatePickerModalOpen}
              />
            </div>
            <div className="new-transaction-div radio-group">
              <label className="new-transaction-label">{t("recurring")}</label>
              <RadioButtonTransactionRecurringType
                is_recurring={formData.is_recurring}
                handleCheckoxChange={handleCheckboxChange}
                recurring_type={formData.recurring_type!}
                handleRadioChange={handleRadioChange}
              />
            </div>
            <div></div>
          </form>
          <div className="new-transaction-btn-submit">
            <button
              type="submit"
              form="transaction-form"
              className="new-transaction-submit-button"
            >
              {t("save")}
            </button>
          </div>
        </>
      )}
      {isSubmittingFormData && <TransferLoader />}
      <AnimatePresence>
        {isModalOpen && <AddCategoryModal setIsOpen={setIsModalOpen} />}
        {isDatePickerModalOpen && (
          <DatePickerModal
            setIsDatePickerModalOpen={setIsDatePickerModalOpen}
            userDatePickerDayjs={userDatePickerDayjs}
            handleDatePickerOnChange={handleDatePickerOnChange}
          />
        )}
      </AnimatePresence>
    </>
  );
};
