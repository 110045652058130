import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { EditCategoryModal } from "./modal/EditCategoryModal";
import { Category, deleteCategories } from "../redux/categoriesSlice";
import { DeleteModal } from "./modal/DeleteModal";
import { useAppSelector } from "../hooks/useRedux";
import { AnimatePresence } from "framer-motion";

export const CategoriesTableActionsButton: React.FunctionComponent<{
  category: Category;
}> = (props) => {
  const { category } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<Boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<Boolean>(false);
  const categories = useAppSelector((state) => state.categories);

  const openDeleteModal = (): void => {
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (): void => {
    setIsEditModalOpen(true);
  };

  return (
    <div>
      {category.user_id !== 0 && (
        <button className="table-button btn-edit" onClick={openEditModal}>
          <EditIcon sx={{ fontSize: 26 }} />
        </button>
      )}
      {category.user_id !== 0 && (
        <button className="table-button btn-delete" onClick={openDeleteModal}>
          <DeleteForeverIcon sx={{ fontSize: 26 }} />
        </button>
      )}
      <AnimatePresence>
        {isEditModalOpen && (
          <EditCategoryModal
            category={category}
            setIsOpen={setIsEditModalOpen}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            setIsOpen={setIsDeleteModalOpen}
            item={category}
            deleteFunction={deleteCategories}
            isLoading={categories.loading}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
