import React from "react";
import "../../assets/styles/Components/Modal/DeleteModal.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CloseButton } from "../CloseButton";
import { capitalize } from "lodash";
import { Transaction } from "../../redux/transactionsSlice";
import { Category } from "../../redux/categoriesSlice";
import { GenericLoader } from "../loader/GenericLoader";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  AsyncThunkAction,
  AsyncThunkConfig,
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

type ModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  item: Transaction | Category;
  deleteFunction: (
    arg: number
  ) => AsyncThunkAction<Transaction | Category, number, AsyncThunkConfig>;
  isLoading: boolean;
};

export const DeleteModal: React.FunctionComponent<ModalProps> = ({
  setIsOpen,
  item,
  deleteFunction,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  const closeModal = (): void => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  const isTransaction = (item: Transaction | Category): item is Transaction => {
    return (item as Transaction).description !== undefined;
  };

  const deleteGenericFunction = async (id: number) => {
    try {
      const response = await dispatch(deleteFunction(id));
    } catch (error) {
      console.error("Errore nella cancellazione delle transazioni", error);
    }
  };

  return (
    <div className="backdrop-panel-blur">
      <ClickAwayListener onClickAway={closeModal}>
        <motion.div
          className={
            "delete-modal" +
            (!appSettings.isDarkMode ? " delete-modal-light" : "")
          }
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, scale: 0.5 }}
        >
          <CloseButton
            className="btn-close"
            onClick={closeModal}
            fontSize={40}
          />
          <div className="delete-container">
            <div className="delete-content">
              {isTransaction(item) ? (
                <h3 className="delete-h3">
                  {t("confirm-delete")} '
                  <span className="delete-span">
                    {capitalize(item.description)}
                  </span>
                  ', {t("priced-at")}{" "}
                  <span className="delete-span">{item.amount}€</span>?
                </h3>
              ) : (
                <h3 className="delete-h3">
                  {t("confirm-delete")} '
                  <span className="delete-span">{capitalize(item.name)}</span>'?
                </h3>
              )}
              <div className="btn-group">
                <button
                  type="button"
                  className="btn-ok"
                  onClick={() => deleteGenericFunction(item.id)}
                >
                  {t("ok")}
                </button>
                <button type="button" className="btn-undo" onClick={closeModal}>
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </ClickAwayListener>
      {isLoading && <GenericLoader />}
    </div>
  );
};
