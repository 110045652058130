import { useEffect, useState } from "react";
import { Category } from "../redux/categoriesSlice";

const getRandomColor = (): string => {
  const letters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const initCategory = {
  id: 0,
  c_type: "expense",
  name: "",
  color: getRandomColor(),
  icon: "Home",
  user_id: 0,
};

export const useCategoryForm = (category?: Category) => {
  const [formData, setFormData] = useState<Category>(category ?? initCategory);
  const [isColorPickerVisible, setIsColorPickerVisible] =
    useState<boolean>(false);

  const handleColorPicker = (visible: boolean) => {
    setIsColorPickerVisible(visible);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    if (name === "type") {
      setFormData({ ...formData, c_type: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectColor = (color: string) => {
    setFormData({ ...formData, icon: formData.icon, color: color });
  };

  const handleSelectIcon = (iconName: string) => {
    setFormData({ ...formData, icon: iconName, color: formData.color });
  };

  useEffect(() => {}, [category]);

  return {
    formData,
    setFormData,
    isColorPickerVisible,
    setIsColorPickerVisible,
    handleColorPicker,
    handleSelectColor,
    handleSelectIcon,
    handleInputChange,
  };
};
