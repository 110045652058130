import React from "react";
import "../assets/styles/Components/RadioButtonTransactionRecurringType.scss";
import { Recurring_type } from "../hooks/useTransactionForm";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const RadioButtonTransactionRecurringType: React.FunctionComponent<{
  is_recurring: boolean;
  handleCheckoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  recurring_type: string;
  handleRadioChange: (r_type: Recurring_type) => void;
}> = (props) => {
  const {
    is_recurring,
    handleCheckoxChange,
    recurring_type,
    handleRadioChange,
  } = props;
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  return (
    <>
      <div
        className={
          "radio-recurring-type" +
          (!appSettings.isDarkMode ? " radio-recurring-type-light" : "")
        }
      >
        <div className="radio-recurring-type-inner">
          <label
            htmlFor="no_recurring"
            className={
              "radio-recurring-type-label " +
              (!is_recurring && "radio-recurring-type-selected")
            }
          >
            <input
              type="radio"
              name="is_recurring"
              id="no_recurring"
              value={"no"}
              checked={!is_recurring}
              onChange={handleCheckoxChange}
              className="radio-recurring-type-input"
            />
            {t("no")}
          </label>
          <label
            htmlFor="yes_recurring"
            className={
              "radio-recurring-type-label " +
              (is_recurring && "radio-recurring-type-selected")
            }
          >
            <input
              type="radio"
              name="is_recurring"
              id="yes_recurring"
              value={"yes"}
              checked={is_recurring}
              onChange={handleCheckoxChange}
              className="radio-recurring-type-input"
            />
            {t("yes")}
          </label>
        </div>
      </div>
      {is_recurring && (
        <div
          className={
            "radio-recurring-type-content" +
            (!appSettings.isDarkMode
              ? " radio-recurring-type-content-light"
              : "")
          }
        >
          <label
            className={
              "radio-recurring-type-label" +
              (recurring_type === Recurring_type.Daily
                ? " radio-recurring-type-selected"
                : "")
            }
          >
            <input
              type="radio"
              className="radio-recurring-type-input"
              value={Recurring_type.Daily}
              checked={recurring_type === Recurring_type.Daily}
              onChange={(e) =>
                handleRadioChange(e.target.value as Recurring_type)
              }
            />
            {t("daily")}
          </label>
          <label
            className={
              "radio-recurring-type-label" +
              (recurring_type === Recurring_type.Weekly
                ? " radio-recurring-type-selected"
                : "")
            }
          >
            <input
              type="radio"
              className="radio-recurring-type-input"
              value={Recurring_type.Weekly}
              checked={recurring_type === Recurring_type.Weekly}
              onChange={(e) =>
                handleRadioChange(e.target.value as Recurring_type)
              }
            />
            {t("weekly")}
          </label>
          <label
            className={
              "radio-recurring-type-label" +
              (recurring_type === Recurring_type.Monthly
                ? " radio-recurring-type-selected"
                : "")
            }
          >
            <input
              type="radio"
              className="radio-recurring-type-input"
              value={Recurring_type.Monthly}
              checked={recurring_type === Recurring_type.Monthly}
              onChange={(e) =>
                handleRadioChange(e.target.value as Recurring_type)
              }
            />
            {t("monthly")}
          </label>
        </div>
      )}
    </>
  );
};
