import React, { useEffect } from "react";
import "../../assets/styles/Components/Form/DetailsTransactionForm.scss";
import { capitalize } from "lodash";
import { format } from "date-fns";
import { Transaction } from "../../redux/transactionsSlice";
import { useTranslation } from "react-i18next";

export const DetailsTransactionForm: React.FunctionComponent<{
  transaction: Transaction;
  setIsEditMode: (isEditMode: boolean) => void;
}> = (props) => {
  const { transaction, setIsEditMode } = props;
  const { t } = useTranslation();

  const formatDate = (date: Date) => {
    if (date === null || date === undefined) {
      return "";
    }
    return format(date, "dd/MM/yyyy HH:mm:ss");
  };

  const handleChangeMode = () => {
    setIsEditMode(true);
  };

  useEffect(() => {}, [transaction]);

  return (
    <>
      <form className="details-transaction-form">
        <div className="details-transaction-div">
          <label htmlFor="createdAtLabel" className="details-transaction-label">
            {t("date")}
          </label>
          <p className="details-transaction-p">
            {formatDate(transaction.created_at!)}
          </p>
        </div>
        <div className="details-transaction-div">
          <label htmlFor="t_type" className="details-transaction-label">
            {t("type")}
          </label>
          <p className="details-transaction-p">
            {capitalize(transaction.t_type === "income" ? "Entrata" : "Spesa")}
          </p>
        </div>
        <div className="details-transaction-div">
          <label htmlFor="category" className="details-transaction-label">
            {t("category")}
          </label>
          <p className="details-transaction-p">
            {capitalize(transaction.category)}
          </p>
        </div>
        <div className="details-transaction-div">
          <label htmlFor="description" className="details-transaction-label">
            {t("description")}
          </label>
          <p className="details-transaction-p">
            {capitalize(transaction.description)}
          </p>
        </div>
        <div className="details-transaction-div">
          <label htmlFor="amount" className="details-transaction-label">
            {t("amount")}
          </label>
          <p className="details-transaction-p">
            {transaction.amount.toFixed(2)} €
          </p>
        </div>
        <div className="details-transaction-div div-recurring">
          <label htmlFor="recurring" className="details-transaction-label">
            {t("recurrence")}{" "}
            <p className="details-transaction-p">
              {transaction.is_recurring === false
                ? t("no")
                : t(transaction.recurring_type!)}
            </p>
          </label>
        </div>
      </form>
      <div className="details-transaction-btn-submit">
        <button
          type="button"
          className="details-transaction-submit-button"
          onClick={handleChangeMode}
        >
          {t("edit")}
        </button>
      </div>
    </>
  );
};
