import React, { useEffect, useState } from "react";
import "../assets/styles/Components/Headers.scss";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { capitalize } from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";
import PhoneIcon from "@mui/icons-material/Phone";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useAuth } from "../stores/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Search } from "./SearchInput";
import { useAppSelector } from "../hooks/useRedux";
import { format } from "date-fns";
import { MuIcon } from "./MuIcon";
import { useTranslation } from "react-i18next";
import { NotificationIcon } from "./NotificationIcon";
import { AnimatePresence } from "framer-motion";
import { HeaderMenu } from "./HeaderMenu";

type SearchResult = {
  id: number;
  type: "transaction" | "category";
  name: string;
  icon: string;
  color: string;
  amount?: number;
  date?: string;
};

interface HeaderDashboardPageProps {
  handleLogout: () => Promise<void>;
}

export const HeaderDashboardPage: React.FunctionComponent<
  HeaderDashboardPageProps
> = ({ handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [firstCharName, setFirstCharName] = useState<string>();
  const [role, setRole] = useState<string>("user");
  const { isLoggedIn } = useAuth();
  const { getItem } = useLocalStorage();
  const transactions = useAppSelector((state) => state.transactions);
  const categories = useAppSelector((state) => state.categories);
  const appSettings = useAppSelector((state) => state.appsettings);
  const navigate = useNavigate();
  const [filteredResults, setFilteredResults] = useState<SearchResult[]>([]);
  const { t } = useTranslation();

  const handleShowMenu = (): void => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const handleShowSearchBar = (): void => {
    setIsSearchBarOpen((isOpen) => !isOpen);
  };

  const navigateTo = (page: string) => {
    setIsMenuOpen(false);
    if (page === "admin/home") {
      navigate("/" + page);
    } else {
      navigate("/dashboard/" + page);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (query.trim() === "" || query.length <= 1) {
      const combinedResults: SearchResult[] = [];
      setFilteredResults(combinedResults);
    } else {
      const filteredCategories = categories.data
        .filter(
          (item) =>
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            item.icon.toLowerCase().includes(query.toLowerCase()) ||
            item.color.toLowerCase().includes(query.toLowerCase()) ||
            item.c_type.toLowerCase().includes(query.toLowerCase())
        )
        .map(
          (category) =>
            ({
              id: category.id,
              type: "category",
              name: category.name,
              icon: category.icon,
              color: category.color,
            } as SearchResult)
        );

      const filteredTransactions = transactions.data
        .filter(
          (item) =>
            item.description.toLowerCase().includes(query.toLowerCase()) ||
            item.category.toLowerCase().includes(query.toLowerCase()) ||
            item.amount.toString().includes(query.toLowerCase()) ||
            item.t_type.toLowerCase().includes(query.toLowerCase()) ||
            item.created_at?.toLocaleString().includes(query)
        )
        .map((transaction) => {
          const category = _.find(
            categories.data,
            (category) => category.name === transaction.category
          );
          return {
            id: transaction.id,
            type: "transaction",
            name: transaction.description,
            icon: category?.icon ?? "CurrencyExchange",
            color: category?.color ?? "#fff",
            amount: transaction.amount,
            date: format(transaction.created_at!, "dd/MM/yy"),
          } as SearchResult;
        });

      const combinedResults = [...filteredCategories, ...filteredTransactions];
      setFilteredResults(combinedResults);
    }
  };

  const showDetailsPage = (type: string, id: number) => {
    handleSearch("");
    setIsSearchBarOpen(false);
    navigate("/dashboard/details" + capitalize(type) + "/" + id);
  };

  useEffect(() => {
    let getFirstCharName = getItem("firstCharName");
    if (isLoggedIn && getFirstCharName) {
      setFirstCharName(getFirstCharName);
      setRole(getItem("role"));
    } else {
      handleLogout();
    }
  }, [isLoggedIn]);

  return (
    <header className={"header" + (!appSettings.isDarkMode ? " light" : "")}>
      <div className="inner-header">
        <Link to={"/"} className="link linklogo">
          <div className="logo"></div>
        </Link>
        <div className="actions-header">
          <div onClick={handleShowSearchBar}>
            <SearchIcon
              sx={{ fontSize: 36, cursor: "pointer", marginRight: 0.5 }}
            />
          </div>
          <div onClick={() => navigateTo("notifications")}>
            <NotificationIcon />
          </div>
          <div className="menu">
            <div onClick={handleShowMenu}>
              <MenuIcon sx={{ fontSize: 36, cursor: "pointer" }} />
            </div>
            <AnimatePresence>
              {isMenuOpen && (
                <HeaderMenu
                  setIsOpen={setIsMenuOpen}
                  className={
                    "header-ul" + (!appSettings.isDarkMode ? " light" : "")
                  }
                >
                  <li
                    onClick={() => navigateTo("profile")}
                    className="header-li"
                  >
                    <div
                      className={
                        "user-icon" +
                        (!appSettings.isDarkMode ? " user-icon-light" : "")
                      }
                    >
                      {capitalize(firstCharName)}
                    </div>
                    {t("profile")}
                  </li>
                  <li
                    onClick={() => navigateTo("categories")}
                    className="header-li"
                  >
                    <CategoryIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("categories")}
                  </li>
                  <li
                    onClick={() => navigateTo("charts")}
                    className="header-li"
                  >
                    <DataUsageIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("charts")}
                  </li>
                  <li
                    onClick={() => navigateTo("contact")}
                    className="header-li"
                  >
                    <PhoneIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("contacts")}
                  </li>
                  <li
                    onClick={() => navigateTo("settings")}
                    className="header-li"
                  >
                    <SettingsIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("settings")}
                  </li>
                  {role === "admin" && (
                    <li
                      onClick={() => navigateTo("admin/home")}
                      className="header-li"
                    >
                      <AdminPanelSettingsIcon
                        sx={{ fontSize: 26, marginRight: 1 }}
                      />
                      Admin
                    </li>
                  )}
                  <li className="header-li">
                    <div
                      className={
                        "divider" +
                        (!appSettings.isDarkMode ? " divider-light" : "")
                      }
                    ></div>
                  </li>
                  <li onClick={handleLogout} className="header-li">
                    <LogoutIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("logout")}
                  </li>
                </HeaderMenu>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      {isSearchBarOpen && (
        <div className="search-bar">
          <Search
            searchQuery={searchQuery}
            onSearch={handleSearch}
            autofocus={true}
            setIsSearchBarOpen={setIsSearchBarOpen}
          />
          <div
            className={
              "search-results" + (!appSettings.isDarkMode ? " light" : "")
            }
          >
            {_.map(filteredResults, (result, i) => (
              <div
                key={i}
                className={"search-result " + (i % 2 === 0 ? "zebra" : "")}
              >
                <li
                  key={result.name + result.id}
                  className="header-li link"
                  onClick={() =>
                    showDetailsPage(result.type.toString(), result.id)
                  }
                >
                  <p className="link name">{capitalize(result.name)}</p>
                  {result.amount ? (
                    <p className="link small amount">
                      {result.amount.toFixed(2)} €
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="link small">{result.date}</p>
                  <p className="link type">
                    <MuIcon name={result.icon} sx={{ color: result.color }} />
                  </p>
                </li>
              </div>
            ))}
          </div>
        </div>
      )}
    </header>
  );
};
