import React, { useEffect, useState } from "react";
import { subDays, format, isEqual } from "date-fns";
import { Dayjs } from "dayjs";
import { Transaction } from "../redux/transactionsSlice";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const RadioButtonTransactionDate: React.FunctionComponent<{
  formData: Transaction;
  setFormData: React.Dispatch<React.SetStateAction<Transaction>>;
  today: Date;
  userDatePickerDayjs: Dayjs | null;
  setIsDatePickerModalOpen: (isDatePickerModalOpen: boolean) => void;
}> = (props) => {
  const {
    formData,
    setFormData,
    today,
    setIsDatePickerModalOpen,
    userDatePickerDayjs,
  } = props;
  const [isDatePicker, setIsDatePicker] = useState<boolean>(false);
  const [isYesterday, setIsYesterday] = useState<boolean>(false);
  const [isToday, setIsToday] = useState<boolean>(true);
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  const yesterday = subDays(today, 1);
  const formattedToday = format(today, "dd/MM");
  const formattedYesterday = format(yesterday, "dd/MM");

  const handleDateClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDatePickerModalOpen(true);
    setIsDatePicker(true);
    setIsToday(false);
    setIsYesterday(false);
  };

  const handleYesterdayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setFormData({ ...formData, created_at: yesterday });
    setIsYesterday(true);
    setIsToday(false);
    setIsDatePicker(false);
  };

  const handleTodayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setFormData({ ...formData, created_at: today });
    setIsToday(true);
    setIsDatePicker(false);
    setIsYesterday(false);
  };

  // if (isEqual(userDatePickerDayjs!.toDate(), today)) {
  //   console.log("Le due date sono uguali.");
  // } else {
  //   console.log("Le due date non sono uguali.");
  // }

  useEffect(() => {
    if (!isEqual(userDatePickerDayjs!.toDate(), today)) {
      setIsDatePicker(true);
      setIsToday(false);
      setIsYesterday(false);
    }
  }, [userDatePickerDayjs]);

  return (
    <>
      <label className="new-transaction-label">{t("date")}</label>
      <div
        className={
          "date-section" +
          (!appSettings.isDarkMode ? " date-section-light" : "")
        }
      >
        <div
          className={
            "new-transaction-div btn-date " + (isDatePicker && "selected")
          }
          onClick={handleDateClick}
        >
          {t("picker")}
          <br />
          <span className="new-transaction-span">
            {format(userDatePickerDayjs!.toString(), "dd/MM")}
          </span>
        </div>
        <div
          className={
            "new-transaction-div btn-date " + (isYesterday && "selected")
          }
          onClick={handleYesterdayClick}
        >
          {t("yesterday")}
          <br />
          <span className="new-transaction-span">{formattedYesterday}</span>
        </div>
        <div
          className={"new-transaction-div btn-date " + (isToday && "selected")}
          onClick={handleTodayClick}
        >
          {t("today")}
          <br />
          <span className="new-transaction-span">{formattedToday}</span>
        </div>
      </div>
    </>
  );
};
