import React, { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/useRedux";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const NotificationIcon: React.FC = () => {
  const notifications = useAppSelector(
    (state) => state.appsettings.notifications
  );
  const [notificationsUnread, setNotificationsUnread] = useState<number>(0);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 5,
      top: 6,
      fontWeight: 700,
      fontSize: 14,
      // color: `${appSettings.isDarkMode ? "#000" : "#fff"}`,
      // background: `${appSettings.isDarkMode ? "#eaee05" : "#1602ad"}`,
      // border: "1px solid #acacac",
      // padding: "8px",
      // borderRadius: 50,
    },
  }));

  useEffect(() => {
    if (notifications.length > 0) {
      const unreadNotificationsCounter = notifications.filter(
        (notification) => notification.read === false
      ).length;
      setNotificationsUnread(unreadNotificationsCounter);
    } else {
      setNotificationsUnread(0);
    }
  }, [notifications]);

  return (
    <StyledBadge badgeContent={notificationsUnread} color="primary">
      <NotificationsIcon sx={{ fontSize: 34, cursor: "pointer" }} />
    </StyledBadge>
  );
};
