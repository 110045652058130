import React from "react";
import { TransactionDemoMode } from "./TransactionDemoMode";

export const BlurLayerContainer: React.FunctionComponent = () => {
  return (
    <div className="backdrop-panel-blur">
      <TransactionDemoMode />
    </div>
  );
};
