import React from "react";
import "../assets/styles/Pages/NewCategory.scss";
import { NewCategoryForm } from "../components/form/NewCategoryForm";
import { BackButton } from "../components/BackButton";

export const NewCategory: React.FunctionComponent = () => {
  return (
    <div className="partial-dashboard-category-page">
      <div className="dash-container">
        <div className="dash-content">
          <BackButton />
          <NewCategoryForm />
        </div>
      </div>
    </div>
  );
};
