import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  createNewCategoryAsync,
  deleteCategoryAsync,
  editCategoryAsync,
  getCategoriesAsync,
} from "../services/Api";

export interface Category {
  readonly id: number;
  readonly name: string;
  readonly icon: string;
  readonly color: string;
  readonly c_type: string;
  readonly user_id: number;
}

export const defaultCategory: Category = {
  id: 0,
  name: "extra",
  icon: "QuestionMark",
  color: "#737373",
  c_type: "expense",
  user_id: 0,
};

interface CategoriesState {
  data: Category[];
  loading: boolean;
  error: string | null;
}

const initialState: CategoriesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await getCategoriesAsync();
    return response.data.data as Category[];
  }
);

export const addCategories = createAsyncThunk(
  "categories/addCategories",
  async (newCategory: Category) => {
    const response = await createNewCategoryAsync(newCategory);
    return response.data.data as Category;
  }
);

export const editCategories = createAsyncThunk(
  "categories/editCategories",
  async (category: Category) => {
    const response = await editCategoryAsync(category);
    return response.data.data as Category;
  }
);

export const deleteCategories = createAsyncThunk(
  "categories/deleteCategories",
  async (idCategory: number) => {
    const response = await deleteCategoryAsync(idCategory);
    return response.data.data as Category;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    addCategory: (state, action: PayloadAction<Category>) => {
      state.data.push(action.payload);
    },
    removeCategory: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter(
        (category) => category.id !== action.payload
      );
    },
    updateCategory: (state, action: PayloadAction<Category>) => {
      const index = state.data.findIndex(
        (category) => category.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // GET
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // POST
      .addCase(addCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCategories.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.loading = false;
        state.error = "";
      })
      .addCase(addCategories.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // PATCH
      .addCase(editCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(editCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        const index = state.data.findIndex(
          (category) => category.id === action.payload.id
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(editCategories.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // DELETE
      .addCase(deleteCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.data = state.data.filter(
          (category) => category.id !== action.payload.id
        );
      })
      .addCase(deleteCategories.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      });
  },
});

export const { addCategory, removeCategory, updateCategory } =
  categoriesSlice.actions;

export const selectCategories = (state: RootState) => state.categories.data;

export const CategoriesReducer = categoriesSlice.reducer;
