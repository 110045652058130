import React from "react";
import "../assets/styles/Components/RadioButtonTransactionType.scss";
import { Transaction_type } from "../hooks/useTransactionForm";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const RadioButtonTransactionType: React.FunctionComponent<{
  t_type: string;
  handleInputChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  showAll?: boolean;
}> = (props) => {
  const { t_type, handleInputChange, showAll } = props;
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  return (
    <div
      className={
        "new-transaction-radio-expense-type" +
        (!appSettings.isDarkMode
          ? " new-transaction-radio-expense-type-light"
          : "")
      }
    >
      <div className="radio-expense-type-inner">
        {showAll && (
          <label
            htmlFor="all"
            className={`radio-expense-type-label ${
              t_type === "all" && "radio-expense-type-selected"
            }`}
          >
            <input
              type="radio"
              name="type"
              id="all"
              value={"all"}
              checked={t_type === "all"}
              onChange={handleInputChange}
              className="radio-expense-type-input"
            />
            {t("all")}
          </label>
        )}
        <label
          htmlFor="expense"
          className={`radio-expense-type-label ${
            t_type === Transaction_type.Expense && "radio-expense-type-selected"
          }`}
        >
          <input
            type="radio"
            name="type"
            id="expense"
            value={Transaction_type.Expense}
            checked={t_type === Transaction_type.Expense}
            onChange={handleInputChange}
            className="radio-expense-type-input"
          />
          {t("expense")}
        </label>
        <label
          htmlFor="income"
          className={`radio-expense-type-label ${
            t_type === Transaction_type.Income && "radio-expense-type-selected"
          }`}
        >
          <input
            type="radio"
            name="type"
            id="income"
            value={Transaction_type.Income}
            checked={t_type === Transaction_type.Income}
            onChange={handleInputChange}
            className="radio-expense-type-input"
          />
          {t("income")}
        </label>
      </div>
    </div>
  );
};
