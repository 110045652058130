import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/transferLoading.json";

export const TransferLoader: React.FunctionComponent = () => {
  return (
    <div className="backdrop-panel-blur">
      <div className="loader-img">
        <Lottie animationData={animationData} />
      </div>
    </div>
  );
};
