import React from "react";
import "../../assets/styles/Components/Modal/ShowIconModal.scss";
import _ from "lodash";
import { MuIcon, iconNames } from "../MuIcon";
import { GenericModal } from "./GenericModal";

export const ShowIconModal: React.FunctionComponent<{
  setIsOpen: (isOpen: boolean) => void;
  color: string;
  setSelectedIcon: (iconName: string) => void;
}> = (props) => {
  const { setIsOpen, color, setSelectedIcon } = props;

  const handleClick = (iconName: string) => {
    setSelectedIcon(iconName);
    setIsOpen(false);
  };

  return (
    <div className="backdrop-panel-blur">
      <GenericModal setIsOpen={setIsOpen} className={"icon-modal"}>
        <div className="icon-modal-container">
          <div className="icon-modal-grid">
            {_.map(iconNames, (icon, i) => (
              <div
                key={i}
                className="icon-modal-grid-cell"
                onClick={() => {
                  handleClick(icon);
                }}
              >
                {<MuIcon name={icon} sx={{ color: color, fontSize: 40 }} />}
              </div>
            ))}
          </div>
        </div>
      </GenericModal>
    </div>
  );
};
