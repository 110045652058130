import React, { useEffect, useState } from "react";
import "../../assets/styles/Components/Form/CategoryForm.scss";
import { capitalize } from "lodash";
import { GenericLoader } from "../loader/GenericLoader";
import { HexColorPicker } from "react-colorful";
import { Category, editCategories } from "../../redux/categoriesSlice";
import { useCategoryForm } from "../../hooks/useCategoryForm";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { ShowIconModal } from "../modal/ShowIconModal";
import { MuIcon } from "../MuIcon";
import { useTranslation } from "react-i18next";

export const EditCategoryForm: React.FunctionComponent<{
  category: Category;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = (props) => {
  const { category, setIsModalOpen } = props;
  const dispatch = useAppDispatch();
  const [isSubmittingFormData, setIsSubmittingFormData] =
    useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isIconModalOpen, setIsIconModalOpen] = useState<boolean>(false);
  const appSettings = useAppSelector((state) => state.appsettings);
  const {
    formData,
    setFormData,
    // selectedIconComponent,
    isColorPickerVisible,
    handleColorPicker,
    handleSelectColor,
    handleSelectIcon,
  } = useCategoryForm(category);
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditMode) {
      setIsSubmittingFormData(true);
      try {
        const response = await dispatch(editCategories(formData));

        if (response.meta.requestStatus === "fulfilled") {
          setIsModalOpen?.(false);
          window.location.reload();
        } else {
          alert("Accesso non autorizzato");
        }
      } catch (error) {
        console.error("Errore durante l'accesso:", error);
        alert("Errore durante l'accesso:");
      } finally {
        setIsSubmittingFormData(false);
      }
    } else {
      setIsEditMode(true);
    }
  };

  useEffect(() => {
    setIsEditMode(false);
    setIsIconModalOpen(false);
    setFormData(category);
  }, [category]);

  return (
    <>
      {isEditMode ? (
        <h2 className="category-edit-h2">
          {t("editing-of")} '
          <span
            className={
              "category-edit-h2-span" +
              (!appSettings.isDarkMode ? " category-edit-h2-span-light" : "")
            }
          >
            {capitalize(category.name)}
          </span>
          '
        </h2>
      ) : (
        <h2 className="category-h2">{capitalize(category.name)}</h2>
      )}
      <form
        className={
          "category-form" +
          (!appSettings.isDarkMode ? " category-form-light" : "")
        }
        onSubmit={handleSubmit}
      >
        <div className="category-form-div">
          <label htmlFor="c_type" className="category-form-label">
            {t("type")}
          </label>
          <p className="category-form-p">
            {capitalize(formData.c_type === "income" ? "Entrata" : "Spesa")}
          </p>
        </div>
        <div className="category-form-div">
          <label htmlFor="name" className="category-form-label">
            {t("name")}
          </label>
          <p className="category-form-p">{capitalize(formData.name)}</p>
        </div>
        <div className="category-form-div div-group-color-icon">
          <div className="category-form-div div-group-color-cat">
            <div className="category-form-div btn-group-color-cat">
              <label
                htmlFor="color"
                className="category-form-label btn-group-color-cat-label"
              >
                {t("color")}
              </label>
              <div
                id="color"
                className="category-form-div display-color-selected"
                style={{ backgroundColor: formData.color }}
                onClick={() => isEditMode && handleColorPicker(true)}
              ></div>
            </div>
          </div>
          <div className="category-form-div div-group-icon-cat-edit">
            <div className="category-form-div content">
              <label
                htmlFor="icon-selected"
                className="category-form-label div-group-icon-cat-label"
              >
                {t("icon")}
              </label>
              <div
                id="icon"
                className="category-form-div display-icon-selected"
                onClick={() => isEditMode && setIsIconModalOpen(true)}
              >
                <MuIcon
                  name={formData.icon}
                  sx={{ color: formData.color, fontSize: 40 }}
                />
              </div>
            </div>
          </div>
        </div>
        {isColorPickerVisible && (
          <div
            className="backdrop-panel-blur"
            onClick={() => handleColorPicker(false)}
          >
            <HexColorPicker
              className="color-picker"
              color={formData.color}
              onChange={(color) => handleSelectColor(color)}
            />
          </div>
        )}
        {isIconModalOpen && (
          <ShowIconModal
            setIsOpen={setIsIconModalOpen}
            color={formData.color}
            setSelectedIcon={handleSelectIcon}
          />
        )}
        <div className="btn-submit">
          <button type="submit" className="category-form-submit-button">
            {isEditMode ? t("save") : t("edit")}
          </button>
        </div>
        {isSubmittingFormData && <GenericLoader />}
      </form>
    </>
  );
};
