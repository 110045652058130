// actionsAndReducers.ts

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../pages/ProfilePage";
import {
  getAllCategoriesAsync,
  getAllNotificationsAsync,
  getAllTransactionsAsync,
  getUsersAsync,
} from "../services/Api";
import { Transaction } from "./transactionsSlice";
import { Category } from "./categoriesSlice";
import { AppNotification } from "./appsettingsSlice";

interface AdminDataState {
  users: User[];
  notifications: AppNotification[];
  transactionsCounter: number;
  categoriesCounter: number;
}

const initialDataState: AdminDataState = {
  users: [],
  notifications: [],
  transactionsCounter: 0,
  categoriesCounter: 0,
};

interface AdminState {
  data: AdminDataState;
  loading: boolean;
  error: string | null;
}

// Inizializza lo stato
const initialState: AdminState = {
  data: initialDataState,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk("admin/fetchUsers", async () => {
  const response = await getUsersAsync();
  return response.data.data as User[];
});

export const fetchNotifications = createAsyncThunk(
  "admin/fetchNotifications",
  async () => {
    const response = await getAllNotificationsAsync();
    return response.data.data as AppNotification[];
  }
);

export const fetchTransactions = createAsyncThunk(
  "admin/fetchTransactions",
  async () => {
    const response = await getAllTransactionsAsync();
    const transactions = response.data.data as Transaction[];
    return transactions.length;
  }
);

export const fetchCategories = createAsyncThunk(
  "admin/fetchCategories",
  async () => {
    const response = await getAllCategoriesAsync();
    const categories = response.data.data as Category[];
    return categories.length;
  }
);

// Crea uno slice con Redux Toolkit
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.data.notifications = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        // state.data.notifications = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // GET users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.data.users = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // // PATCH
      // .addCase(editTransactions.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(editTransactions.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.error = "";
      //   const index = state.data.findIndex(
      //     (transaction) => transaction.id === action.payload.id
      //   );
      //   if (index !== -1) {
      //     state.data[index] = action.payload;
      //   }
      // })
      // .addCase(editTransactions.rejected, (state, action) => {
      //   // state.data = [];
      //   state.loading = false;
      //   state.error = action.error.message ?? "Messaggio di fallback";
      // })
      // // DELETE
      // .addCase(deleteTransactions.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(deleteTransactions.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.error = "";
      //   state.data = state.data.filter(
      //     (transaction) => transaction.id !== action.payload.id
      //   );
      // })
      // .addCase(deleteTransactions.rejected, (state, action) => {
      //   // state.data = [];
      //   state.loading = false;
      //   state.error = action.error.message ?? "Messaggio di fallback";
      // })
      // counter transactions
      // GET
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.data.transactionsCounter = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.data.transactionsCounter = 0;
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // counter categories
      // GET
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.data.categoriesCounter = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.data.transactionsCounter = 0;
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      });
  },
});

// Esporta le action creators
// export const { openSnackbar, closeSnackbar } = adminSlice.actions;

// Esporta il reducer
export const AdminReducer = adminSlice.reducer;
