import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { AppNewNotification } from "../redux/appsettingsSlice";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../hooks/useRedux";

const getStyles = (id: number, usersIds: readonly number[], theme: Theme) => {
  return {
    fontWeight:
      usersIds.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

interface MultipleSelectChipProps {
  ids: number[];
  setNotification: React.Dispatch<React.SetStateAction<AppNewNotification>>;
  notification: AppNewNotification;
}

export const MultipleSelectChip: React.FC<MultipleSelectChipProps> = ({
  ids,
  setNotification,
  notification,
}) => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const theme = useTheme();
  const { t } = useTranslation();
  const [usersIds, setUsersIds] = React.useState<number[]>([]);

  const bgColor = appSettings.isDarkMode ? "0a223e" : "#ededed";
  const textColor = appSettings.isDarkMode ? "#fff" : "#2b2a2a";
  const bgChipsColor = appSettings.isDarkMode ? "eaee05" : "#1602ad";
  const textChipsColor = appSettings.isDarkMode ? "#2b2a2a" : "#fff";
  // const borderColor = appSettings.isDarkMode ? "#2b2a2a" : "#fff";
  const borderFocusedColor = appSettings.isDarkMode ? "eaee05" : "#1602ad";

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: bgColor,
        border: "solid #144378",
        color: textColor,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof usersIds>) => {
    const {
      target: { value },
    } = event;
    setUsersIds(value as number[]);
    setNotification({ ...notification, users_ids: value as number[] });
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel
          id="demo-multiple-chip-label"
          sx={{
            color: textColor,
          }}
        >
          {t("users")}
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={usersIds}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ backgroundColor: bgChipsColor, color: textChipsColor }}
                />
              ))}
            </Box>
          )}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#144378",
            },
            // "&:hover .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "green",
            // },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: borderFocusedColor,
            },
          }}
          MenuProps={MenuProps}
        >
          <MenuItem key={-1} value={-1} style={getStyles(-1, usersIds, theme)}>
            {"Tutti"}
          </MenuItem>
          {ids.map((id) => (
            <MenuItem
              key={id}
              value={id}
              style={getStyles(id, usersIds, theme)}
            >
              {id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
