import React, { useEffect, useState } from "react";
import "../assets/styles/Components/Headers.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../stores/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { capitalize } from "lodash";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { HeaderMenu } from "./HeaderMenu";

interface HeaderHomePageProps {
  setIsLoginModalOpen: (isModalOpen: boolean) => void;
  handleLogout: () => Promise<void>;
}

export const HeaderHomePage: React.FunctionComponent<HeaderHomePageProps> = ({
  setIsLoginModalOpen,
  handleLogout,
}) => {
  const { isLoggedIn } = useAuth();
  const { getItem } = useLocalStorage();
  const navigate = useNavigate();
  const appSettings = useAppSelector((state) => state.appsettings);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [firstCharName, setFirstCharName] = useState<string>();
  const { t } = useTranslation();

  const showLoginModal = (): void => {
    setIsLoginModalOpen(true);
  };

  const handleShowMenu = (): void => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const navigateTo = (page: string) => {
    setIsMenuOpen(false);
    navigate("/dashboard/" + page);
  };

  useEffect(() => {
    let getFirstCharName = getItem("firstCharName");
    if (isLoggedIn && getFirstCharName) {
      setFirstCharName(getFirstCharName);
    }
  }, [isLoggedIn]);

  return (
    <header className={"header" + (!appSettings.isDarkMode ? " light" : "")}>
      <div className="inner-header">
        <Link to={"/"} className="link linklogo">
          <div className="logo"></div>
        </Link>
        <div className="actions-header">
          <div className="menu">
            {!isLoggedIn ? (
              <p className="login" onClick={showLoginModal}>
                {t("login")}
              </p>
            ) : (
              <p onClick={handleShowMenu}>
                <MenuIcon sx={{ fontSize: 36, cursor: "pointer" }} />
              </p>
            )}
            <AnimatePresence>
              {isMenuOpen && (
                <HeaderMenu
                  setIsOpen={setIsMenuOpen}
                  className={
                    "header-ul" + (!appSettings.isDarkMode ? " light" : "")
                  }
                >
                  <li
                    onClick={() => navigateTo("profile")}
                    className="header-li"
                  >
                    <div className="user-icon">{capitalize(firstCharName)}</div>
                    {t("profile")}
                  </li>
                  <li
                    onClick={() => navigateTo("settings")}
                    className="header-li"
                  >
                    <SettingsIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("settings")}
                  </li>
                  <li className="header-li">
                    <div className="divider"></div>
                  </li>
                  <li onClick={handleLogout} className="header-li">
                    <LogoutIcon sx={{ marginRight: 1 }} />
                    {t("logout")}
                  </li>
                </HeaderMenu>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </header>
  );
};
