import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { HeaderAdminPage } from "../components/HeaderAdminPage";
import { BottomBarMenuAdmin } from "../components/BottomBarMenuAdmin";
import { logoutUserAsync } from "../services/Api";
import { useAuth } from "../stores/AuthContext";
import { GenericLoader } from "../components/loader/GenericLoader";

export const AdminContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await logoutUserAsync();

      if (response.status === 200) {
        logout();
        navigate("/");
      }
    } catch (error) {
      console.error("Errore durante il logout:", error);
      alert("Errore durante il logout");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <HeaderAdminPage handleLogout={handleLogout} />
      {/* TODO creare bottom bar e side menu per admin */}
      {/* {window.innerWidth < 500 ? <BottomBarMenu /> : <SideMenu />} */}
      <BottomBarMenuAdmin />
      {isLoading ? <GenericLoader /> : <Outlet />}
    </div>
  );
};
