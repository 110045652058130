import React, { useEffect, useState } from "react";
import "../assets/styles/Pages/ResetPasswordPage.scss";
import { useAppSelector } from "../hooks/useRedux";
import { GenericLoader } from "../components/loader/GenericLoader";
import { ResetPasswordForm } from "../components/form/ResetPasswordForm";
import { HeaderHomePage } from "../components/HeaderHomePage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoutUserAsync } from "../services/Api";
import { useAuth } from "../stores/AuthContext";

export const ResetPasswordPage: React.FunctionComponent = () => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await logoutUserAsync();

      if (response.status === 200) {
        logout();
        navigate("/");
      }
    } catch (error) {
      console.error("Errore durante il logout:", error);
      alert("Errore durante il logout");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get("token");
    if (tokenParam !== null && tokenParam !== "") {
      setToken(tokenParam);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <HeaderHomePage
        setIsLoginModalOpen={setIsLoginModalOpen}
        handleLogout={handleLogout}
      />
      <div
        className={
          "partial-reset-password-page" +
          (!appSettings.isDarkMode ? " light" : "")
        }
      >
        <div className="dash-container">
          <div className="dash-content">
            <div className="reset-password-content">
              {isLoading ? (
                <GenericLoader />
              ) : (
                <>
                  <h2 className="reset-password-h2">{t("reset-password")}</h2>
                  <div className="reset-password-content-form">
                    <ResetPasswordForm
                      setIsLoading={setIsLoading}
                      token={token}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
