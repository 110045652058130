import React, { useEffect, useState } from "react";
import "../assets/styles/Components/TransactionsFilterSection.scss";
import _ from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SortFilterType } from "../hooks/useFilterTransactions";
import { Category } from "../redux/categoriesSlice";
import {
  getTransactionFieldFriendlyName,
  transactionFields,
} from "../utils/MappingTransactionFields";
import { CategorySelect } from "./CategorySelect";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

export const TransactionsFilterSection: React.FunctionComponent<{
  categories: Category[];
  sortFilterData: SortFilterType;
  isSortedOrderDesc: boolean;
  handleSort: (property: string, order: "asc" | "desc") => void;
  handleFilterType: (typeFilter: string) => void;
  handleFilterCategory: (categoryFilter: string) => void;
  handleSortClick: (order: "asc" | "desc") => void;
}> = (props) => {
  const {
    categories,
    sortFilterData,
    isSortedOrderDesc,
    handleSort,
    handleFilterCategory,
    handleSortClick,
  } = props;
  const [sortCounter, setSortCounter] = useState<number>(0);
  const sortValues = ["asc", "desc"];
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const handleSortDirection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setSortCounter((counter) => counter + 1);
    const sortValue = sortValues[sortCounter % 2] as "asc" | "desc";
    handleSortClick(sortValue);
  };

  return (
    <div
      className={
        "filter-content" +
        (!appSettings.isDarkMode ? " filter-content-light" : "")
      }
    >
      <div className="filter">
        <p className="filter-content-p">{t("category")}:</p>
        <div className="filter-content-select">
          <CategorySelect
            categories={_.filter(categories, (category) => {
              if (sortFilterData.filterType === "all") {
                return true;
              } else {
                return category.c_type === sortFilterData.filterType;
              }
            })}
            categoryName={sortFilterData.filterCategory}
            selectCategory={handleFilterCategory}
            showAll={true}
          />
        </div>
      </div>
      <div className="sort">
        <p className="filter-content-p">{t("sort-by")}:</p>
        <div className="sort-content">
          <select
            id="sort"
            name="sort"
            className="sort-content-select"
            value={sortFilterData.sortProperty}
            onChange={(e) => {
              handleSort(e.target.value, sortFilterData.sortOrder);
            }}
          >
            <option value="" disabled>
              {t("attribute")}
            </option>
            {_.map(transactionFields, (field) => (
              <option key={field} value={field}>
                {t(field)}
              </option>
            ))}
          </select>
          <div className="actions-group">
            <div className="action" onClick={handleSortDirection}>
              {isSortedOrderDesc ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
