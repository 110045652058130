import React, { useState } from "react";
import "../../assets/styles/Components/Modal/DetailsTransactionsModal.scss";
import { capitalize } from "lodash";
import { DetailsTransactionForm } from "../form/DetailsTransactionForm";
import { Transaction } from "../../redux/transactionsSlice";
import { TransactionForm } from "../form/TransactionForm";
import { GenericModal } from "./GenericModal";
import { useAppSelector } from "../../hooks/useRedux";

export const DetailsTransactionModal: React.FunctionComponent<{
  transaction: Transaction;
  setIsDetailsModalOpen: (isDetailsModalOpen: boolean) => void;
}> = (props) => {
  const { transaction, setIsDetailsModalOpen } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const appSettings = useAppSelector((state) => state.appsettings);

  return (
    <>
      <GenericModal
        setIsOpen={setIsDetailsModalOpen}
        className={
          "details-transaction-modal" +
          (!appSettings.isDarkMode ? " details-transaction-modal-light" : "")
        }
      >
        <div className="container">
          <div className="content">
            <h3 className="details-transaction-modal-h3">
              <span className="details-transaction-modal-span">
                {isEditMode && "Modifica di "}
              </span>
              {capitalize(transaction.description)}
            </h3>
            {isEditMode === false ? (
              <DetailsTransactionForm
                transaction={transaction}
                setIsEditMode={setIsEditMode}
              />
            ) : (
              <TransactionForm
                transaction={transaction}
                setIsTransactionsModalOpen={setIsDetailsModalOpen}
              />
            )}
          </div>
        </div>
      </GenericModal>
    </>
  );
};
