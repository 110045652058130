import React, { useState } from "react";
import axios from "axios";
import "../../assets/styles/Components/Modal/UploadFileModal.scss";
import DescriptionIcon from "@mui/icons-material/Description";
import { GenericModal } from "./GenericModal";
import { uploadFileXlsxAsync } from "../../services/Api";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { UploadFileLoader } from "../loader/UploadFIleLoader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const UploadFileModal: React.FunctionComponent<{
  setIsOpen: (isOpen: boolean) => void;
}> = (props) => {
  const { setIsOpen } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      var file = event.target.files[0];
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await uploadFileXlsxAsync(formData);

        if (response.status === 200) {
          // setIsOpen(false);
          dispatch(
            openSnackbar({
              message: t("upload-successfully"),
              severity: "success",
            })
          );
          navigate("/dashboard/home");
        }
      } catch (error) {
        console.error("Errore durante l'upload:", error);
        dispatch(
          openSnackbar({
            message: t("upload-stopped"),
            severity: "error",
          })
        );
      } finally {
        setIsUploading(false);
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("upload-error"),
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      <GenericModal setIsOpen={setIsOpen} className={"upload-modal"}>
        <h3 className="upload-h3">{t("upload-file")}</h3>
        <div className="custom-input-upload-content">
          <label htmlFor="input-upload" className="custom-input-upload">
            {t("select-file")}
          </label>
          <div className="upload-p-content">
            {fileName !== "" && (
              <div className="upload-img">
                <DescriptionIcon sx={{ fontSize: 60 }} />
              </div>
            )}
            <p className="upload-p">{fileName}</p>
          </div>
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            id="input-upload"
            className="input-upload"
          />
        </div>
        <button onClick={handleUpload} className="btn-upload">
          {t("upload")}
        </button>
      </GenericModal>
      {isUploading && <UploadFileLoader />}
    </>
  );
};
