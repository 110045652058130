import { useState } from "react";
import { addDays, addWeeks, addMonths } from "date-fns";
import { Transaction } from "../redux/transactionsSlice";

export enum Transaction_type {
  Expense = "expense",
  Income = "income",
}

export enum Recurring_type {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export const useTransactionForm = () => {
  const initTransaction: Transaction = {
    id: 0,
    t_type: Transaction_type.Expense,
    category: "",
    description: "",
    amount: 1,
    created_at: new Date(),
    user_id: 0,
    is_recurring: false,
    recurring_date: undefined,
    recurring_type: undefined,
  };

  const [formData, setFormData] = useState<Transaction>(initTransaction);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const resetForm = () => {
    setFormData(initTransaction);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    if (name === "type") {
      setFormData({ ...formData, t_type: value, category: "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const setAmount = (amount: number) => {
    setFormData({ ...formData, amount: amount });
  };

  const selectCategory = (categoryName: string) => {
    if (categoryName !== "newCategory") {
      setFormData({ ...formData, category: categoryName });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleRadioChange = (r_type: Recurring_type) => {
    setFormData({
      ...formData,
      recurring_type: r_type,
      recurring_date: setRecurringDate(r_type, formData.created_at!),
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const is_recurring = event.target.value === "yes" ? true : false;
    const recurringDate = setRecurringDate(
      Recurring_type.Daily,
      formData.created_at!
    );
    setFormData({
      ...formData,
      is_recurring: is_recurring,
      recurring_type: is_recurring ? Recurring_type.Daily : undefined,
      recurring_date: is_recurring ? recurringDate : undefined,
    });
  };

  const setRecurringDate = (
    recurringType: Recurring_type,
    startDate: Date
  ): Date => {
    switch (recurringType) {
      case Recurring_type.Daily:
        return addDays(startDate, 1);
      case Recurring_type.Weekly:
        return addWeeks(startDate, 1);
      case Recurring_type.Monthly:
        return addMonths(startDate, 1);
      default:
        return addMonths(startDate, 1);
    }
  };

  return {
    formData,
    setFormData,
    resetForm,
    isModalOpen,
    setIsModalOpen,
    handleInputChange,
    handleCheckboxChange,
    handleRadioChange,
    selectCategory,
    setAmount,
  };
};
