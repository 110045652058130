import React, { useState } from "react";
import { useParams } from "react-router-dom";
import _, { capitalize } from "lodash";
import { useAppSelector } from "../hooks/useRedux";
import { TransactionForm } from "../components/form/TransactionForm";
import { DetailsTransactionForm } from "../components/form/DetailsTransactionForm";
import { BackButton } from "../components/BackButton";
import { useTranslation } from "react-i18next";

export const DetailsTransaction: React.FunctionComponent = () => {
  const { id } = useParams<string>();
  const transactions = useAppSelector((state) => state.transactions);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const appSettings = useAppSelector((state) => state.appsettings);
  const { t } = useTranslation();

  const transaction = _.filter(
    transactions.data,
    (transaction) => transaction.id === Number.parseInt(id!)
  )[0];

  return (
    <div
      className={
        "partial-dashboard-details-transaction-page" +
        (!appSettings.isDarkMode
          ? " partial-dashboard-details-transaction-page-light"
          : "")
      }
    >
      <div
        className={
          "dash-container" +
          (!appSettings.isDarkMode ? " dash-container-light" : "")
        }
      >
        <div className="dash-content">
          <BackButton />
          {isEditMode === false ? (
            <>
              <h2 className="new-transaction-h2">
                {capitalize(transaction.description)}
              </h2>
              <DetailsTransactionForm
                transaction={transaction}
                setIsEditMode={setIsEditMode}
              />
            </>
          ) : (
            <>
              <h2 className="new-transaction-edit-h2">
                {t("editing-of")} '
                <span className="new-transaction-edit-h2-span">
                  {capitalize(transaction.description)}
                </span>
                '
              </h2>
              <TransactionForm transaction={transaction} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
