import React, { useEffect, useState } from "react";
import "../assets/styles/Components/DonutDashboard.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  TooltipItem,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  Transaction,
  demoAmountTransactions,
  demoChartTransactions,
} from "../redux/transactionsSlice";
import { Category } from "../redux/categoriesSlice";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DonutDashboard: React.FunctionComponent<{
  transactions: Transaction[];
  categories: Category[];
}> = (props) => {
  const { transactions, categories } = props;
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalExpense, setTotalExpense] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const { t } = useTranslation();

  let transactionsList: Transaction[] =
    transactions.length > 0 ? transactions : demoChartTransactions;

  const categoryMap = transactionsList.reduce((map, transaction) => {
    const { category, amount } = transaction;

    if (map.has(category)) {
      // Se la categoria è già presente nella mappa, aggiungi l'importo esistente
      map.set(category, map.get(category)! + amount);
    } else {
      // Se la categoria non è ancora presente nella mappa, inizializza con l'importo
      map.set(category, amount);
    }

    return map;
  }, new Map<string, number>());

  // Estrai i dati dalla mappa per utilizzarli nel grafico
  const labels: string[] = Array.from(categoryMap.keys());
  const data: number[] = Array.from(categoryMap.values());
  const backgroundColors: string[] = labels.map((categoryName) => {
    // Assumi che ci sia un array di categorie con corrispondenti colori nel tuo progetto
    const categoryObject: Category | undefined = categories.find(
      (category) => category.name === categoryName
    );
    return categoryObject ? categoryObject.color : "#e07707";
  });

  // const percentage = (value: TooltipItem<"doughnut">): string => {
  //   const total: number = data.reduce((acc, value) => acc + value, 0);
  //   const percentage: number = (value.parsed / total) * 100;
  //   return "Percentuale: " + percentage;
  // };

  const amount = (value: TooltipItem<"doughnut">): string => {
    return value.parsed + " €";
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: "#000",
        hoverOffset: 5,
        borderRadius: 30,
        spacing: 10,
      },
    ],
  };

  const options = {
    cutout: 90,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: amount,
        },
      },
    },
  };

  useEffect(() => {
    transactionsList =
      transactions.length > 0 ? transactions : demoAmountTransactions;

    const totals = transactionsList.reduce(
      (accumulator, transaction) => {
        const { t_type, amount } = transaction;

        if (t_type === "income") {
          accumulator.totalIncome += amount;
        } else if (t_type === "expense") {
          accumulator.totalExpense += amount;
        }

        return accumulator;
      },
      { totalIncome: 0, totalExpense: 0 }
    );

    setTotalIncome(totals.totalIncome);
    setTotalExpense(totals.totalExpense);
    setTotalBalance(totals.totalIncome - totals.totalExpense);
  }, []);

  return (
    <>
      <div className="donut-content">
        <div className="donut-chart">
          <Doughnut data={chartData} options={options} />
          <div className="summary">
            <p className="income">+{totalIncome.toFixed(2)} €</p>
            <p className="expense">-{totalExpense.toFixed(2)} €</p>
          </div>
        </div>
      </div>
      <h3 className="balance">
        {t("balance")}:{" "}
        <span className={`${totalBalance >= 0 ? "positive" : "negative"}`}>
          {totalBalance.toFixed(2)}
        </span>{" "}
        €
      </h3>
    </>
  );
};
