import React from "react";

export const CategoriesAdminPage: React.FC = () => {
  return (
    <div className="partial-admin-categories-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="admin-categories-content">
            <h3 className="admin-categories-h3">Categories</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
