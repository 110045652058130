import React, { useState } from "react";
import "../assets/styles/Pages/HomePage.scss";
import Lottie from "lottie-react";
import animationData from "../assets/HeroImg.json";
import { HeaderHomePage } from "../components/HeaderHomePage";
import { useAuth } from "../stores/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { LoginModal } from "../components/modal/LoginModal";
import { SignupModal } from "../components/modal/SignupModal";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { logoutUserAsync } from "../services/Api";
import { GenericLoader } from "../components/loader/GenericLoader";

export const Home: React.FunctionComponent = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState<boolean>(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState<boolean>(false);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showLoginModal = (): void => {
    setIsLoginModalOpen(true);
  };

  const handleLogout = async () => {
    setIsLogoutLoading(true);
    try {
      const response = await logoutUserAsync();

      if (response.status === 200) {
        logout();
        navigate("/");
      }
    } catch (error) {
      console.error("Errore durante il logout:", error);
      alert("Errore durante il logout");
    }
    setIsLogoutLoading(false);
  };

  return (
    <div>
      <HeaderHomePage
        setIsLoginModalOpen={setIsLoginModalOpen}
        handleLogout={handleLogout}
      />
      {isLogoutLoading ? (
        <GenericLoader />
      ) : (
        <section className="hero">
          <div className="container">
            <div className="content-text">
              <h2 className="hero-title">{t("hero-title")}</h2>
              <p className="hero-subtitle">{t("hero-subtitle")}</p>
              {!isLoggedIn ? (
                <div onClick={showLoginModal} className="btn-action">
                  {t("login")}
                </div>
              ) : (
                <Link to={"/dashboard/home"} className="link btn-action">
                  {t("dashboard")}
                </Link>
              )}
            </div>
            <div className="content-img">
              <Lottie animationData={animationData} />
            </div>
          </div>
        </section>
      )}
      <AnimatePresence>
        {isLoginModalOpen && (
          <LoginModal
            setIsLoginModalOpen={setIsLoginModalOpen}
            setIsSignupModalOpen={setIsSignupModalOpen}
          />
        )}
        {isSignupModalOpen && (
          <SignupModal
            setIsSignupModalOpen={setIsSignupModalOpen}
            setIsLoginModalOpen={setIsLoginModalOpen}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
function logout() {
  throw new Error("Function not implemented.");
}
