import React from "react";
import { useNavigate } from "react-router-dom";
import { loginUserAsync } from "../../services/Api";
import { useAuth } from "../../stores/AuthContext";
import { PasswordInput } from "./PasswordInput";
import { GenericLoader } from "../loader/GenericLoader";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { requestResetPasswordAsync } from "../../services/Api";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";

export interface LoginFormFields {
  readonly email: string;
  readonly password: string;
}

export const LoginForm: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setFocus,
  } = useForm<LoginFormFields>();
  const { login } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const sendRequestResetPassword = async () => {
    const email = getValues("email");
    if (email !== "") {
      try {
        const response = await requestResetPasswordAsync(email);

        if (response.status === 200) {
          dispatch(
            openSnackbar({
              message: t("mail-sended"),
              severity: "success",
            })
          );
        }
      } catch (error) {
        dispatch(
          openSnackbar({
            message: t("mail-not-sended"),
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("mail-invalid"),
          severity: "error",
        })
      );
    }
  };

  const onSubmit: SubmitHandler<LoginFormFields> = async (data) => {
    try {
      const response = await loginUserAsync(data);
      if (response.status === 200) {
        const token = response.headers["authorization"];
        const userId = response.data.user.id;
        const role = response.data.user.role;
        const firstName = response.data.user.first_name;
        login(token, userId, role, firstName);
        navigate("/dashboard/home");
      } else {
        alert("Accesso non autorizzato");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          dispatch(
            openSnackbar({
              message: t("attempts-exceeded"),
              severity: "error",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: t("mail-password-incorrect"),
              severity: "error",
            })
          );
        }
      } else {
        console.error("Errore durante l'accesso:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <div className="login-div">
        <label htmlFor="email" className="login-label">
          Email:
        </label>
        <input
          type="text"
          id="email"
          className={"login-input" + (errors.email ? " form-input-error" : "")}
          {...register("email", { required: true, pattern: emailPattern })}
        />
        {/* {errors.email && <span className="form-span-error">* Is required</span>} */}
      </div>
      <div className="login-div">
        <PasswordInput
          id={"password"}
          className={errors.password ? " form-input-error" : ""}
          register={register("password", {
            required: true,
            min: 8,
            pattern: passwordPattern,
          })}
          setFocus={setFocus}
        />
        {/* {errors.password && (
          <span className="form-span-error">* Is required</span>
        )} */}
      </div>
      <button className="login-button">Accedi</button>
      <p className="link login-p reset-p">
        Hai dimenticato la password?
        <br />
        <span onClick={sendRequestResetPassword} className="login-span">
          Reset password
        </span>
      </p>
      {isSubmitting && <GenericLoader />}
    </form>
  );
};
