import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationCommonEN from "../locales/en/translation_common.json";
import translationMenuEN from "../locales/en/translation_menu.json";
import translationTypeEN from "../locales/en/translation_type.json";
import translationMsgEN from "../locales/en/translation_message.json";

import translationCommonIT from "../locales/it/translation_common.json";
import translationMenuIT from "../locales/it/translation_menu.json";
import translationTypeIT from "../locales/it/translation_type.json";
import translationMsgIT from "../locales/it/translation_message.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...translationCommonEN,
        ...translationMenuEN,
        ...translationTypeEN,
        ...translationMsgEN,
      },
    },
    it: {
      translation: {
        ...translationCommonIT,
        ...translationMenuIT,
        ...translationTypeIT,
        ...translationMsgIT,
      },
    },
  },
  lng: "it", // Lingua predefinita
  fallbackLng: "it", // Lingua di fallback
  interpolation: {
    escapeValue: false, // non è necessario per React
  },
});

export default i18n;
