import React from "react";
import "../../assets/styles/Components/Modal/CategoryModal.scss";
import { EditCategoryForm } from "../form/EditCategoryForm";
import { Category } from "../../redux/categoriesSlice";
import { GenericModal } from "./GenericModal";
import { useAppSelector } from "../../hooks/useRedux";

export const EditCategoryModal: React.FunctionComponent<{
  category: Category;
  setIsOpen: (isOpen: Boolean) => void;
}> = (props) => {
  const { category, setIsOpen } = props;
  const appSettings = useAppSelector((state) => state.appsettings);

  return (
    <>
      <GenericModal
        setIsOpen={setIsOpen}
        className={
          "category-modal" +
          (!appSettings.isDarkMode ? " category-modal-light" : "")
        }
      >
        <div className="container">
          <div className="content">
            <EditCategoryForm category={category} setIsModalOpen={setIsOpen} />
          </div>
        </div>
      </GenericModal>
    </>
  );
};
