import React, { useState } from "react";
import "../assets/styles/Pages/ChartsPage.scss";
import { GenericLoader } from "../components/loader/GenericLoader";
import { BackButton } from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useRedux";
import { BarChart } from "../components/charts/BarChart";
import { DonutChart } from "../components/charts/DonutChart";
import { LineChart } from "../components/charts/LineChart";
import { ChartCheckboxGroup } from "../components/ChartCheckboxGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";

export const ChartsPage: React.FunctionComponent = () => {
  const transactions = useAppSelector((state) => state.transactions);
  const appSettings = useAppSelector((state) => state.appsettings);
  const [isMenuChartsVisibilityOpen, setIsMenuChartsVisibilityOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const backFunction = () => {
    navigate("/dashboard/home");
  };

  const toggleMenuChartsVisibility = () => {
    setIsMenuChartsVisibilityOpen(!isMenuChartsVisibilityOpen);
  };

  return (
    <div className="partial-charts-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="charts-container">
            {appSettings.loading ? (
              <GenericLoader />
            ) : (
              <div className="charts-content">
                <BackButton backFunction={backFunction} />
                <div className="charts-content-actions">
                  <h2 className="charts-content-h2">{t("charts")}</h2>
                  <div
                    className="chart-type-content-visibility"
                    onClick={() => toggleMenuChartsVisibility()}
                  >
                    <BarChartIcon sx={{}} />
                    <AnimatePresence>
                      {isMenuChartsVisibilityOpen && (
                        <ChartCheckboxGroup
                          selectedChartType={appSettings.chartType}
                          setIsOpen={setIsMenuChartsVisibilityOpen}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                {appSettings.chartType.some(
                  (chartTypeOption) => chartTypeOption.type === "bar"
                ) && (
                  <div className="charts-content-div">
                    <BarChart
                      transactions={transactions.data}
                      chartTypeItem={
                        appSettings.chartType.find(
                          (item) => item.type === "bar"
                        )!
                      }
                    />
                    <div className="charts-content-divider"></div>
                  </div>
                )}
                {appSettings.chartType.some(
                  (chartTypeOption) => chartTypeOption.type === "donut"
                ) && (
                  <div className="charts-content-div">
                    <DonutChart
                      transactions={transactions.data}
                      chartTypeItem={
                        appSettings.chartType.find(
                          (item) => item.type === "donut"
                        )!
                      }
                    />
                  </div>
                )}
                {appSettings.chartType.length >= 3 && (
                  <div className="charts-content-divider"></div>
                )}
                {appSettings.chartType.some(
                  (chartTypeOption) => chartTypeOption.type === "line"
                ) && (
                  <div className="charts-content-div">
                    <LineChart
                      transactions={transactions.data}
                      chartTypeItem={
                        appSettings.chartType.find(
                          (item) => item.type === "line"
                        )!
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
