import React, { useEffect, useState } from "react";
import "../assets/styles/Pages/NotificationsPage.scss";
import { useNavigate } from "react-router-dom";
import { GenericLoader } from "../components/loader/GenericLoader";
import { BackButton } from "../components/BackButton";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import {
  AppNotification,
  updateInternalNotifications,
  updateNotifications,
} from "../redux/appsettingsSlice";
import { format } from "date-fns/format";
import { it, enGB } from "date-fns/locale";
import DraftsIcon from "@mui/icons-material/Drafts";
import _ from "lodash";
import Badge from "@mui/material/Badge";
import { useTranslation } from "react-i18next";

export const NotificationsPage: React.FunctionComponent = () => {
  const settings = useAppSelector((state) => state.appsettings);
  const [notifications, setNotifications] = useState<AppNotification[]>(
    settings.notifications
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = settings.language === "it" ? it : enGB;

  const notificationsFilteredByDate: Record<string, AppNotification[]> = {};
  const sortedNotifications = _.orderBy(notifications, "created_at", "desc");
  sortedNotifications.forEach((notification) => {
    const day = format(notification.created_at, "d MMMM", { locale: lang });

    notificationsFilteredByDate[day] = [
      ...(notificationsFilteredByDate[day] || []),
      notification,
    ];
  });

  const setNotificationRead = (id: number) => {
    const updatedNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, read: true };
      }
      return notification;
    });

    setNotifications(updatedNotifications);
    dispatch(updateInternalNotifications(updatedNotifications));
  };

  const setAllNotificationsRead = async () => {
    if (notifications.length > 0) {
      const updatedNotifications = notifications.map((notification) => {
        return { ...notification, read: true };
      });

      setNotifications(updatedNotifications);
      dispatch(updateInternalNotifications(updatedNotifications));
    }
  };

  const updateNotificationsToDb = async () => {
    if (notifications.length > 0) {
      const ids = notifications
        .filter((notification) => notification.read === true)
        .map((notification) => notification.id);
      if (ids.length > 0) {
        await dispatch(updateNotifications(ids));
      }
    }
  };

  const backFunction = () => {
    navigate("/dashboard/home");
  };

  useEffect(() => {
    return () => {
      if (notifications.length > 0) {
        updateNotificationsToDb();
      }
    };
  }, []);

  return (
    <div className="partial-notifications-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="notifications-container">
            {settings.loading ? (
              <GenericLoader />
            ) : (
              <>
                <BackButton backFunction={backFunction} />
                <h2 className="notifications-h2">{t("notifications")}</h2>
                <div
                  className={
                    "notifications-content " +
                    (!settings.isDarkMode ? " notifications-content-light" : "")
                  }
                >
                  <div className="notifications-div">
                    <div
                      className="notifications-actions-button"
                      onClick={() => setAllNotificationsRead()}
                    >
                      {t("mark-all-read")}
                    </div>
                  </div>
                  <div className="notifications-div">
                    <div className="notifications-list-container">
                      {Object.entries(notificationsFilteredByDate).map(
                        ([date, notificationsForDate]) => (
                          <div
                            key={date}
                            className="notifications-list-content"
                          >
                            <h3 className="notifications-list-h3">{date}</h3>
                            {notificationsForDate.map((notification) => (
                              <div
                                key={notification.id}
                                className={
                                  "notifications-list-item-container " +
                                  (!notification.read
                                    ? " notifications-unread"
                                    : "")
                                }
                                onClick={() =>
                                  setNotificationRead(notification.id)
                                }
                              >
                                <div className="notifications-item-icon">
                                  <Badge
                                    color="primary"
                                    variant="dot"
                                    invisible={notification.read}
                                  >
                                    <DraftsIcon sx={{ fontSize: 40 }} />
                                  </Badge>
                                </div>
                                <div className="notifications-list-item-content">
                                  <div className="notifications-item-title">
                                    {t(notification.title)}
                                  </div>
                                  <div className="notifications-item-text">
                                    {notification.text}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
