import axios, { AxiosError } from "axios";
import { API_BASE_URL, API_BASE_AUTH_URL } from "../utils/Constants";
import { SignupFormFields } from "../components/form/SignupForm";
import { LoginFormFields } from "../components/form/LoginForm";
import { Transaction } from "../redux/transactionsSlice";
import { Category } from "../redux/categoriesSlice";
import { ChangePasswordFormFields } from "../components/form/ChangePasswordForm";
import { ResetPasswordFormFields } from "../components/form/ResetPasswordForm";
import {
  AppNewNotification,
  AppNotification,
  Setting,
} from "../redux/appsettingsSlice";

//! auth api ==========================================================

export const loginUserAsync = async (formData: LoginFormFields) => {
  try {
    const response = await axios.post(
      `${API_BASE_AUTH_URL}login`,
      {
        username: formData.email.toLowerCase(),
        password: formData.password,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const registerUserAsync = async (formData: SignupFormFields) => {
  try {
    const response = await axios.post(
      `${API_BASE_AUTH_URL}signin`,
      {
        first_name: formData.first_name.toLowerCase(),
        last_name: formData.last_name.toLowerCase(),
        email: formData.email.toLowerCase(),
        password: formData.password,
      },
      { withCredentials: true }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const logoutUserAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_AUTH_URL}logout`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const logoutAllUsersAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.post(
      `${API_BASE_AUTH_URL}logoutall`,
      {},
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const checkAuthenticationAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/dashboard`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;

      if (axiosError.response) {
        throw {
          statusCode: axiosError.response.status,
          message: "Errore di risposta dal server",
        };
      } else if (axiosError.request) {
        throw {
          statusCode: axiosError.request.status,
          message: "Errore nella domanda al server",
        };
      }
    }
    throw error;
  }
};

export const checkRoleAuthorizationAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/admin`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });
    console.log(response);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;

      if (axiosError.response) {
        throw {
          statusCode: axiosError.response.status,
          message: "Errore di risposta dal server",
        };
      } else if (axiosError.request) {
        throw {
          statusCode: axiosError.request.status,
          message: "Errore nella domanda al server",
        };
      }
    }
    throw error;
  }
};

export const getUserAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_AUTH_URL}getuser`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const changePasswordAsync = async (
  formData: ChangePasswordFormFields
) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.patch(
      `${API_BASE_AUTH_URL}changepassword`,
      {
        old_password: formData.oldPassword,
        new_password: formData.newPassword,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const requestResetPasswordAsync = async (email: string) => {
  try {
    // let tokenString = localStorage.getItem("token");
    // const token = JSON.parse(tokenString!);
    const response = await axios.post(
      `${API_BASE_AUTH_URL}requestresetpassword`,
      { email: email },
      {
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordAsync = async (formData: ResetPasswordFormFields) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.patch(
      `${API_BASE_AUTH_URL}resetpassword`,
      {
        token: formData.token,
        new_password: formData.newPassword,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//! settings api =====================================================

export const getSettingsAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/settings`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const editSettingAsync = async (formData: Setting) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    let userIdString = JSON.parse(localStorage.getItem("userId")!);
    let userId = Number.parseInt(userIdString);
    const response = await axios.patch(
      `${API_BASE_URL}api/settings/${formData.id}`,
      {
        id: formData.id,
        enable_tutorial: formData.enable_tutorial,
        enable_send_monthly_report: formData.enable_send_monthly_report,
        enable_send_recurring_daily_report:
          formData.enable_send_recurring_daily_report,
        user_id: userId,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const sendMailToSupportAsync = async (
  message: string,
  rating: number | null
) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const ratingToSend = rating !== null ? rating : 0;
    const response = await axios.post(
      `${API_BASE_URL}api/sendmailtosupport`,
      {
        message: message,
        rating: ratingToSend.toString(),
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//! transactions api =====================================================

export const getTransactionsAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/transactions`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const createNewTransactionAsync = async (formData: Transaction) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    let userIdString = JSON.parse(localStorage.getItem("userId")!);
    let userId = Number.parseInt(userIdString);
    const response = await axios.post(
      `${API_BASE_URL}api/transactions`,
      {
        id: formData.id,
        t_type: formData.t_type,
        category: formData.category,
        description: formData.description,
        amount: formData.amount,
        created_at: formData.created_at,
        user_id: userId,
        is_recurring: formData.is_recurring,
        recurring_date: formData.recurring_date,
        recurring_type: formData.recurring_type,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const editTransactionAsync = async (formData: Transaction) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    let userIdString = JSON.parse(localStorage.getItem("userId")!);
    let userId = Number.parseInt(userIdString);
    const response = await axios.patch(
      `${API_BASE_URL}api/transactions/${formData.id}`,
      {
        id: formData.id,
        t_type: formData.t_type,
        category: formData.category,
        description: formData.description,
        amount: formData.amount,
        user_id: userId,
        is_recurring: formData.is_recurring,
        recurring_date: formData.recurring_date,
        recurring_type: formData.recurring_type,
        created_at: formData.created_at,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTransactionAsync = async (id: number) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.delete(
      `${API_BASE_URL}api/transactions/${id}`,
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//! categories api ==========================================================

export const getCategoriesAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/categories`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const createNewCategoryAsync = async (formData: Category) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    let userIdString = JSON.parse(localStorage.getItem("userId")!);
    let userId = Number.parseInt(userIdString);
    const response = await axios.post(
      `${API_BASE_URL}api/categories`,
      {
        id: formData.id,
        c_type: formData.c_type,
        name: formData.name.toLowerCase(),
        icon: formData.icon,
        color: formData.color,
        user_id: userId,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const editCategoryAsync = async (formData: Category) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    let userIdString = JSON.parse(localStorage.getItem("userId")!);
    let userId = Number.parseInt(userIdString);
    const response = await axios.patch(
      `${API_BASE_URL}api/categories/${formData.id}`,
      {
        c_type: formData.c_type,
        name: formData.name,
        icon: formData.icon,
        color: formData.color,
        user_id: userId,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCategoryAsync = async (id: number) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.delete(`${API_BASE_URL}api/categories/${id}`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

//! notification ======================================================================

export const getNotificationsAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/notifications`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationsAsync = async (ids: number[]) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.patch(
      `${API_BASE_URL}api/notifications`,
      ids,
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//! download-upload xlsx api ==========================================================

export const downloadTransactionsAsync = async (
  transactions: Transaction[],
  date: string
) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const filenameWithoutExtension = `transactions_report_${date.replace(
      " ",
      "_"
    )}`;
    const filename = `${filenameWithoutExtension}.xlsx`;

    const transactionIds = transactions
      .map((transaction) => transaction.id)
      .join(",");

    const response = await axios.get(
      `${API_BASE_URL}api/download/transactions`,
      {
        params: {
          transaction_ids: encodeURIComponent(transactionIds),
          filename: encodeURIComponent(filenameWithoutExtension),
        },
        withCredentials: true,
        headers: {
          Authorization: token,
        },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        // La richiesta è stata fatta e il server ha restituito uno stato di errore
        console.log(axiosError.response); // Stampa il JSON dell'errore
      } else if (axiosError.request) {
        // La richiesta è stata fatta ma non è stata ricevuta alcuna risposta
        console.log(axiosError.request); // Stampa la richiesta Axios
      } else {
        // Si è verificato un errore durante la configurazione della richiesta
        console.log("Error", axiosError.message); // Stampa il messaggio di errore
      }
    } else {
      // Si è verificato un errore diverso da Axios
      console.log("Error", error); // Stampa il messaggio di errore
    }
    throw error;
  }
};

export const uploadFileXlsxAsync = async (formData: FormData) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.post(
      `${API_BASE_URL}api/upload/transactions`,
      formData,
      {
        withCredentials: true,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

//! admin

export const getUsersAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/admin/getusers`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllNotificationsAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(
      `${API_BASE_URL}api/admin/getnotifications`,
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTransactionsAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(
      `${API_BASE_URL}api/admin/gettransactions`,
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCategoriesAsync = async () => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.get(`${API_BASE_URL}api/admin/getcategories`, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const CreateNotificationAsync = async (
  notification: AppNewNotification
) => {
  try {
    let tokenString = localStorage.getItem("token");
    const token = JSON.parse(tokenString!);
    const response = await axios.post(
      `${API_BASE_URL}api/notifications`,
      {
        id: 0,
        users_ids: notification.users_ids,
        title: notification.title,
        text: notification.text,
        created_at: notification.created_at,
        read: notification.read,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
