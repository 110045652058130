import React from "react";
import "../assets/styles/Pages/CategoriesPage.scss";
import { CategoriesTable } from "../components/CategoriesTable";
import { GenericLoader } from "../components/loader/GenericLoader";
import { useAppSelector } from "../hooks/useRedux";
import { BackButton } from "../components/BackButton";

export const CategoriesPage: React.FunctionComponent = () => {
  const categories = useAppSelector((state) => state.categories);

  return (
    <div className="partial-dashboard-category-table-page">
      <div className="dash-container">
        <div className="dash-content">
          <BackButton />
          {categories.loading ? (
            <GenericLoader />
          ) : (
            <CategoriesTable categories={categories.data} />
          )}
        </div>
      </div>
    </div>
  );
};
