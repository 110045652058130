import React from "react";
import { createTheme, styled, useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  editSettings,
  selectDatePaginationMode,
  selectLanguage,
  toggleDarkMode,
} from "../../redux/appsettingsSlice";
import { useTranslation } from "react-i18next";

const MaterialUISwitchDarkMode = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#005fdf",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
    borderRadius: 20 / 2,
  },
}));

const MaterialUISwitchGeneric = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#005fdf",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 30 30"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#eaee05" : "#8796A5",
    borderRadius: 20 / 2,
  },
}));

export const AppSettingsForm: React.FunctionComponent = () => {
  const settings = useAppSelector((state) => state.appsettings);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const customTheme = createTheme({
    ...theme,
    palette: {
      mode: settings.isDarkMode ? "dark" : "light",
    },
  });

  const handleDarkModeToggle = () => {
    dispatch(toggleDarkMode());
  };

  const handleChangeLanguage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value;
    dispatch(selectLanguage(newLanguage));
    i18n.changeLanguage(newLanguage);
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, checked } = event.target;
      const response = await dispatch(
        editSettings({ ...settings.data, [name]: checked })
      );

      if (response.meta.requestStatus === "fulfilled") {
      } else {
        alert("Accesso non autorizzato");
      }
    } catch (error) {
      console.error("Errore durante l'accesso:", error);
      alert("Errore durante l'accesso:");
    }
  };

  return (
    <div
      className={
        "app-settings-content" +
        (!settings.isDarkMode ? " app-settings-content-light" : "")
      }
    >
      <h2 className="app-settings-h2">{t("settings")}</h2>
      <form className="app-settings-form">
        <div className="app-settings-div">
          <label className="app-settings-label">DarkMode</label>
          <FormControlLabel
            control={
              <MaterialUISwitchDarkMode
                sx={{ m: 1 }}
                checked={settings.isDarkMode}
                onChange={() => handleDarkModeToggle()}
                theme={customTheme}
              />
            }
            label=""
          />
        </div>
        <div className="app-settings-div">
          <label className="app-settings-label">
            {t("date-visualization")}
          </label>
          <select
            name="datePaginationUnit"
            id="datePaginationUnit"
            value={settings.datePaginationMode}
            onChange={(e) => dispatch(selectDatePaginationMode(e.target.value))}
          >
            <option value="daily">{t("daily")}</option>
            <option value="weekly">{t("weekly")}</option>
            <option value="monthly">{t("monthly")}</option>
            <option value="yearly">{t("yearly")}</option>
          </select>
        </div>
        <div className="app-settings-div">
          <label className="app-settings-label">{t("language")}</label>
          <select
            name="language"
            id="language"
            value={settings.language}
            onChange={handleChangeLanguage}
          >
            <option value="it">{t("italian")}</option>
            <option value="en">{t("english")}</option>
          </select>
        </div>
        <div className="app-settings-div">
          <h3 className="app-settings-h3">{t("mail-notify")}</h3>
          <label className="app-settings-label">
            {t("monthly-balance-report")}
          </label>
          <FormControlLabel
            control={
              <MaterialUISwitchGeneric
                sx={{ m: 1 }}
                name="enable_send_monthly_report"
                checked={settings.data.enable_send_monthly_report}
                onChange={handleSubmit}
                theme={customTheme}
              />
            }
            label=""
          />
        </div>
        <div className="app-settings-div">
          <label className="app-settings-label">
            {t("daily-recurrence-report")}
          </label>
          <FormControlLabel
            control={
              <MaterialUISwitchGeneric
                sx={{ m: 1 }}
                name="enable_send_recurring_daily_report"
                checked={settings.data.enable_send_recurring_daily_report}
                onChange={handleSubmit}
                theme={customTheme}
              />
            }
            label=""
          />
        </div>
      </form>
    </div>
  );
};
