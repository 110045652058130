import { Transaction } from "../redux/transactionsSlice";

export const transactionFields: Array<keyof Transaction> = [
  "t_type",
  "category",
  "amount",
  "created_at",
  "is_recurring",
  "recurring_date",
  "recurring_type",
];

export const getTransactionFieldFriendlyName = (fieldName: string): string => {
  try {
    switch (fieldName) {
      case "t_type":
        return "Tipo";
      case "category":
        return "Categoria";
      case "amount":
        return "Importo";
      case "created_at":
        return "Data creazione";
      case "is_recurring":
        return "Ricorrente";
      case "recurring_date":
        return "Data ricorrenza";
      case "recurring_type":
        return "Tipo ricorrenza";
      default:
        return "";
    }
  } catch {
    return "";
  }
};
