import React from "react";
import { ChartSingleData } from "../../utils/Charts";

interface ChartLegendProps {
  data: ChartSingleData;
}

export const ChartLegend: React.FC<ChartLegendProps> = ({ data }) => {
  const categoryNames = data.labels;
  const backgroundColors = data.datasets[0].backgroundColor;

  return (
    <div className="chart-legend-container">
      <div className="chart-legend-content">
        {categoryNames.map((categoryName, index) => (
          <div key={index} className="chart-legend-item-container">
            <div
              className="chart-legend-item-color"
              style={{ backgroundColor: backgroundColors[index] }}
            ></div>
            <p className="chart-legend-item-name">{categoryName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
