import React from "react";

export const TransactionsAdminPage: React.FC = () => {
  return (
    <div className="partial-admin-transactions-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="admin-transactions-content">
            <h3 className="admin-transactions-h3">Transactions</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
