import React from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useAppSelector } from "../hooks/useRedux";
import { EditCategoryForm } from "../components/form/EditCategoryForm";
import { BackButton } from "../components/BackButton";

export const DetailsCategory: React.FunctionComponent = () => {
  const { id } = useParams<string>();
  const categories = useAppSelector((state) => state.categories);

  const category = _.filter(
    categories.data,
    (category) => category.id === Number.parseInt(id!)
  )[0];

  return (
    <div className="partial-dashboard-category-page">
      <div className="dash-container">
        <div className="dash-content">
          <BackButton />
          <EditCategoryForm category={category} />
        </div>
      </div>
    </div>
  );
};
