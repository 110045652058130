import React, { useEffect, useState } from "react";
import "../assets/styles/Components/CategoriesTable.scss";
import _, { capitalize } from "lodash";
import { Search } from "./SearchInput";
import { MuIcon } from "./MuIcon";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";
import { AppNotification } from "../redux/appsettingsSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { format } from "date-fns/format";
import { it } from "date-fns/locale";

export const AdminNotificationsTable: React.FC = () => {
  const appSettings = useAppSelector((state) => state.appsettings);
  const notifications = useAppSelector(
    (state) => state.admin.data.notifications
  );
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [filteredResults, setFilteredResults] = useState<AppNotification[]>(
    _.orderBy(notifications, ["created_at"], ["desc"])
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  // const [typeFilterSelected, setTypeFilterSelected] = useState<string>("all");
  const { t } = useTranslation();

  const checkIconColor = appSettings.isDarkMode ? "#eaee05" : "#1602ad";

  const handleSearch = (query: string) => {
    if (query.trim() === "" || query.length <= 1) {
      setFilteredResults(notifications);
    } else {
      const filteredData = notifications.filter(
        (item) =>
          item.title.toLowerCase().includes(query.toLowerCase()) ||
          item.text.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(filteredData);
    }
    setSearchQuery(query);
  };

  // const handleSearchAndFilter = (query: string, typeFilter: string) => {
  //   const filteredData = categories.filter((item) => {
  //     const nameMatch =
  //       query.trim() === "" ||
  //       query.length <= 1 ||
  //       item.name.toLowerCase().includes(query.toLowerCase());
  //     const typeMatch = typeFilter === "all" || item.c_type === typeFilter;
  //     return nameMatch && typeMatch;
  //   });

  // setFilteredResults(filteredData);
  // };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 500); // Puoi regolare questo valore in base alle tue esigenze
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    // const selectedType = event.target.value;
    // setTypeFilterSelected(selectedType);
    handleSearch(searchQuery);
  };

  return (
    <>
      <div
        className={
          "table-container" +
          (!appSettings.isDarkMode ? " table-container-light" : "")
        }
      >
        <div className="search-and-filter">
          <Search searchQuery={searchQuery} onSearch={handleSearch} />
          <div className="table-filter">
            {/* <RadioButtonTransactionType
              t_type={typeFilterSelected}
              handleInputChange={handleInputChange}
              showAll={true}
            /> */}
          </div>
        </div>
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th className="th" style={{ minWidth: 60 }}>
                  {t("userid")}
                </th>
                <th className="th">{t("title")}</th>
                <th className="th">{t("text")}</th>
                <th className="th">{t("created_at")}</th>
                <th className="th">{t("read")}</th>
                <th className="th" style={{ width: 120 }}>
                  {t("actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {_.map(filteredResults, (notification, i) => {
                return (
                  <tr key={i}>
                    <td>{notification.user_id}</td>
                    <td>{capitalize(notification.title)}</td>
                    <td>{notification.text}</td>
                    <td>
                      {format(notification.created_at, "dd/MM/yy HH:mm:ss", {
                        locale: it,
                      })}
                    </td>
                    <td>
                      {notification.read && (
                        <CheckCircleIcon sx={{ color: checkIconColor }} />
                      )}
                    </td>
                    <td className="table-actions">
                      {/* <CategoriesTableActionsButton category={category} /> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
