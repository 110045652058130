import React from "react";
import "../../assets/styles/Components/Modal/CategoryModal.scss";
import { NewCategoryForm } from "../form/NewCategoryForm";
import { GenericModal } from "./GenericModal";

export const AddCategoryModal: React.FunctionComponent<{
  setIsOpen: (isOpen: boolean) => void;
}> = (props) => {
  const { setIsOpen } = props;

  return (
    <div className="backdrop-panel-blur">
      <GenericModal setIsOpen={setIsOpen} className={"category-modal"}>
        <div className="container">
          <div className="content">
            <NewCategoryForm setIsModalOpen={setIsOpen} />
          </div>
        </div>
      </GenericModal>
    </div>
  );
};
