import React, { useEffect } from "react";
import "../assets/styles/Pages/DashboardSummary.scss";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import _, { capitalize } from "lodash";
import { format, isSameMonth, startOfMonth } from "date-fns";
import { fetchTransactions, Transaction } from "../redux/transactionsSlice";
import { defaultCategory, fetchCategories } from "../redux/categoriesSlice";
import { FetchTransactionsLoader } from "../components/loader/FetchTransactionsLoader";
import { DonutDashboard } from "../components/DonutDashboard";
import { TransactionCard } from "../components/TransactionCard";
import { BlurLayerContainer } from "../components/BlurLayerContainer";
import { fetchSettings } from "../redux/appsettingsSlice";
import { useTranslation } from "react-i18next";

export const DashboardSummary: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector((state) => state.transactions);
  const categories = useAppSelector((state) => state.categories);
  const settings = useAppSelector((state) => state.appsettings);
  const isLoading = transactions.loading || categories.loading;
  const transactionsForChart = _.filter(transactions.data, (transaction) => {
    const dateNow = new Date();
    return isSameMonth(transaction.created_at!, startOfMonth(dateNow));
  });
  const { t } = useTranslation();

  const sortedTransactions = _.orderBy(
    transactions.data,
    ["created_at"],
    ["desc"]
  ).slice(0, 5);

  const transactionsByDate: Record<string, Transaction[]> = {};

  sortedTransactions.forEach((transaction, i) => {
    const transactionDate = format(transaction.created_at!, "dd/MM");
    if (!transactionsByDate[transactionDate]) {
      transactionsByDate[transactionDate] = [];
    }
    transactionsByDate[transactionDate].push(transaction);
  });

  const transactionsToRender = Object.entries(transactionsByDate).map(
    ([date, transactions], i) => (
      <div key={i} className="grouped-transactions">
        <div className="label-date">
          <div className="summary-div"></div>
          <p className="summary-p">{date}</p>
          <div className="summary-div"></div>
        </div>
        {transactions.map((transaction, j) => {
          const category = _.find(
            categories.data,
            (category) => category.name === transaction.category
          );
          return (
            <TransactionCard
              key={j}
              transaction={transaction}
              category={category ?? defaultCategory}
              isDemoMode={settings.data.enable_tutorial ?? true}
            />
          );
        })}
      </div>
    )
  );

  useEffect(() => {
    dispatch(fetchTransactions());
    dispatch(fetchCategories());
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <div className="partial-dashboard-page">
      <div className="dash-container">
        {!isLoading && (settings.data.enable_tutorial ?? true) && (
          <BlurLayerContainer />
        )}
        <div className="dash-content">
          {isLoading && <FetchTransactionsLoader />}
          {!isLoading && [
            <div key={"chart-content"} className="chart-content">
              <p key={"month"} className="month">
                {capitalize(t(format(new Date(), "LLLL").toLowerCase()))}
              </p>
              <DonutDashboard
                key={"DonutDashboard"}
                transactions={transactionsForChart}
                categories={categories.data}
              />
            </div>,
            <div key={"transactions-content"} className="transactions-content">
              <h3 className="summary-h3">{t("last-transaction")}</h3>
              {transactions.data.length > 0 ? (
                transactionsToRender
              ) : (
                <p className="summary-p">{t("no-transactions-found")}</p>
              )}
            </div>,
          ]}
        </div>
      </div>
    </div>
  );
};
