import React, { ReactNode } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CloseButton } from "../CloseButton";
import { motion } from "framer-motion";

type ModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  className: string;
  children: ReactNode;
};

export const GenericModal: React.FunctionComponent<ModalProps> = ({
  setIsOpen,
  className,
  children,
}) => {
  const closeModal = (): void => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <div className="backdrop-panel-blur">
      <ClickAwayListener onClickAway={closeModal}>
        <motion.div
          className={className}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, scale: 0.5 }}
        >
          <CloseButton
            className="btn-close"
            onClick={closeModal}
            fontSize={40}
          />
          {children}
        </motion.div>
      </ClickAwayListener>
    </div>
  );
};
