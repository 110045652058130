import React, { useState } from "react";
import "../assets/styles/Components/TransactionsCard.scss";
import { capitalize } from "lodash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { pink } from "@mui/material/colors";
import { DetailsTransactionModal } from "./modal/DetailsTransactionModal";
import { Transaction, deleteTransactions } from "../redux/transactionsSlice";
import { Category } from "../redux/categoriesSlice";
import { Transaction_type } from "../hooks/useTransactionForm";
import { MuIcon } from "./MuIcon";
import { DeleteModal } from "./modal/DeleteModal";
import { useAppSelector } from "../hooks/useRedux";
import { AnimatePresence } from "framer-motion";

export const TransactionCard: React.FunctionComponent<{
  transaction: Transaction;
  category: Category;
  isDemoMode?: boolean;
}> = (props) => {
  const { transaction, category, isDemoMode } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const transactions = useAppSelector((state) => state.transactions);

  const openDetailsModal = (): void => {
    if (!isDemoMode) {
      setIsDetailsModalOpen(true);
    }
  };

  const openDeleteModal = (): void => {
    if (!isDemoMode) {
      setIsDeleteModalOpen(true);
    }
  };

  return (
    <div className="transaction-card">
      <div className="container">
        <div
          className="category"
          style={{ backgroundColor: category.color }}
        ></div>
        <div className="content">
          <div className="icon">
            {
              <MuIcon
                name={category.icon}
                sx={{ color: "#fff", fontSize: 30 }}
              />
            }
          </div>
          <div className="text">
            <div className="description" onClick={openDetailsModal}>
              {capitalize(transaction.description)}
            </div>
            <div className="amount">
              {transaction.t_type === Transaction_type.Income ? "+ " : "- "}
              {transaction.amount.toFixed(2)} €
            </div>
          </div>
          <div className="actions" onClick={openDeleteModal}>
            <DeleteForeverIcon sx={{ color: pink[500], fontSize: 28 }} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isDetailsModalOpen && (
          <DetailsTransactionModal
            transaction={transaction}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            setIsOpen={setIsDeleteModalOpen}
            item={transaction}
            deleteFunction={deleteTransactions}
            isLoading={transactions.loading}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
