import React from "react";
import "../../assets/styles/Components/Modal/DatePickerModal.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CloseButton } from "../CloseButton";
import dayjs, { Dayjs } from "dayjs";
import { motion } from "framer-motion";

export const DatePickerModal: React.FunctionComponent<{
  setIsDatePickerModalOpen: (isDatePickerModalOpen: boolean) => void;
  userDatePickerDayjs: Dayjs | null;
  handleDatePickerOnChange: (value: any) => void;
}> = (props) => {
  const {
    setIsDatePickerModalOpen,
    userDatePickerDayjs,
    handleDatePickerOnChange,
  } = props;

  const closeModal = (): void => {
    setTimeout(() => {
      setIsDatePickerModalOpen(false);
    }, 100);
  };

  return (
    <div className="backdrop-panel-blur">
      <ClickAwayListener onClickAway={closeModal}>
        <motion.div
          className="date-picker-modal"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, scale: 0.5 }}
        >
          <CloseButton
            className="btn-close"
            onClick={closeModal}
            fontSize={40}
          />
          <div className="container">
            <div className="content">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  sx={{
                    backgroundColor: "transparent",
                    ".MuiPickersToolbar-root": {
                      display: "none",
                    },
                    ".MuiDialogActions-root": { display: "none" },
                    ".MuiDateCalendar-root": {
                      color: "#fff",
                    },
                    ".MuiButtonBase-root": {
                      color: "#fff",
                    },
                    ".MuiDayCalendar-root": {
                      color: "#fff",
                    },
                    ".MuiDayCalendar-weekDayLabel": {
                      color: "#bbdefb",
                    },
                    ".MuiPickersDay-root": {
                      color: "#fff",
                    },
                    ".MuiPickersDay-root.Mui-disabled:not(.Mui-selected)": {
                      color: "#6e6d6d",
                    },
                  }}
                  defaultValue={userDatePickerDayjs}
                  maxDate={dayjs(new Date())}
                  views={["day"]}
                  onChange={handleDatePickerOnChange}
                />
              </LocalizationProvider>
            </div>
            <button className="saveDate" onClick={closeModal}>
              Ok
            </button>
          </div>
        </motion.div>
      </ClickAwayListener>
    </div>
  );
};
