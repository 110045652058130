import React, { useState } from "react";
import "../assets/styles/Components/BottomBarMenu.scss";
import HomeIcon from "@mui/icons-material/Home";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BottomBarMenuAdmin: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleMenuClick = (menu: string) => {
    navigate(`/admin/${menu}`);
  };

  const setPageSelected = (uri: string) => {
    return location.pathname === "/admin/" + uri ? "selected-page" : "";
  };

  return (
    <>
      <div className="bottom-bar-menu">
        <div className="inner-bottom-bar-menu">
          <nav className="bottom-bar-menu-nav">
            <ul className="bottom-bar-menu-ul">
              <li
                onClick={() => handleMenuClick("home")}
                className={"bottom-bar-menu-li link " + setPageSelected("home")}
              >
                <HomeIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {/* {setPageSelected("transactions") && t("transactions")} */}
              </li>
              <li
                onClick={() => handleMenuClick("transactions")}
                className={
                  "bottom-bar-menu-li link " + setPageSelected("transactions")
                }
              >
                <CurrencyExchangeIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {/* {setPageSelected("transactions") && t("transactions")} */}
              </li>
              <li
                onClick={() => handleMenuClick("categories")}
                className={
                  "bottom-bar-menu-li link " + setPageSelected("categories")
                }
              >
                <CategoryIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {/* {setPageSelected("categories") && t("categories")} */}
              </li>
              <li
                onClick={() => handleMenuClick("users")}
                className={
                  "bottom-bar-menu-li link " + setPageSelected("users")
                }
              >
                <GroupIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {/* {setPageSelected("users") && t("users")} */}
              </li>
              <li
                onClick={() => handleMenuClick("notifications")}
                className={
                  "bottom-bar-menu-li link " + setPageSelected("notifications")
                }
              >
                <NotificationsIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {/* {setPageSelected("notifications") && "notifications"} */}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
