import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaginationMode } from "../components/DatePagination";
import {
  getSettingsAsync,
  editSettingAsync,
  getNotificationsAsync,
  updateNotificationsAsync,
} from "../services/Api";

export enum NotificationTitle {
  Update = "update",
  Info = "info",
  Warning = "warning",
}

export interface AppNotification {
  readonly id: number;
  readonly user_id: number;
  readonly title: NotificationTitle;
  readonly text: string;
  readonly created_at: Date;
  readonly read: boolean;
}

const initNotifications: AppNotification[] = [];

export interface AppNewNotification {
  readonly id: number;
  readonly users_ids: number[];
  readonly title: NotificationTitle;
  readonly text: string;
  readonly created_at: Date;
  readonly read: boolean;
}

export const initAdminNotification: AppNewNotification = {
  id: 0,
  users_ids: [-1],
  title: NotificationTitle.Info,
  text: "",
  created_at: new Date(),
  read: false,
};

export interface ChartSettings {
  enabledAnimation: boolean;
  filterBy: "day" | "month" | "year";
}

export interface ChartTypeItem {
  type: "line" | "donut" | "bar";
  viewMode: "balance" | "count";
  settings: ChartSettings;
}

export interface Setting {
  readonly id: number;
  readonly enable_tutorial: boolean;
  readonly enable_send_monthly_report: boolean;
  readonly enable_send_recurring_daily_report: boolean;
  readonly user_id: number;
}

const initSetting = {
  id: 0,
  enable_tutorial: true,
  enable_send_monthly_report: false,
  enable_send_recurring_daily_report: false,
  user_id: 0,
};

interface AppSettingsState {
  isDarkMode: boolean;
  datePaginationMode: PaginationMode;
  language: "it" | "en";
  chartType: ChartTypeItem[];
  notifications: AppNotification[];
  data: Setting;
  loading: boolean;
  error: string | null;
}

const initialState: AppSettingsState = {
  isDarkMode: true,
  datePaginationMode: PaginationMode.Monthly,
  language: "it",
  chartType: [],
  notifications: [],
  data: initSetting,
  loading: false,
  error: null,
};

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async () => {
    const response = await getSettingsAsync();
    return response.data.data as Setting;
  }
);

export const editSettings = createAsyncThunk(
  "settings/editSettings",
  async (setting: Setting) => {
    const response = await editSettingAsync(setting);
    return response.data.data as Setting;
  }
);

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async () => {
    const response = await getNotificationsAsync();
    return response.data.data as AppNotification[];
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/updateNotifications",
  async (ids: number[]) => {
    const response = await updateNotificationsAsync(ids);
    return response.data.data as AppNotification[];
  }
);

const updateChartTypeItemViewModeInternal = (
  chartType: ChartTypeItem[],
  type: "line" | "donut" | "bar",
  newViewMode: "balance" | "count"
): ChartTypeItem[] =>
  chartType.map((item) =>
    item.type === type ? { ...item, viewMode: newViewMode } : item
  );

const updateChartTypeItemSettingsInternal = (
  chartType: ChartTypeItem[],
  type: "line" | "donut" | "bar",
  settings: ChartSettings
): ChartTypeItem[] =>
  chartType.map((item) =>
    item.type === type ? { ...item, settings: settings } : item
  );

const appsettingsSlice = createSlice({
  name: "appsettings",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
    selectDatePaginationMode: (state, action) => {
      state.datePaginationMode = action.payload;
    },
    selectLanguage: (state, action) => {
      state.language = action.payload;
    },
    selectChartType: (state, action) => {
      state.chartType = action.payload;
    },
    updateChartTypeItemViewMode: (state, action) => {
      const { type, newViewMode } = action.payload;
      state.chartType = updateChartTypeItemViewModeInternal(
        state.chartType,
        type,
        newViewMode
      );
    },
    updateChartTypeItemSettings: (state, action) => {
      const { type, settings } = action.payload;
      state.chartType = updateChartTypeItemSettingsInternal(
        state.chartType,
        type,
        settings
      );
    },
    updateInternalNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.data = initSetting;
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // PATCH
      .addCase(editSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.data = action.payload;
      })
      .addCase(editSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // GET Notifications
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications = initNotifications;
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      })
      // PATCH Notifications
      .addCase(updateNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.notifications = action.payload;
      })
      .addCase(updateNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message ?? "Messaggio di fallback";
      });
  },
});

export const {
  toggleDarkMode,
  selectDatePaginationMode,
  selectLanguage,
  selectChartType,
  updateChartTypeItemViewMode,
  updateChartTypeItemSettings,
  updateInternalNotifications,
} = appsettingsSlice.actions;

export const AppSettingsReducer = appsettingsSlice.reducer;
