import React from "react";
import { useNavigate } from "react-router-dom";
import { GenericLoader } from "../loader/GenericLoader";
import { registerUserAsync } from "../../services/Api";
import { useAuth } from "../../stores/AuthContext";
import { PasswordInput } from "./PasswordInput";
import { comparePasswords } from "../../utils/FormValidators";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface SignupFormFields {
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly password: string;
  readonly confirmPassword: string;
}

export const SignupForm: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setFocus,
  } = useForm<SignupFormFields>();
  const { login } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const onSubmit: SubmitHandler<SignupFormFields> = async (data) => {
    const isPasswordsEqual = comparePasswords(
      data.password,
      data.confirmPassword
    );
    if (isPasswordsEqual) {
      try {
        const response = await registerUserAsync(data);

        if (response.status === 201) {
          const token = response.headers["authorization"];
          const userId = response.data.user.id;
          const role = response.data.user.role;
          const firstName = response.data.user.first_name;
          login(token, userId, role, firstName);
          navigate("/dashboard/home");
        } else {
          alert("Accesso non autorizzato");
        }
      } catch (error) {
        console.error("Errore durante l'accesso:", error);
        dispatch(
          openSnackbar({
            message: t("account-exist"),
            severity: "error",
          })
        );
        setFocus("email");
      }
    } else {
      dispatch(
        openSnackbar({
          message: t("passwords-not-match"),
          severity: "error",
        })
      );
      setValue("confirmPassword", "");
      setFocus("confirmPassword");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
      <div className="signup-div">
        <label htmlFor="firstname" className="signup-label">
          Nome:
        </label>
        <input
          type="text"
          id="firstname"
          className={
            "signup-input" + (errors.first_name ? " form-input-error" : "")
          }
          {...register("first_name", { required: true, min: 3 })}
        />
      </div>
      <div className="signup-div">
        <label htmlFor="lastname" className="signup-label">
          Cognome:
        </label>
        <input
          type="text"
          id="lastname"
          className={
            "signup-input" + (errors.last_name ? " form-input-error" : "")
          }
          {...register("last_name", { required: true, min: 3 })}
        />
      </div>
      <div className="signup-div">
        <label htmlFor="signupemail" className="signup-label">
          Email:
        </label>
        <input
          type="text"
          id="signupemail"
          className={"signup-input" + (errors.email ? " form-input-error" : "")}
          {...register("email", { required: true, pattern: emailPattern })}
        />
      </div>
      <div className="signup-div">
        <PasswordInput
          id={"signuppassword"}
          className={errors.password ? " form-input-error" : ""}
          register={register("password", {
            required: true,
            min: 8,
            pattern: passwordPattern,
          })}
          setFocus={setFocus}
        />
      </div>
      <div className="signup-div">
        <label htmlFor="confirmpassword" className="signup-label">
          Conferma Password:
        </label>
        <input
          type="password"
          id="confirmpassword"
          className={
            "signup-input" + (errors.confirmPassword ? " form-input-error" : "")
          }
          {...register("confirmPassword", {
            required: true,
            min: 8,
            pattern: passwordPattern,
          })}
        />
      </div>
      <button className="signup-button">Registrati</button>
      {isSubmitting && <GenericLoader />}
    </form>
  );
};
