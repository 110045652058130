import React, { useEffect, useState } from "react";
import "../assets/styles/Pages/ProfilePage.scss";
import { useNavigate } from "react-router-dom";
import { getUserAsync } from "../services/Api";
import { ProfileData } from "../components/form/ProfileForm";
import { GenericLoader } from "../components/loader/GenericLoader";
import { ChangePasswordForm } from "../components/form/ChangePasswordForm";
import { BackButton } from "../components/BackButton";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { openSnackbar } from "../redux/snackbarSlice";
import { useTranslation } from "react-i18next";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  created_at: Date;
}

export const initUser = {
  id: -1,
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  created_at: new Date(),
};

export const ProfilePage: React.FunctionComponent = () => {
  const [user, setUser] = useState<User>(initUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePasswordForm, setIsChangePasswordForm] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const appSettings = useAppSelector((state) => state.appsettings);

  const backFunction = () => {
    if (!isChangePasswordForm) {
      navigate("/dashboard/home");
    } else {
      setIsChangePasswordForm(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getUserAsync();
        setUser(response.data.user as User);
      } catch (error) {
        dispatch(
          openSnackbar({
            message: t("retrive-user-data-error"),
            severity: "error",
          })
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Clean up function
    return () => {
      // Effetto di cleanup, se necessario
    };
  }, []);

  return (
    <div
      className={
        "partial-profile-page" + (!appSettings.isDarkMode ? " light" : "")
      }
    >
      <div className="dash-container">
        <div className="dash-content">
          <div className="profile-content">
            {isLoading ? (
              <GenericLoader />
            ) : (
              <>
                <BackButton backFunction={backFunction} />
                {!isChangePasswordForm ? (
                  <ProfileData
                    user={user}
                    setIsChangePasswordForm={setIsChangePasswordForm}
                  />
                ) : (
                  <div className="change-password-content">
                    <ChangePasswordForm
                      setIsLoading={setIsLoading}
                      setIsChangePasswordForm={setIsChangePasswordForm}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
