import React from "react";
import "../../assets/styles/Pages/admin/UsersAdminPage.scss";
import { useTranslation } from "react-i18next";
import { logoutAllUsersAsync } from "../../services/Api";
import { useAppDispatch } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";

export const UsersAdminPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleLogoutAll = async () => {
    try {
      const response = await logoutAllUsersAsync();

      if (response.status === 200) {
        dispatch(
          openSnackbar({
            message: t("logout-all"),
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.error("Errore durante il logout:", error);
      dispatch(
        openSnackbar({
          message: t("error-logout-all"),
          severity: "error",
        })
      );
    }
  };
  return (
    <div className="partial-admin-users-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="admin-users-content">
            <h3 className="admin-users-h3">Users</h3>
            <div
              className="admin-users-btn-logout-all"
              onClick={handleLogoutAll}
            >
              {t("btn-logout-all")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
