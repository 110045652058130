import React, { ReactNode } from "react";
import { useAppSelector } from "../hooks/useRedux";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { motion } from "framer-motion";

interface HeaderMenuProps {
  setIsOpen: (isOpen: boolean) => void;
  className: string;
  children: ReactNode;
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({
  setIsOpen,
  className,
  children,
}) => {
  const closeModal = (): void => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };
  return (
    <ClickAwayListener onClickAway={closeModal}>
      <motion.ul
        className={className}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transform: "translateY(10px)" }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.5 }}
      >
        {children}
      </motion.ul>
    </ClickAwayListener>
  );
};
