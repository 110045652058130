import React, { useState } from "react";
import "../assets/styles/Components/Headers.scss";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CategoryIcon from "@mui/icons-material/Category";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAppSelector } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { HeaderMenu } from "./HeaderMenu";

type SearchResult = {
  id: number;
  type: "transaction" | "category" | "user";
  name: string;
  icon?: string;
  color?: string;
  amount?: number;
  date?: string;
  email?: string;
};

interface HeaderAdminPageProps {
  handleLogout: () => Promise<void>;
}

export const HeaderAdminPage: React.FunctionComponent<HeaderAdminPageProps> = ({
  handleLogout,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  //   const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  //   const [searchQuery, setSearchQuery] = useState<string>("");
  const appSettings = useAppSelector((state) => state.appsettings);
  const navigate = useNavigate();
  const [filteredResults, setFilteredResults] = useState<SearchResult[]>([]);
  const { t } = useTranslation();

  const handleShowMenu = (): void => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const navigateTo = (page: string) => {
    setIsMenuOpen(false);
    if (page === "dashboard") {
      navigate("/" + page);
    } else {
      navigate("/admin/" + page);
    }
  };

  //   const handleSearch = (query: string) => {
  //     setSearchQuery(query);
  //     if (query.trim() === "" || query.length <= 1) {
  //       const combinedResults: SearchResult[] = [];
  //       setFilteredResults(combinedResults);
  //     } else {
  //       const filteredCategories = categories.data
  //         .filter(
  //           (item) =>
  //             item.name.toLowerCase().includes(query.toLowerCase()) ||
  //             item.icon.toLowerCase().includes(query.toLowerCase()) ||
  //             item.color.toLowerCase().includes(query.toLowerCase()) ||
  //             item.c_type.toLowerCase().includes(query.toLowerCase())
  //         )
  //         .map(
  //           (category) =>
  //             ({
  //               id: category.id,
  //               type: "category",
  //               name: category.name,
  //               icon: category.icon,
  //               color: category.color,
  //             } as SearchResult)
  //         );

  //       const filteredTransactions = transactions.data
  //         .filter(
  //           (item) =>
  //             item.description.toLowerCase().includes(query.toLowerCase()) ||
  //             item.category.toLowerCase().includes(query.toLowerCase()) ||
  //             item.amount.toString().includes(query.toLowerCase()) ||
  //             item.t_type.toLowerCase().includes(query.toLowerCase()) ||
  //             item.created_at?.toLocaleString().includes(query)
  //         )
  //         .map((transaction) => {
  //           const category = _.find(
  //             categories.data,
  //             (category) => category.name === transaction.category
  //           );
  //           return {
  //             id: transaction.id,
  //             type: "transaction",
  //             name: transaction.description,
  //             icon: category?.icon ?? "CurrencyExchange",
  //             color: category?.color ?? "#fff",
  //             amount: transaction.amount,
  //             date: format(transaction.created_at!, "dd/MM/yy"),
  //           } as SearchResult;
  //         });

  //       const filteredUsers = users
  //         .filter(
  //           (item) =>
  //             item.first_name.toLowerCase().includes(query.toLowerCase()) ||
  //             item.last_name.toLowerCase().includes(query.toLowerCase()) ||
  //             item.email.toLowerCase().includes(query.toLowerCase()) ||
  //             item.created_at?.toLocaleString().includes(query)
  //         )
  //         .map(
  //           (user) =>
  //             ({
  //               id: user.id,
  //               icon: "Person",
  //               type: "user",
  //               name: user.first_name + " " + user.last_name,
  //               email: user.email,
  //               date: format(user.created_at!, "dd/MM/yy"),
  //             } as SearchResult)
  //         );

  //       const combinedResults = [
  //         ...filteredCategories,
  //         ...filteredTransactions,
  //         ...filteredUsers,
  //       ];
  //       setFilteredResults(combinedResults);
  //     }
  //   };

  return (
    <header className={"header" + (!appSettings.isDarkMode ? " light" : "")}>
      <div className="inner-header">
        <Link to={"/"} className="link linklogo">
          <div className="logo"></div>
        </Link>
        <div className="actions-header">
          <div className="menu">
            <div onClick={handleShowMenu}>
              <MenuIcon sx={{ fontSize: 36, cursor: "pointer" }} />
            </div>
            <AnimatePresence>
              {isMenuOpen && (
                <HeaderMenu
                  setIsOpen={setIsMenuOpen}
                  className={
                    "header-ul" + (!appSettings.isDarkMode ? " light" : "")
                  }
                >
                  <li
                    onClick={() => navigateTo("dashboard")}
                    className="header-li"
                  >
                    <HomeIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("dashboard")}
                  </li>
                  <li
                    onClick={() => navigateTo("transactions")}
                    className="header-li"
                  >
                    <CurrencyExchangeIcon
                      sx={{ fontSize: 26, marginRight: 1 }}
                    />
                    {t("transactions")}
                  </li>
                  <li
                    onClick={() => navigateTo("categories")}
                    className="header-li"
                  >
                    <CategoryIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("categories")}
                  </li>
                  <li onClick={() => navigateTo("users")} className="header-li">
                    <DataUsageIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("users")}
                  </li>
                  <li
                    onClick={() => navigateTo("notifications")}
                    className="header-li"
                  >
                    <NotificationsIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("notifications")}
                  </li>
                  <li className="header-li">
                    <div
                      className={
                        "divider" +
                        (!appSettings.isDarkMode ? " divider-light" : "")
                      }
                    ></div>
                  </li>
                  <li onClick={handleLogout} className="header-li">
                    <LogoutIcon sx={{ fontSize: 26, marginRight: 1 }} />
                    {t("logout")}
                  </li>
                </HeaderMenu>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </header>
  );
};
