import React, { useState } from "react";
import "../../assets/styles/Pages/admin/NotificationsAdminPage.scss";
import {
  AppNewNotification,
  NotificationTitle,
  initAdminNotification,
} from "../../redux/appsettingsSlice";
import { CreateNotificationAsync } from "../../services/Api";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { openSnackbar } from "../../redux/snackbarSlice";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { MultipleSelectChip } from "../../components/AdminMultiSelectUsers";
import { AdminNotificationsTable } from "../../components/AdminNotificationsTable";
import AddIcon from "@mui/icons-material/Add";

export const NotificationsAdminPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreateModeVisible, setIsCreateModeVisible] =
    useState<boolean>(false);
  const admin = useAppSelector((state) => state.admin);
  const appSettings = useAppSelector((state) => state.appsettings);
  const [notification, setNotification] = useState<AppNewNotification>(
    initAdminNotification
  );
  const [textCounter, setTextCounter] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const maxTextareaLenght: number = 100;

  // const ids = Array.from({ length: 10 }, (_, index) => index + 1);
  const ids = admin.data.notifications.map((notification) => {
    return notification.id;
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      notification.users_ids.length <= 1 &&
      notification.users_ids.includes(-1)
    ) {
      setNotification({ ...notification, users_ids: ids });
    }
    if (notification.text !== "") {
      setIsLoading(true);
      try {
        const response = await CreateNotificationAsync(notification);

        if (response.status === 200) {
          dispatch(
            openSnackbar({
              message: t("notification-created"),
              severity: "success",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: t("error-try-again"),
              severity: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          openSnackbar({
            message: t("error-try-again"),
            severity: "error",
          })
        );
      } finally {
        setIsLoading(false);
        setNotification(initAdminNotification);
        setIsCreateModeVisible(false);
      }
    }
  };

  const handleTextareaOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setTextCounter(value.length);
    setNotification({
      ...notification,
      text: value,
    });
  };

  return (
    <div className="partial-admin-notifications-page">
      <div className="dash-container">
        <div className="dash-content">
          <div className="admin-notifications-content">
            <div className="admin-notifications-content-title">
              <h3 className="admin-notifications-h3">Notifications</h3>
              <div
                className={
                  "admin-notifications-create " +
                  (isCreateModeVisible ? " selected" : "")
                }
                onClick={() => setIsCreateModeVisible(!isCreateModeVisible)}
              >
                <AddIcon />
              </div>
            </div>
            {isCreateModeVisible && (
              <>
                <div className="admin-notifications-form">
                  <h3 className="notifications-form-h3">
                    {t("create-notification")}
                  </h3>
                  <form className="notifications-form" onSubmit={handleSubmit}>
                    <div className="notifications-form-div">
                      <label
                        htmlFor="notify-user-id"
                        className="notifications-form-label"
                      >
                        {t("select-users")}
                      </label>
                      <MultipleSelectChip
                        ids={ids}
                        setNotification={setNotification}
                        notification={notification}
                      />
                    </div>
                    <div className="notifications-form-div">
                      <label
                        htmlFor="notify-title"
                        className="notifications-form-label"
                      >
                        {t("title")}
                      </label>
                      <select
                        id="notify-title"
                        name="notify-title"
                        className={
                          "notify-title-content-select " +
                          (!appSettings.isDarkMode
                            ? " notify-title-content-select-light"
                            : "")
                        }
                        value={notification.title}
                        onChange={(e) => {
                          setNotification({
                            ...notification,
                            title: e.target.value as NotificationTitle,
                          });
                        }}
                      >
                        <option value="" disabled>
                          {t("attribute")}
                        </option>
                        {_.map(NotificationTitle, (field) => (
                          <option key={field} value={field}>
                            {t(field)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="notifications-form-div">
                      <label
                        htmlFor="notify-text"
                        className={
                          "notifications-form-label " +
                          (!appSettings.isDarkMode
                            ? " notifications-form-label-light"
                            : "")
                        }
                      >
                        {t("text")}
                      </label>
                      <textarea
                        id="notify-text"
                        name="notify-text"
                        className={
                          "notifications-form-textarea " +
                          (!appSettings.isDarkMode
                            ? " notifications-form-textarea-light"
                            : "")
                        }
                        value={notification.text}
                        onChange={handleTextareaOnChange}
                        required
                        maxLength={maxTextareaLenght}
                      />
                      <p
                        className={
                          "notifications-form-textarea-counter " +
                          (textCounter >= 60
                            ? " textarea-counter-warning"
                            : "") +
                          (textCounter >= 85 ? " textarea-counter-error" : "")
                        }
                      >
                        {textCounter}/{maxTextareaLenght}
                      </p>
                    </div>
                    <div className="notifications-form-div notifications-form-div-submit">
                      <button
                        type="submit"
                        className="notifications-btn-submit"
                      >
                        {t("send")}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="admin-notifications-divider"></div>
              </>
            )}
            <div className="admin-notifications-table">
              <AdminNotificationsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
