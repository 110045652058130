import React, { useState } from "react";
import "../assets/styles/Components/ChartRadioButtonGroup.scss";
import {
  ChartTypeItem,
  updateChartTypeItemViewMode,
} from "../redux/appsettingsSlice";
import { useAppDispatch } from "../hooks/useRedux";
import { useTranslation } from "react-i18next";

interface ChartRadioGroupProps {
  selectedChartType: ChartTypeItem;
}

interface ChartRadioButtonOptions {
  id: "balance" | "count";
  label: string;
}

const options: ChartRadioButtonOptions[] = [
  { id: "balance", label: "balance" },
  { id: "count", label: "count" },
];

export const ChartRadioButtonGroup: React.FC<ChartRadioGroupProps> = ({
  selectedChartType,
}) => {
  const [selectedOption, setSelectedOption] = useState<"balance" | "count">(
    selectedChartType.viewMode
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleRadioChange = (optionId: "balance" | "count") => {
    setSelectedOption(optionId);
    dispatch(
      updateChartTypeItemViewMode({
        type: selectedChartType.type,
        newViewMode: optionId,
      })
    );
  };

  return (
    <div className="radio-group-chart">
      <div className="radio-group-chart-div">
        {options.map((option) => (
          <label
            key={option.id}
            htmlFor={selectedChartType.type + option.id}
            className={`radio-group-chart-label ${
              selectedOption === option.id && "radio-group-chart-selected"
            }`}
          >
            <input
              type="radio"
              id={selectedChartType.type + option.id}
              name={"radio-group-" + selectedChartType.type}
              className="radio-group-chart-input"
              checked={selectedOption === option.id}
              onChange={() => handleRadioChange(option.id)}
            />
            {t(option.label)}
          </label>
        ))}
      </div>
    </div>
  );
};
