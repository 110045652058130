import React from "react";
import "../assets/styles/Components/BottomBarMenu.scss";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BottomBarMenu: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleMenuClick = (menu: string) => {
    navigate(`/dashboard/${menu}`);
  };

  const setPageSelected = (uri: string) => {
    return location.pathname === "/dashboard/" + uri ? "selected-page" : "";
  };

  return (
    <>
      <div className="bottom-bar-menu">
        <div className="inner-bottom-bar-menu">
          <nav className="bottom-bar-menu-nav">
            <ul className="bottom-bar-menu-ul">
              <li
                onClick={() => handleMenuClick("home")}
                className={"bottom-bar-menu-li link " + setPageSelected("home")}
              >
                <DataUsageIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {setPageSelected("home") && "Dashboard"}
              </li>
              <li className="bottom-bar-menu-li">
                <div
                  className="btn-add link"
                  onClick={() => handleMenuClick("createNew")}
                >
                  <AddIcon sx={{ fontSize: 40 }} />
                </div>
              </li>
              <li
                onClick={() => handleMenuClick("transactions")}
                className={
                  "bottom-bar-menu-li link " + setPageSelected("transactions")
                }
              >
                <CurrencyExchangeIcon sx={{ fontSize: 32, marginRight: 0.8 }} />
                {setPageSelected("transactions") && t("transactions")}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
