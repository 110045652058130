import React from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { editSettings } from "../redux/appsettingsSlice";

export const TransactionDemoMode: React.FunctionComponent = () => {
  const settings = useAppSelector((state) => state.appsettings);
  const dispatch = useAppDispatch();

  const handleDemoModeToggle = () => {
    dispatch(editSettings({ ...settings.data, enable_tutorial: false }));
  };

  return (
    <div className="demo-mode-container">
      <h3>
        Questa è la pagina del riepilogo delle transazioni mensili
        <br />
        <br />
        Premi il pulsante in basso a destra per inserire il tuo primo movimento
      </h3>
      <div className="arrow-demo">
        <KeyboardDoubleArrowDownIcon sx={{ fontSize: 70 }} />
      </div>
      <div className="btn-add-demo link" onClick={handleDemoModeToggle}>
        <AddIcon sx={{ fontSize: 40 }} />
      </div>
    </div>
  );
};
