import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  createNewTransactionAsync,
  deleteTransactionAsync,
  editTransactionAsync,
  getTransactionsAsync,
} from "../services/Api";

export interface Transaction {
  readonly id: number;
  readonly t_type: string;
  readonly category: string;
  readonly description: string;
  readonly amount: number;
  readonly created_at?: Date;
  readonly user_id: number;
  readonly is_recurring: boolean;
  readonly recurring_date?: Date;
  readonly recurring_type?: string;
}

export const isTransactionsEqual = (
  transaction1: Transaction,
  transaction2: Transaction
): boolean => {
  // Confronta le proprietà rilevanti delle due transazioni
  return (
    transaction1.id === transaction2.id &&
    transaction1.t_type === transaction2.t_type &&
    transaction1.category === transaction2.category &&
    transaction1.description === transaction2.description &&
    transaction1.amount === transaction2.amount &&
    transaction1.created_at === transaction2.created_at &&
    transaction1.user_id === transaction2.user_id &&
    transaction1.is_recurring === transaction2.is_recurring &&
    transaction1.recurring_date === transaction2.recurring_date &&
    transaction1.recurring_type === transaction2.recurring_type
  );
};

export const InitTransaction: Transaction = {
  id: 0,
  t_type: "expense",
  category: "",
  description: "",
  amount: 1,
  user_id: 0,
  is_recurring: false,
  recurring_date: undefined,
  recurring_type: undefined,
};

export const demoChartTransactions: Transaction[] = [
  {
    id: 1,
    t_type: "expense",
    category: "restaurant",
    description: "",
    amount: 0.1,
    user_id: 0,
    is_recurring: false,
    recurring_date: undefined,
    recurring_type: undefined,
    created_at: new Date(),
  },
];

export const demoAmountTransactions: Transaction[] = [
  {
    id: 1,
    t_type: "expense",
    category: "restaurant",
    description: "",
    amount: 0,
    user_id: 0,
    is_recurring: false,
    recurring_date: undefined,
    recurring_type: undefined,
    created_at: new Date(),
  },
];

interface TransactionsState {
  data: Transaction[];
  loading: boolean;
  error: string | null;
}

const initialState: TransactionsState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async () => {
    const response = await getTransactionsAsync();
    return response.data.data as Transaction[];
  }
);

export const addTransactions = createAsyncThunk(
  "transactions/addTransactions",
  async (newTransaction: Transaction) => {
    const response = await createNewTransactionAsync(newTransaction);
    return response.data.data as Transaction;
  }
);

export const editTransactions = createAsyncThunk(
  "transactions/editTransactions",
  async (transaction: Transaction) => {
    const response = await editTransactionAsync(transaction);
    return response.data.data as Transaction;
  }
);

export const deleteTransactions = createAsyncThunk(
  "transactions/deleteTransactions",
  async (idTransaction: number) => {
    const response = await deleteTransactionAsync(idTransaction);
    return response.data.data as Transaction;
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    addTransaction: (state, action: PayloadAction<Transaction>) => {
      state.data.push(action.payload);
    },
    removeTransaction: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter(
        (transaction) => transaction.id !== action.payload
      );
    },
    updateTransaction: (state, action: PayloadAction<Transaction>) => {
      const index = state.data.findIndex(
        (transaction) => transaction.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // GET
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // POST
      .addCase(addTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTransactions.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.loading = false;
        state.error = "";
      })
      .addCase(addTransactions.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // PATCH
      .addCase(editTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(editTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        const index = state.data.findIndex(
          (transaction) => transaction.id === action.payload.id
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(editTransactions.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      })
      // DELETE
      .addCase(deleteTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.data = state.data.filter(
          (transaction) => transaction.id !== action.payload.id
        );
      })
      .addCase(deleteTransactions.rejected, (state, action) => {
        // state.data = [];
        state.loading = false;
        state.error = action.error.message ?? "Messaggio di fallback";
      });
  },
});

export const { addTransaction, removeTransaction, updateTransaction } =
  transactionsSlice.actions;

export const selectTransactions = (state: RootState) => state.transactions.data;

export const TransactionsReducer = transactionsSlice.reducer;
